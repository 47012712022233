import {RJSFSchema, UiSchema} from '@rjsf/utils';

import {EResultRepresentationType} from 'core/enums';
import {EElementResultType} from 'core/enums';

export const UI_SCHEMA: UiSchema = {
  basic: {
    name: {
      'ui:field': '/schemas/jsf-input'
    },
    resultRepresentationType: {
      'ui:field': '/schemas/jsf-select'
    },
    resultType: {
      'ui:field': '/schemas/jsf-select'
    },
    elementName: {
      'ui:field': '/schemas/jsf-select-arrow'
    }
  }
};

export const SCHEMA: RJSFSchema = {
  type: 'object',
  properties: {
    basic: {
      title: 'Свойства',
      type: 'object',
      required: ['name', 'resultRepresentationType', 'resultType', 'elementName'],
      properties: {
        name: {
          title: 'Имя',
          type: 'string',
          minLength: 1
        },
        resultRepresentationType: {
          title: 'Формат результата',
          type: 'string',
          oneOf: [
            {
              const: EResultRepresentationType.COKER_MATERIAL_BALANCE,
              title: 'Средний выход продукта'
            }
          ],
          readOnly: true
        },
        resultType: {
          title: 'Тип результата',
          type: 'string',
          oneOf: [{const: EElementResultType.Coker_M_Balance, title: 'Coker: выход продукта'}],
          readOnly: true
        },
        elementName: {
          title: 'Имя элемента',
          type: 'string',
          oneOf: [{const: '704F784D-0000-0000-0000-000000000001', title: 'COKER-100'}],
          readOnly: true
        }
      }
    }
  }
};

export const FORM_DATA = {
  basic: {
    name: 'COKER-100 [Выход продукта]',
    resultRepresentationType: EResultRepresentationType.COKER_MATERIAL_BALANCE,
    resultType: EElementResultType.Coker_M_Balance,
    elementName: '704F784D-0000-0000-0000-000000000001'
  }
};
