/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CheckpointsInfoOut,
  HTTPValidationError,
  ResponseStatusCheckpointOut,
  ResponseStatusNoneType,
} from '../models/index';
import {
    CheckpointsInfoOutFromJSON,
    CheckpointsInfoOutToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    ResponseStatusCheckpointOutFromJSON,
    ResponseStatusCheckpointOutToJSON,
    ResponseStatusNoneTypeFromJSON,
    ResponseStatusNoneTypeToJSON,
} from '../models/index';

export interface CheckpointsCreateCheckpointRequest {
    projectUuid: string;
    fromCheckpoint: string;
    idempotencyKey: string;
}

export interface CheckpointsDeleteCheckpointRequest {
    projectUuid: string;
    checkpointUuid: string;
}

export interface CheckpointsGetProjectCheckpointsRequest {
    projectUuid: string;
}

export interface CheckpointsGetProjectDataRequest {
    projectUuid: string;
    checkpointUuid: string;
}

/**
 * 
 */
export class CheckpointsApi extends runtime.BaseAPI {

    /**
     * Create Checkpoint
     */
    async checkpointsCreateCheckpointRaw(requestParameters: CheckpointsCreateCheckpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseStatusCheckpointOut>> {
        if (requestParameters['projectUuid'] == null) {
            throw new runtime.RequiredError(
                'projectUuid',
                'Required parameter "projectUuid" was null or undefined when calling checkpointsCreateCheckpoint().'
            );
        }

        if (requestParameters['fromCheckpoint'] == null) {
            throw new runtime.RequiredError(
                'fromCheckpoint',
                'Required parameter "fromCheckpoint" was null or undefined when calling checkpointsCreateCheckpoint().'
            );
        }

        if (requestParameters['idempotencyKey'] == null) {
            throw new runtime.RequiredError(
                'idempotencyKey',
                'Required parameter "idempotencyKey" was null or undefined when calling checkpointsCreateCheckpoint().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['fromCheckpoint'] != null) {
            queryParameters['fromCheckpoint'] = requestParameters['fromCheckpoint'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['idempotencyKey'] != null) {
            headerParameters['idempotency-key'] = String(requestParameters['idempotencyKey']);
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OpenIdConnect", []);
        }

        const response = await this.request({
            path: `/api/public/v1/projects/{project_uuid}/checkpoints`.replace(`{${"project_uuid"}}`, encodeURIComponent(String(requestParameters['projectUuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseStatusCheckpointOutFromJSON(jsonValue));
    }

    /**
     * Create Checkpoint
     */
    async checkpointsCreateCheckpoint(requestParameters: CheckpointsCreateCheckpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseStatusCheckpointOut> {
        const response = await this.checkpointsCreateCheckpointRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Checkpoint
     */
    async checkpointsDeleteCheckpointRaw(requestParameters: CheckpointsDeleteCheckpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseStatusNoneType>> {
        if (requestParameters['projectUuid'] == null) {
            throw new runtime.RequiredError(
                'projectUuid',
                'Required parameter "projectUuid" was null or undefined when calling checkpointsDeleteCheckpoint().'
            );
        }

        if (requestParameters['checkpointUuid'] == null) {
            throw new runtime.RequiredError(
                'checkpointUuid',
                'Required parameter "checkpointUuid" was null or undefined when calling checkpointsDeleteCheckpoint().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OpenIdConnect", []);
        }

        const response = await this.request({
            path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}`.replace(`{${"project_uuid"}}`, encodeURIComponent(String(requestParameters['projectUuid']))).replace(`{${"checkpoint_uuid"}}`, encodeURIComponent(String(requestParameters['checkpointUuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseStatusNoneTypeFromJSON(jsonValue));
    }

    /**
     * Delete Checkpoint
     */
    async checkpointsDeleteCheckpoint(requestParameters: CheckpointsDeleteCheckpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseStatusNoneType> {
        const response = await this.checkpointsDeleteCheckpointRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Project Checkpoints
     */
    async checkpointsGetProjectCheckpointsRaw(requestParameters: CheckpointsGetProjectCheckpointsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CheckpointsInfoOut>> {
        if (requestParameters['projectUuid'] == null) {
            throw new runtime.RequiredError(
                'projectUuid',
                'Required parameter "projectUuid" was null or undefined when calling checkpointsGetProjectCheckpoints().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OpenIdConnect", []);
        }

        const response = await this.request({
            path: `/api/public/v1/projects/{project_uuid}/checkpoints`.replace(`{${"project_uuid"}}`, encodeURIComponent(String(requestParameters['projectUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckpointsInfoOutFromJSON(jsonValue));
    }

    /**
     * Get Project Checkpoints
     */
    async checkpointsGetProjectCheckpoints(requestParameters: CheckpointsGetProjectCheckpointsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CheckpointsInfoOut> {
        const response = await this.checkpointsGetProjectCheckpointsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Project Data
     */
    async checkpointsGetProjectDataRaw(requestParameters: CheckpointsGetProjectDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['projectUuid'] == null) {
            throw new runtime.RequiredError(
                'projectUuid',
                'Required parameter "projectUuid" was null or undefined when calling checkpointsGetProjectData().'
            );
        }

        if (requestParameters['checkpointUuid'] == null) {
            throw new runtime.RequiredError(
                'checkpointUuid',
                'Required parameter "checkpointUuid" was null or undefined when calling checkpointsGetProjectData().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OpenIdConnect", []);
        }

        const response = await this.request({
            path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}`.replace(`{${"project_uuid"}}`, encodeURIComponent(String(requestParameters['projectUuid']))).replace(`{${"checkpoint_uuid"}}`, encodeURIComponent(String(requestParameters['checkpointUuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get Project Data
     */
    async checkpointsGetProjectData(requestParameters: CheckpointsGetProjectDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.checkpointsGetProjectDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
