import {Box, Grid, Link} from '@chakra-ui/react';
import {memo} from 'react';

import {ISelectOption} from '../../../interfaces';

interface IProps<T> {
  value: T;
  options: ISelectOption<T>[];
  onChange: (value: T) => void;
}

const SelectTabsFC = <T,>({value, options, onChange}: IProps<T>) => {
  return (
    <Grid
      gap="4px"
      bg="buttonBg"
      borderRadius="4px"
      alignItems="center"
      gridTemplateColumns={`repeat(${options.length}, 1fr)`}
    >
      {options.map((opt, index) => (
        <Link
          key={index}
          onClick={() => !opt.readonly && onChange(opt.value)}
          _hover={{textDecoration: 'none', cursor: opt.readonly ? 'not-allowed' : 'pointer'}}
        >
          <Box
            p="3px 0"
            fontSize="12px"
            color={opt.readonly ? 'lightGray' : 'bodyText'}
            textAlign="center"
            borderRadius="4px"
            bg={opt.value === value ? 'accent50' : 'buttonBg'}
          >
            {opt.label}
          </Box>
        </Link>
      ))}
    </Grid>
  );
};

export const SelectTabs = memo(SelectTabsFC);
