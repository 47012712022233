import {RJSFSchema, UiSchema} from '@rjsf/utils';
import {EElement} from '@progress-fe/core';

export const UI_SCHEMA_FLOW_MODEL_BASIC: UiSchema = {
  basic: {
    name: {
      'ui:field': '/schemas/jsf-input'
    },
    elementType: {
      'ui:field': '/schemas/jsf-select'
    },
    model_type: {
      'ui:field': '/schemas/jsf-select'
    },
    isDefaultModel: {
      'ui:field': '/schemas/jsf-checkbox'
    }
  },
  connected_elements: {
    name_1: {
      'ui:field': '/schemas/jsf-select-pick-arrow'
    },
    name_2: {
      'ui:field': '/schemas/jsf-select-pick-arrow'
    },
    name_3: {
      'ui:field': '/schemas/jsf-select-pick-arrow'
    },
    name_4: {
      'ui:field': '/schemas/jsf-select-pick-arrow'
    },
    name_5: {
      'ui:field': '/schemas/jsf-select-pick-arrow'
    },
    name_6: {
      'ui:field': '/schemas/jsf-select-pick-arrow'
    },
    name_7: {
      'ui:field': '/schemas/jsf-select-pick-arrow'
    },
    name_8: {
      'ui:field': '/schemas/jsf-select-pick-arrow'
    },
    name_9: {
      'ui:field': '/schemas/jsf-select-pick-arrow'
    }
  }
};

export const SCHEMA_FLOW_MODEL_BASIC: RJSFSchema = {
  type: 'object',
  properties: {
    basic: {
      title: 'Настройка',
      type: 'object',
      required: ['name', 'elementType', 'model_type', 'isDefaultModel'],
      properties: {
        name: {
          title: 'Имя модели',
          type: 'string',
          minLength: 1
        },
        elementType: {
          title: 'Тип элемента',
          type: 'string',
          oneOf: [{const: EElement.MaterialFlowElement, title: 'Материальный поток'}],
          readOnly: true
        },
        model_type: {
          title: 'Тип модели',
          type: 'string',
          oneOf: [{const: 'EModelType.Robinson', title: 'Пенг-Робинсон'}],
          readOnly: true
        },
        isDefaultModel: {
          title: 'Модель по умолчанию',
          type: 'boolean'
        }
      }
    },
    connected_elements: {
      title: 'Связанные элементы',
      type: 'object',
      properties: {
        name_1: {
          title: 'Материальный поток',
          type: 'string',
          oneOf: [{const: '196C89E0-0000-0000-0000-000000000008', title: '1'}],
          readOnly: true
        },
        name_2: {
          title: 'Материальный поток',
          type: 'string',
          oneOf: [{const: '196C89E0-0000-0000-0000-000000000009', title: '2', readOnly: true}],
          readOnly: true
        },
        name_3: {
          title: 'Материальный поток',
          type: 'string',
          oneOf: [{const: '196C89E0-0000-0000-0000-000000000010', title: '3', readOnly: true}],
          readOnly: true
        },
        name_4: {
          title: 'Материальный поток',
          type: 'string',
          oneOf: [{const: '196C89E0-0000-0000-0000-000000000011', title: '4', readOnly: true}],
          readOnly: true
        },
        name_5: {
          title: 'Материальный поток',
          type: 'string',
          oneOf: [{const: '196C89E0-0000-0000-0000-000000000012', title: '5', readOnly: true}],
          readOnly: true
        },
        name_6: {
          title: 'Материальный поток',
          type: 'string',
          oneOf: [{const: '196C89E0-0000-0000-0000-000000000013', title: '6', readOnly: true}],
          readOnly: true
        },
        name_7: {
          title: 'Материальный поток',
          type: 'string',
          oneOf: [{const: '196C89E0-0000-0000-0000-000000000014', title: '7', readOnly: true}],
          readOnly: true
        },
        name_8: {
          title: 'Материальный поток',
          type: 'string',
          oneOf: [{const: '196C89E0-0000-0000-0000-000000000015', title: '8', readOnly: true}],
          readOnly: true
        },
        name_9: {
          title: 'Материальный поток',
          type: 'string',
          oneOf: [{const: '196C89E0-0000-0000-0000-000000000016', title: '9', readOnly: true}],
          readOnly: true
        }
      }
    }
  }
};

export const FORM_DATA_FLOW_MODEL_BASIC = {
  basic: {
    name: 'Пенг-Робинсон-1',
    elementType: EElement.MaterialFlowElement,
    model_type: 'EModelType.Robinson',
    isDefaultModel: true
  },
  connected_elements: {
    name_1: '196C89E0-0000-0000-0000-000000000008',
    name_2: '196C89E0-0000-0000-0000-000000000009',
    name_3: '196C89E0-0000-0000-0000-000000000010',
    name_4: '196C89E0-0000-0000-0000-000000000011',
    name_5: '196C89E0-0000-0000-0000-000000000012',
    name_6: '196C89E0-0000-0000-0000-000000000013',
    name_7: '196C89E0-0000-0000-0000-000000000014',
    name_8: '196C89E0-0000-0000-0000-000000000015',
    name_9: '196C89E0-0000-0000-0000-000000000016'
  }
};
