import {useCallback, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Button, Flex, Grid, GridItem, Stack, Text} from '@chakra-ui/react';
import {Input, Loader, Svg, useDebouncedCallback} from '@progress-fe/ui-kit';
import {useNavigate} from 'react-router';

import {useStore} from 'core/hooks';
import {ProjectTypeEnum} from 'api';
import {Paginator} from 'ui-kit/paginator/Paginator';
import {getProjectUrl} from 'core/utils';

import {CreateProjectModal, ProjectList, ProjectMenu, UserSignOut} from './components';

export const MyProjectsSceneFC = () => {
  const navigate = useNavigate();

  const {authStore, myProjectsStore} = useStore();
  const {projects, paginator} = myProjectsStore;

  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    myProjectsStore.loadProjects();
  }, [myProjectsStore]);

  const searchByName = useDebouncedCallback(
    () => {
      myProjectsStore.loadProjects();
    },
    300,
    []
  );

  const handleSearch = useCallback(
    (value: string) => {
      myProjectsStore.setQuery(value);
      searchByName();
    },
    [myProjectsStore, searchByName]
  );

  const handleToggleModal = useCallback(() => {
    setModalOpen((prev) => !prev);
  }, [setModalOpen]);

  const handleCreateProject = useCallback(
    async (type: ProjectTypeEnum) => {
      const project = await myProjectsStore.createProject(type);
      setModalOpen(false);

      if (project) {
        navigate(getProjectUrl(type, project.uuid));
      }
    },
    [myProjectsStore, navigate]
  );

  return (
    <>
      {isModalOpen && (
        <CreateProjectModal onCreate={handleCreateProject} onClose={handleToggleModal} />
      )}
      <Grid gridTemplateColumns={'240px 1fr'} h="100vh">
        <Grid
          bg="bg"
          p="21px"
          position="relative"
          boxShadow="shadow_3"
          templateRows="80px 70px 1fr auto"
        >
          <Svg name="Favicon" size="s48" />

          <Text fontSize="24px" fontWeight="700" color="black">
            Мои проекты
          </Text>

          <ProjectMenu
            activeMenuItem={myProjectsStore.activeMenu}
            updateActiveMenuItem={myProjectsStore.setActiveType}
          />

          <UserSignOut username={authStore.user?.name || ''} onSignOut={authStore.signOut} />
        </Grid>

        <GridItem bg="darkWhite">
          <Flex p="64px 150px" h="100%" flexDirection="column" minW="1300px">
            <Flex justify="space-between" align="center">
              <Text fontSize="24px" fontWeight="700" color="black">
                Все проекты
              </Text>
              <Flex gap="24px">
                <Box width="240px">
                  <Input
                    size="md"
                    variant="outline"
                    placeholder="Поиск"
                    value={myProjectsStore.searchText}
                    onChange={handleSearch}
                    leftElement={<Svg name={'Search'} />}
                  />
                </Box>
                <Button variant="solid" size="md" onClick={handleToggleModal}>
                  Создать проект
                </Button>
              </Flex>
            </Flex>
            <Flex p="48px 0 0 0" flexGrow="1">
              {myProjectsStore.isLoading ? (
                <Loader />
              ) : (
                <Stack spacing="12px" w="100%">
                  <ProjectList projects={projects} />
                  {myProjectsStore.isNeedPagination && (
                    <Paginator
                      page={paginator.page}
                      size={paginator.size}
                      total={paginator.total}
                      onPageChange={myProjectsStore.setPage}
                    />
                  )}
                </Stack>
              )}
            </Flex>
          </Flex>
        </GridItem>
      </Grid>
    </>
  );
};

export const MyProjectsScene = observer(MyProjectsSceneFC);

export default MyProjectsScene;
