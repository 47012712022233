import {Box} from '@chakra-ui/react';
import {FieldProps} from '@rjsf/utils';

/**
 * Component renders "/schemas/jsf-divider" field of JsonSchema.
 * @param props : field props come from JsonSchema.
 * Value of props.formData is null.
 */
const DividerJsFieldFC = (props: FieldProps) => {
  return (
    <Box p="15px 0 4px 0" fontSize="12px" fontWeight="600">
      <Box>{props.schema.title}</Box>
      <Box borderTop="1px" borderColor="border" opacity="0.6" m="0 0 8px 0" h="1px" />
    </Box>
  );
};

export const DividerJsField = DividerJsFieldFC;
