import {FC, ReactElement} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Center} from '@chakra-ui/react';
import {IErrorMessage} from '@progress-fe/ui-kit';

import {Header} from 'ui-kit/headers';
import {TUserModel} from 'core/models';

import {
  ErrorIconButton,
  PlayIconButton,
  RedoIconButton,
  SaveIconButton,
  UndoIconButton,
  UserIconButton,
  PlaySelectedIconButton
} from './components';

interface IProps {
  title: string;
  currentUser: TUserModel;
  isCalculating: boolean;
  lastSaveDate: string | null;
  errors: IErrorMessage[];
  onRunCalculation: () => void;
  onSignOut: () => void;
  onSave: () => void;
  additionalActions?: ReactElement;
}

const ProjectMainBarFC: FC<IProps> = ({
  title,
  currentUser,
  isCalculating,
  lastSaveDate,
  errors,
  additionalActions,
  onRunCalculation,
  onSignOut,
  onSave
}) => {
  return (
    <Header title={title}>
      <Center gap="8px">
        <UndoIconButton isDisabled />
        <RedoIconButton isDisabled />
        <Box w="1px" bg="border" h="32px" />

        {!!additionalActions && (
          <>
            {additionalActions} <Box w="1px" bg="border" h="32px" />
          </>
        )}
        <PlayIconButton isDisabled={isCalculating} onRun={onRunCalculation} />
        <PlaySelectedIconButton isDisabled={isCalculating} />
        <ErrorIconButton errors={errors} isDisabled={isCalculating} />
        <Box w="1px" bg="border" h="32px" />

        <SaveIconButton isDisabled={isCalculating} lastSaveDate={lastSaveDate} onSave={onSave} />
        <UserIconButton currentUser={currentUser} onSignOut={onSignOut} />
      </Center>
    </Header>
  );
};

export const ProjectMainBar = observer(ProjectMainBarFC);
