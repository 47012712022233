import {Instance, types} from 'mobx-state-tree';
import {v4 as uuidv4} from 'uuid';

import {ResetModel, ProjectEntityJsonSchemas, TProjectEntityJsonSchemasModel} from 'core/models';
import * as BASIC_MOCKS from 'core/mocks/blends/ab72/ab72_basic';
import * as PROPS_MOCKS from 'core/mocks/blends/ab72/ab72_properties';

const ProjectBlends = types
  .compose(
    ResetModel,
    types.model('ProjectBlends', {
      blends: types.optional(types.array(ProjectEntityJsonSchemas), [])
    })
  )
  .actions((self) => ({
    // TODO: only for dev purpose should be removed
    afterCreate() {
      self.blends.push(
        ProjectEntityJsonSchemas.create({
          id: uuidv4(),
          name: `Тестовая смесь ${self.blends.length + 1}`,
          jsonSchemas: [
            {
              id: uuidv4(),
              name: 'Базовые',
              uiSchema: BASIC_MOCKS.UI_SCHEMA_AB72_BASIC,
              schema: BASIC_MOCKS.SCHEMA_AB72_BASIC,
              formData: BASIC_MOCKS.FORM_DATA_AB72_BASIC
            },
            {
              id: uuidv4(),
              name: 'Состав и свойства',
              uiSchema: PROPS_MOCKS.UI_SCHEMA_AB72_PROPERTIES,
              schema: PROPS_MOCKS.SCHEMA_AB72_PROPERTIES,
              formData: PROPS_MOCKS.FORM_DATA_AB72_PROPERTIES
            }
          ]
        })
      );
    },
    fetch(projectId: string): void {
      // TODO
      console.info(projectId);
    },
    createBlend(): void {
      self.blends.push(
        ProjectEntityJsonSchemas.create({
          id: uuidv4(),
          name: `Тестовая смесь ${self.blends.length + 1}`,
          jsonSchemas: [
            {
              id: uuidv4(),
              name: 'Базовые',
              uiSchema: BASIC_MOCKS.FORM_DATA_AB72_BASIC,
              schema: BASIC_MOCKS.SCHEMA_AB72_BASIC,
              formData: BASIC_MOCKS.FORM_DATA_AB72_BASIC
            },
            {
              id: uuidv4(),
              name: 'Модель',
              uiSchema: PROPS_MOCKS.UI_SCHEMA_AB72_PROPERTIES,
              schema: PROPS_MOCKS.SCHEMA_AB72_PROPERTIES,
              formData: PROPS_MOCKS.FORM_DATA_AB72_PROPERTIES
            }
          ]
        })
      );
    },
    hasResult(elementId: string): boolean {
      return self.blends.some((e) => e.id === elementId);
    },
    findResult(elementId: string): TProjectEntityJsonSchemasModel | undefined {
      return self.blends.find((e) => e.id === elementId);
    }
  }))
  .views(() => ({}));

export type TProjectBlendModel = Instance<typeof ProjectBlends>;

export {ProjectBlends};
