import {FC} from 'react';
import {Tag, TagLabel} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';
import {hexToRGB} from '@progress-fe/ui-kit';

import {ProjectTypeEnum} from 'api';

interface ITypeBadge {
  name: string;
  color: string;
}

const TagsOptions: Record<ProjectTypeEnum, ITypeBadge> = {
  [ProjectTypeEnum.SamplesAndOilBlend]: {
    name: 'Пробы и смеси',
    color: '#9A2E9E'
  },
  [ProjectTypeEnum.TechProcess]: {
    name: 'Техпроцесс',
    color: '#346BD9'
  },
  [ProjectTypeEnum.UnknownDefaultOpenApi]: {
    name: 'Отсутсвует',
    color: '#4E4E4E'
  }
};

interface IProps {
  type: ProjectTypeEnum;
}

const ProjectCategryBadgeFC: FC<IProps> = ({type}) => {
  const renderOptions = TagsOptions[type];

  return (
    <Tag
      p="4px 8px"
      h="24px"
      borderRadius="8px"
      backgroundColor={hexToRGB(renderOptions.color, 0.1)}
    >
      <TagLabel color={renderOptions.color} fontWeight="400" lineHeight="16px">
        {renderOptions.name}
      </TagLabel>
    </Tag>
  );
};

export const ProjectCategryBadge = observer(ProjectCategryBadgeFC);
