import {FC, memo} from 'react';
import {VStack, Center, Text} from '@chakra-ui/react';
import {useT} from '@progress-fe/core';

interface IProps {
  onClick: () => void;
}

const ComponentPlacementFC: FC<IProps> = ({onClick}) => {
  const {t} = useT();

  return (
    <Center
      w="100%"
      h="280px"
      border="1px solid"
      borderRadius="4px"
      borderColor="border"
      cursor="pointer"
      onClick={onClick}
    >
      <VStack gap={'4px'}>
        <Text>{t('DialogableContainer.placementTitle')}</Text>
        <Text textStyle="secondary" fontSize="12px">
          {t('DialogableContainer.clickToCollapse')}
        </Text>
      </VStack>
    </Center>
  );
};

export const ComponentPlacement = memo(ComponentPlacementFC);
