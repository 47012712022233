import {DragEvent, FC, useCallback, useEffect, useRef, useState} from 'react';
import {Box, Grid} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';
import {v4 as uuidv4} from 'uuid';
import {EElement, EventEmitter, useT} from '@progress-fe/core';
import {useDialog} from '@progress-fe/ui-kit';
import {
  Edge,
  Node,
  NodeChange,
  XYPosition,
  Connection,
  ReactFlowInstance,
  OnSelectionChangeParams,
  addEdge,
  reconnectEdge,
  useStoreApi,
  useEdgesState,
  useNodesState,
  useOnSelectionChange
} from '@xyflow/react';
import {
  RFRender,
  isEnergyPort,
  portsHaveSameFlowType,
  IRFMenuItem,
  TRFEdgeDataConfig,
  TRFWorkZoneDataConfig,
  RF_FIT_VIEW_MAX_ZOOM,
  RF_ENERGY_EDGE_PROPS,
  RF_MATERIAL_EDGE_PROPS,
  RF_DRAG_NODE_TYPE,
  RF_DRAG_TEMPLATE_CODE,
  ERFElement,
  ElementByRFElement
} from '@progress-fe/rf-core';

import {RFMenu} from 'ui-kit';

interface IProps {
  width: number;
  height: number;
  menuItems: IRFMenuItem[];
  initialNodes: Node<TRFWorkZoneDataConfig>[];
  initialEdges: Edge<TRFEdgeDataConfig>[];
  selectedNodeId: string | null;
  onCreateElement: (type: EElement, templateCode: string, position?: XYPosition) => Promise<void>;
  onDeleteElement: (uuid: string) => Promise<void>;
  onChangeNodePosition: (uuid: string, position: XYPosition) => Promise<void>;
}

const RFWorkZoneFC: FC<IProps> = ({
  width,
  height,
  menuItems,
  initialNodes,
  initialEdges,
  selectedNodeId,
  onCreateElement,
  onDeleteElement,
  onChangeNodePosition
}) => {
  const pickedEntityId = useRef<string | null>(null);

  const [instance, setInstance] = useState<ReactFlowInstance<
    Node<TRFWorkZoneDataConfig>,
    Edge<TRFEdgeDataConfig>
  > | null>(null);

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const {getState} = useStoreApi();
  const {addSelectedNodes, resetSelectedElements} = getState();

  const {t} = useT();

  const DeleteDialog = useDialog({title: t('elements.delete')});

  useEffect(() => {
    setNodes(initialNodes);
    setEdges(initialEdges);
  }, [initialEdges, initialEdges.length, initialNodes, initialNodes.length, setEdges, setNodes]);

  useEffect(() => {
    instance?.fitView({maxZoom: RF_FIT_VIEW_MAX_ZOOM});
  }, [width, instance]);

  // Pick specific node or edge
  useEffect(() => {
    const pickEntity = (entityId: string) => {
      pickedEntityId.current = entityId;

      const foundNode = nodes.find((n) => n.id === entityId);
      if (foundNode) {
        addSelectedNodes([foundNode.id]);
      }
    };

    EventEmitter.on('PickEntity', pickEntity);

    return () => {
      EventEmitter.off('PickEntity', pickEntity);
    };
  }, [addSelectedNodes, nodes]);

  useEffect(() => {
    setTimeout(() => {
      if (!!selectedNodeId) {
        addSelectedNodes([selectedNodeId]);
      } else {
        resetSelectedElements();
      }
    }, 50);
    // FYI: Should be only 1 dep
    // eslint-disable-next-line
  }, [selectedNodeId]);

  const onCreateEdgeByConnection = useCallback(
    (connection: Connection) => {
      const isValid = !!connection.source && !!connection.target;
      const hasHandles = !!connection.sourceHandle && !!connection.targetHandle;
      const isLogicValid = portsHaveSameFlowType(connection.sourceHandle, connection.targetHandle);

      if (!isValid || !hasHandles || !isLogicValid) return null;
      const isEdgeEnergy = isEnergyPort(connection.sourceHandle);

      const nodeA = nodes.find((n) => n.id === connection.source);
      const nodeB = nodes.find((n) => n.id === connection.target);
      console.info('AB', nodeA, nodeB);

      const edge: Edge<TRFEdgeDataConfig> = {
        id: uuidv4(),
        ...connection,
        type: isEdgeEnergy ? 'energy' : 'material',
        ...(isEdgeEnergy ? RF_ENERGY_EDGE_PROPS : RF_MATERIAL_EDGE_PROPS)
      };

      return edge;
    },
    [nodes]
  );

  const handleDeleteNode = (uuid: string, callback: () => void) => {
    const node = nodes.find((n) => n.id === uuid);
    DeleteDialog.open(t('elements.deleteSureMsg', {name: node?.data?.elementName}), {
      close: {title: t('actions.cancel')},
      apply: {
        title: t('actions.delete'),
        isDanger: true,
        onClick: async () => {
          await onDeleteElement(uuid);
          callback();
        }
      }
    });
  };

  // Specific node or edge was selected
  const handleChangeSelection = useCallback((params: OnSelectionChangeParams) => {
    const selectedNodes: Node[] = params.nodes;
    const newSelectedId = selectedNodes.length > 0 ? selectedNodes[0].id : null;

    // Don't emit event when node was selected by the Pick button
    if (newSelectedId && newSelectedId !== pickedEntityId.current) {
      EventEmitter.emit('SelectEntity', newSelectedId);
    }

    // Just clear node which was selected by the Pick button
    if (newSelectedId && newSelectedId === pickedEntityId.current) {
      pickedEntityId.current = null;
    }
  }, []);

  const handleOnConnect = useCallback(
    (connection: Connection) => {
      setEdges((existingEdges) => {
        const edge = onCreateEdgeByConnection(connection);
        return !!edge ? addEdge(edge, existingEdges) : existingEdges;
      });
    },
    [setEdges, onCreateEdgeByConnection]
  );

  const handleOnReconnect = useCallback(
    (oldEdge: Edge<TRFEdgeDataConfig>, newConnection: Connection) => {
      setEdges((existingEdges) => {
        const edge = onCreateEdgeByConnection(newConnection);
        return !!edge ? reconnectEdge(oldEdge, newConnection, existingEdges) : existingEdges;
      });
    },
    [setEdges, onCreateEdgeByConnection]
  );

  // Types: 'position' | 'replace' | 'select' | 'dimensions' | 'remove' | 'add'
  const handleNodeChanges = async (changes: NodeChange<Node<TRFWorkZoneDataConfig>>[]) => {
    if (changes.every((c) => c.type === 'dimensions')) {
      onNodesChange(changes);
      return;
    }

    if (changes.every((c) => c.type === 'select')) {
      onNodesChange(changes);
      return;
    }

    if (changes.every((c) => c.type === 'remove')) {
      handleDeleteNode(changes[0].id, () => onNodesChange(changes));
      return;
    }

    if (changes.every((c) => c.type === 'position')) {
      onNodesChange(changes);
      const targetChange = changes.find((n) => n.dragging === false);
      if (!!targetChange?.position) {
        await onChangeNodePosition(targetChange.id, targetChange.position);
      }
      return;
    }
  };

  const handleOnDrop = useCallback(
    async (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      const type = event.dataTransfer.getData(RF_DRAG_NODE_TYPE);
      const templateCode = event.dataTransfer.getData(RF_DRAG_TEMPLATE_CODE);
      const position = instance?.screenToFlowPosition({x: event.clientX, y: event.clientY});

      if (!!position && !!type) {
        const elementType = ElementByRFElement[type as ERFElement];
        if (!!elementType) {
          await onCreateElement(elementType, templateCode, position);
        }
      }
    },
    [instance, onCreateElement]
  );

  useOnSelectionChange({onChange: handleChangeSelection});

  return (
    <Grid width="100%" gridTemplateColumns="48px 1fr" height={height}>
      <DeleteDialog.render />
      <RFMenu menuItems={menuItems} height={height} />
      <Box width="100%" height={height}>
        <RFRender
          nodes={nodes}
          edges={edges}
          onInit={setInstance}
          onNodesChange={handleNodeChanges}
          onEdgesChange={onEdgesChange}
          onConnect={handleOnConnect}
          onReconnect={handleOnReconnect}
          onDrop={handleOnDrop}
        />
      </Box>
    </Grid>
  );
};

export const RFWorkZone = observer(RFWorkZoneFC);
