import {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, IconButton, useToken} from '@chakra-ui/react';
import {Tooltip} from 'react-tooltip';
import {Svg} from '@progress-fe/ui-kit';

interface IProps {
  isDisabled: boolean;
}

const TOOLTIP_ID = 'REDO_TOOLTIP_ID';

const RedoIconButtonFC: FC<IProps> = ({isDisabled}) => {
  const [borderHex] = useToken('colors', ['border']);

  return (
    <>
      <IconButton
        aria-label=""
        size="sm"
        variant="ghost"
        icon={<Svg name="Redo" />}
        isDisabled={isDisabled}
        data-tooltip-id={TOOLTIP_ID}
      />

      <Tooltip
        id={TOOLTIP_ID}
        delayShow={250}
        border={`1px solid ${borderHex}`}
        closeEvents={{click: true, mouseleave: true}}
        style={{padding: '0', backgroundColor: 'white'}}
        opacity="1"
      >
        <Box color="bodyText" p="6px 10px" fontSize="12px">
          Шаг вперёд
        </Box>
      </Tooltip>
    </>
  );
};

export const RedoIconButton = observer(RedoIconButtonFC);
