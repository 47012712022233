import {RJSFSchema, UiSchema} from '@rjsf/utils';

export const UI_SCHEMA_FLOW1_CHARACTERISTICS: UiSchema = {
  characteristics: {
    feedstock_temperature: {
      'ui:field': '/schemas/jsf-input'
    },
    feedstock_pressure: {
      'ui:field': '/schemas/jsf-input'
    },
    feedstock_mass_flow_rate: {
      'ui:field': '/schemas/jsf-input'
    }
  }
};

export const SCHEMA_FLOW1_CHARACTERISTICS: RJSFSchema = {
  type: 'object',
  properties: {
    characteristics: {
      title: 'Характеристики',
      type: 'object',
      required: ['feedstock_temperature', 'feedstock_pressure', 'feedstock_mass_flow_rate'],
      properties: {
        feedstock_temperature: {
          title: 'Температура',
          type: ['number', 'null'],
          minimum: 350,
          maximum: 550,
          $comment: '°C'
        },
        feedstock_pressure: {
          title: 'Давление',
          type: ['number', 'null'],
          exclusiveMinimum: 0,
          maximum: 100000,
          $comment: 'кПа (абс.)'
        },
        feedstock_mass_flow_rate: {
          title: 'Массовый расход',
          type: ['number', 'null'],
          exclusiveMinimum: 0,
          $comment: 'т/ч'
        }
      }
    }
  }
};

export const FORM_DATA_FLOW1_CHARACTERISTICS = {
  characteristics: {
    feedstock_temperature: null,
    feedstock_pressure: null,
    feedstock_mass_flow_rate: null
  }
};

export const REVERT_FORM_DATA = {};
