import {RJSFSchema, UiSchema} from '@rjsf/utils';

export const UI_SCHEMA_AB72_PROPERTIES: UiSchema = {
  preudo_components_properties: {
    table: {
      'ui:field': '/schemas/jsf-table'
    }
  },
  preudo_components_composition: {
    flowType: {
      'ui:field': '/schemas/jsf-tabs'
    },
    table: {
      'ui:field': '/schemas/jsf-table'
    }
  }
};

export const SCHEMA_AB72_PROPERTIES: RJSFSchema = {
  type: 'object',
  properties: {
    preudo_components_properties: {
      title: 'Свойства псевдокомпонентов',
      type: 'object',
      required: [],
      properties: {
        table: {
          type: 'object'
        }
      }
    },
    preudo_components_composition: {
      title: 'Компонентный и псевдокомпонентный состав',
      type: 'object',
      required: [],
      properties: {
        flowType: {
          title: 'Тип расхода',
          type: 'string',
          oneOf: [
            {const: 'TYPE_1', title: 'Мольный'},
            {const: 'TYPE_2', title: 'Массовый'},
            {const: 'TYPE_3', title: 'Объемный'}
          ]
        },
        table: {
          type: 'object'
        }
      }
    }
  }
};

export const FORM_DATA_AB72_PROPERTIES = {
  preudo_components_properties: {
    table: {
      fieldConfig: {
        isFixedColumn: true,
        columns: [
          {
            name: 'Название',
            align: 'left',
            sticky: true
          },
          {
            name: 'ТКП, °F',
            align: 'right'
          },
          {
            name: 'Мол. масса',
            align: 'right'
          },
          {
            name: 'Плотн., кг/м3',
            align: 'right'
          },
          {
            name: 'Вязкость 1 СИ',
            align: 'right'
          },
          {
            name: 'Вязкость 2 СИ',
            align: 'right'
          }
        ]
      },
      fieldValues: Array(50)
        .fill(null)
        .map(() => ['NBP_69', ...Array<string>(5).fill('0.0001')])
    }
  },
  preudo_components_composition: {
    flowType: 'TYPE_1',
    table: {
      fieldConfig: {
        isFixedColumn: true,
        columns: [
          {
            name: 'Название',
            align: 'left',
            sticky: true
          },
          {
            name: 'Объем жидкости',
            align: 'right'
          },
          {
            name: 'Кумулятивн. содержание',
            align: 'right'
          },
          {
            name: 'Расход',
            align: 'right'
          },
          {
            name: 'Молярные доли',
            align: 'right'
          }
        ]
      },
      fieldValues: Array(50)
        .fill(null)
        .map(() => ['Oxygen', ...Array<string>(4).fill('0.0001')])
    }
  }
};
