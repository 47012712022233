import {RJSFSchema, UiSchema} from '@rjsf/utils';
import {EElement} from '@progress-fe/core';

export const UI_SCHEMA_COMPRESSOR: UiSchema = {
  basic: {
    name: {
      'ui:field': '/schemas/jsf-input'
    },
    elementType: {
      'ui:field': '/schemas/jsf-select'
    },
    modelId: {
      'ui:field': '/schemas/jsf-select-arrow'
    }
  },
  connections: {
    input_flow: {
      'ui:field': '/schemas/jsf-select-pick-arrow'
    },
    output_flow: {
      'ui:field': '/schemas/jsf-select-pick-arrow'
    },
    input_energy_flow: {
      'ui:field': '/schemas/jsf-select-pick-arrow'
    }
  }
};

export const SCHEMA_COMPRESSOR: RJSFSchema = {
  type: 'object',
  properties: {
    basic: {
      title: 'Базовые свойства',
      type: 'object',
      required: ['name', 'elementType', 'modelId'],
      properties: {
        name: {
          title: 'Имя',
          type: 'string',
          minLength: 1
        },
        elementType: {
          title: 'Тип элемента',
          type: 'string',
          oneOf: [{const: EElement.CompressorElement, title: 'Компрессор'}],
          readOnly: true
        },
        modelId: {
          title: 'Модель',
          type: 'string',
          oneOf: [{const: '031CE246-0000-0000-0000-000000000005', title: 'Компрессор-1'}]
        }
      }
    },
    connections: {
      title: 'Связи',
      type: 'object',
      required: ['input_flow', 'output_flow', 'input_energy_flow'],
      properties: {
        input_flow: {
          title: 'Входной материальный поток',
          type: 'string',
          oneOf: [
            {const: '196C89E0-0000-0000-0000-000000000008', title: '1', readOnly: true},
            {const: '196C89E0-0000-0000-0000-000000000009', title: '2'},
            {const: '196C89E0-0000-0000-0000-000000000010', title: '3', readOnly: true},
            {const: '196C89E0-0000-0000-0000-000000000011', title: '4', readOnly: true},
            {const: '196C89E0-0000-0000-0000-000000000012', title: '5', readOnly: true},
            {const: '196C89E0-0000-0000-0000-000000000013', title: '6', readOnly: true},
            {const: '196C89E0-0000-0000-0000-000000000014', title: '7', readOnly: true},
            {const: '196C89E0-0000-0000-0000-000000000015', title: '8', readOnly: true},
            {const: '196C89E0-0000-0000-0000-000000000016', title: '9', readOnly: true}
          ]
        },
        output_flow: {
          title: 'Выходной материальный поток',
          type: 'string',
          oneOf: [
            {const: '196C89E0-0000-0000-0000-000000000008', title: '1', readOnly: true},
            {const: '196C89E0-0000-0000-0000-000000000009', title: '2', readOnly: true},
            {const: '196C89E0-0000-0000-0000-000000000010', title: '3', readOnly: true},
            {const: '196C89E0-0000-0000-0000-000000000011', title: '4'},
            {const: '196C89E0-0000-0000-0000-000000000012', title: '5', readOnly: true},
            {const: '196C89E0-0000-0000-0000-000000000013', title: '6', readOnly: true},
            {const: '196C89E0-0000-0000-0000-000000000014', title: '7', readOnly: true},
            {const: '196C89E0-0000-0000-0000-000000000015', title: '8', readOnly: true},
            {const: '196C89E0-0000-0000-0000-000000000016', title: '9', readOnly: true}
          ]
        },
        input_energy_flow: {
          title: 'Входной энергетический поток',
          type: 'string',
          oneOf: [{const: '196C89E0-0000-0000-0000-000000000006', title: 'Q-100'}]
        }
      }
    }
  }
};

export const FORM_DATA_COMPRESSOR = {
  basic: {
    name: 'K-100',
    elementType: EElement.CompressorElement,
    modelId: '031CE246-0000-0000-0000-000000000005'
  },
  connections: {
    input_flow: '196C89E0-0000-0000-0000-000000000009',
    output_flow: '196C89E0-0000-0000-0000-000000000011',
    input_energy_flow: '196C89E0-0000-0000-0000-000000000006'
  }
};

export const REVERT_FORM_DATA = {};
