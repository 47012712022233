import {memo} from 'react';
import {hasValue} from '@progress-fe/core';
import {Box, Grid} from '@chakra-ui/react';
import _ from 'lodash-es';

import {InputNumber} from '../../../../inputs';

interface IProps {
  suffix?: string;
  value: Array<{sampleId: string; sampleValue: string | null}>;
}

const CompositionSummaryFC = ({value, suffix}: IProps) => {
  const calculateSum = () => {
    return _.sum(value.map((i) => (hasValue(i.sampleValue) ? Number(i.sampleValue) : undefined)));
  };

  return (
    <Grid p="6px 0 0 0" gridTemplateColumns="minmax(0, 176px) 1fr" alignItems="center">
      <Box p="0 0 0 2px">Итого</Box>
      <InputNumber
        disabled
        size="xs"
        variant="outline"
        sx={{width: '100%'}}
        value={calculateSum()}
        rightElement={suffix ? <>{suffix}</> : undefined}
      />
    </Grid>
  );
};

export const CompositionSummary = memo(CompositionSummaryFC);
