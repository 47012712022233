export enum EElementResultType {
  Separator_2ph_Threads = 'Separator_2ph_Threads', // V_100 потоки
  Separator_3ph_Threads = 'Separator_3ph_Threads', // V_101 потоки
  Compressor_Threads = 'Compressor_Threads', // K_100 потоки
  Cooler_Threads = 'Cooler_Threads', // E_100 потоки
  Valve_Threads = 'Valve_Threads', // VLV_100 потоки
  Coker_Output = 'Coker_Output', // COKER_100 выход продукта
  Coker_M_Balance = 'Coker_M_Balance' // COKER_100 материальный баланс
}

export enum EResultRepresentationType {
  CONNECTED_FLOWS_DATA = 'CONNECTED_FLOWS_DATA', // Состояние связанных потоков
  COKER_MEAN_PRODUCT_OUTPUT = 'COKER_MEAN_PRODUCT_OUTPUT', // Средний выход продукта
  COKER_MATERIAL_BALANCE = 'COKER_MATERIAL_BALANCE' // Материальный баланс
}
