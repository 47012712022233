import {FC, useEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, IconButton, Text, useToken} from '@chakra-ui/react';
import {Tooltip} from 'react-tooltip';
import {useClickOutside} from '@progress-fe/ui-kit';
import {Svg, IErrorMessage} from '@progress-fe/ui-kit';

interface IProps {
  isDisabled: boolean;
  errors: IErrorMessage[];
}

const TOOLTIP_ID = 'ERROR_TOOLTIP_ID';
const HOVER_TOOLTIP_ID = 'HOVER_ERROR_TOOLTIP_ID';

const ErrorIconButtonFC: FC<IProps> = ({errors, isDisabled}) => {
  const [isOpen, setIsOpen] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const [borderHex] = useToken('colors', ['border']);

  useClickOutside(tooltipRef, () => {
    setIsOpen(false);
  });

  useEffect(() => {
    setIsOpen(errors.length > 0);
  }, [errors.length]);

  return (
    <>
      <Box data-tooltip-id={HOVER_TOOLTIP_ID}>
        <IconButton
          size="sm"
          aria-label=""
          isDisabled={isDisabled}
          data-tooltip-id={TOOLTIP_ID}
          variant={errors.length === 0 ? 'ghost' : 'danger'}
          onClick={() => errors.length > 0 && setIsOpen(!isOpen)}
          className="skip-outside-click"
          icon={
            <Box color={errors.length === 0 ? 'black' : 'white'}>
              <Svg name="Error" />
            </Box>
          }
        />
      </Box>

      {!isOpen && (
        <Tooltip
          id={HOVER_TOOLTIP_ID}
          delayShow={250}
          border={`1px solid ${borderHex}`}
          closeEvents={{click: true, mouseleave: true}}
          style={{padding: '0', backgroundColor: 'white'}}
          opacity="1"
        >
          <Box color="bodyText" p="6px 10px" fontSize="12px">
            Ошибки
          </Box>
        </Tooltip>
      )}

      <Tooltip
        id={TOOLTIP_ID}
        isOpen={isOpen}
        opacity="1"
        border={`1px solid ${borderHex}`}
        offset={4}
        style={{
          padding: '0',
          backgroundColor: 'white',
          boxShadow: 'shadow_6'
        }}
      >
        <Box
          ref={tooltipRef}
          p="8px 16px"
          width="400px"
          maxHeight="400px"
          color="bodyText"
          fontSize="12px"
          overflowY="auto"
          pointerEvents="all"
        >
          {errors.map((error, index) => (
            <Box key={index} color="error" /*className="ellipsis-three"*/>
              <Text fontWeight={600} pr="4px" display="inline">
                {error.title}.
              </Text>
              {error.message}
            </Box>
          ))}
        </Box>
      </Tooltip>
    </>
  );
};

export const ErrorIconButton = observer(ErrorIconButtonFC);
