/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {ElementOut} from './ElementOut';
import {ElementOutFromJSON, ElementOutFromJSONTyped, ElementOutToJSON} from './ElementOut';

/**
 *
 * @export
 * @interface ElementsOut
 */
export interface ElementsOut {
  /**
   *
   * @type {Array<ElementOut>}
   * @memberof ElementsOut
   */
  elements: Array<ElementOut>;
}

/**
 * Check if a given object implements the ElementsOut interface.
 */
export function instanceOfElementsOut(value: object): value is ElementsOut {
  if (!('elements' in value) || value['elements'] === undefined) return false;
  return true;
}

export function ElementsOutFromJSON(json: any): ElementsOut {
  return ElementsOutFromJSONTyped(json, false);
}

export function ElementsOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): ElementsOut {
  if (json == null) {
    return json;
  }
  return {
    elements: (json['elements'] as Array<any>).map(ElementOutFromJSON)
  };
}

export function ElementsOutToJSON(value?: ElementsOut | null): any {
  if (value == null) {
    return value;
  }
  return {
    elements: (value['elements'] as Array<any>).map(ElementOutToJSON)
  };
}
