import {FC, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {Center} from '@chakra-ui/react';
import {Loader} from '@progress-fe/ui-kit';

import {createRoutesNode} from 'core/utils';
import {ROUTES} from 'core/constants';
import {useStore} from 'core/hooks';

import {AppAuth} from './AppAuth';
import {MAIN_ROUTES} from './App.routes';

const AppFC: FC = () => {
  const {initApplication, isInitialized} = useStore();

  useEffect(() => {
    const init = async () => {
      await initApplication();
    };

    init();
  }, [initApplication]);

  if (!isInitialized) {
    return (
      <Center h="100vh" bg="darkWhite">
        <Loader />
      </Center>
    );
  }

  return <AppAuth>{createRoutesNode(MAIN_ROUTES, ROUTES.MyProjects)}</AppAuth>;
};

export const App = observer(AppFC);
