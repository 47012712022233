import {Grid, GridItem} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';
import {FC, useCallback, useEffect} from 'react';
import {useParams} from 'react-router';
import {EStructureItem} from '@progress-fe/core';

import {ProjectLayout} from 'ui-kit';
import {useStore} from 'core/hooks';

import {SamplesAndOilStructure} from './components';
import {SamplesForms} from './components/SamplesForms';

const SamplesAndOilSceneFC: FC = () => {
  const {samplesAndOilStore} = useStore();
  const {uiState, projectProbes, projectBlends} = samplesAndOilStore;

  const {id} = useParams();

  useEffect(() => {
    samplesAndOilStore.initProject(id || '');

    return () => {
      samplesAndOilStore.uninitialize();
    };
  }, [id, samplesAndOilStore]);

  const handleCreateEntity = useCallback(
    (entityType: EStructureItem): void => {
      if (entityType === EStructureItem.Probe) {
        projectProbes.createProbe();
        return;
      }
      projectBlends.createBlend();
    },
    [projectBlends, projectProbes]
  );

  const handleSelectEntity = useCallback(
    (entityId: string, entityType: EStructureItem): void => {
      uiState.select(entityType, entityId);
    },
    [uiState]
  );

  return (
    <ProjectLayout project={samplesAndOilStore}>
      <Grid position="relative" h="100%" gridTemplateColumns={'280px 440px 1fr'}>
        {/* PROJECT ITEMS: SETTINGS, ELEMENTS, MODELS ... */}
        <GridItem bg="bg" borderRight="1px" borderColor="border">
          <SamplesAndOilStructure
            probes={projectProbes.probes}
            blends={projectBlends.blends}
            selectedId={uiState.entityId}
            onSelectEntity={handleSelectEntity}
            onCreateModel={handleCreateEntity}
          />
        </GridItem>

        {/* SELECTED PROJECT ITEM */}
        <GridItem
          bg="bg"
          borderRight="1px"
          borderColor="border"
          overflowY={uiState.entityType === EStructureItem.Settings ? 'hidden' : 'auto'}
        >
          <SamplesForms
            probeInstance={samplesAndOilStore.selectedProbe}
            blendInstance={samplesAndOilStore.selectedBlend}
          />
        </GridItem>

        {/* SHOW STRUCTURE, CALCULATION OR RESULTS */}
        <GridItem>
          <></>
        </GridItem>
      </Grid>
    </ProjectLayout>
  );
};

export const SamplesAndOilScene = observer(SamplesAndOilSceneFC);
