import {applySnapshot, cast, flow, Instance, SnapshotIn, types} from 'mobx-state-tree';
import {EElement} from '@progress-fe/core';

import {RJSFSchemas, TechProcessApi} from 'api';
import {JsonSchema, RequestModel, TJsonSchemaSnapshotIn} from 'core/models';

const ElementDetails = types
  .model('ElementDetails', {
    uuid: types.string,
    name: types.string,
    type: types.enumeration(Object.values(EElement)),
    jsonSchemas: types.optional(types.array(JsonSchema), []),
    jsonSchemasRequest: types.optional(RequestModel, {})
  })
  .actions((self) => ({
    loadJsonSchemas: flow(function* (projectUuid: string, checkpointUuid: string) {
      if (!!self.jsonSchemas.length || self.jsonSchemasRequest.isPending) {
        return;
      }

      const response: {[key: string]: RJSFSchemas} = yield self.jsonSchemasRequest.send(
        TechProcessApi.techProcessGetElementSchemas.bind(TechProcessApi),
        {
          elementUuid: self.uuid,
          projectUuid: projectUuid,
          checkpointUuid: checkpointUuid
        }
      );

      const jsonSchemas: TJsonSchemaSnapshotIn[] = [];
      for (const [tabKey, tabSchema] of Object.entries(response)) {
        jsonSchemas.push({
          id: tabKey,
          name: tabSchema.tabName,
          uiSchema: tabSchema.ui,
          schema: tabSchema.jsonSchema,
          formData: tabSchema.data
        });
      }

      applySnapshot(self.jsonSchemas, jsonSchemas);
    }),
    clearJsonSchemas() {
      self.jsonSchemas = cast([]);
    }
  }))
  .views((self) => ({
    get isSchemasLoading(): boolean {
      return self.jsonSchemasRequest.isPending;
    }
  }));

export type TElementDetailsModel = Instance<typeof ElementDetails>;

export type TElementDetailsSnapshotIn = SnapshotIn<typeof ElementDetails>;

export {ElementDetails};
