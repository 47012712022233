import {Instance, SnapshotIn, types} from 'mobx-state-tree';

import {ResetModel} from '../ResetModel';

const Paginator = types.compose(
  ResetModel,
  types
    .model('Paginator', {
      page: 1,
      size: types.number,
      total: types.number
    })
    .actions((self) => ({
      setPage: (page: number) => {
        self.page = page;
      }
    }))
);

export type TPaginatorModel = Instance<typeof Paginator>;

export type TPaginatorSnapshotIn = SnapshotIn<typeof Paginator>;

export {Paginator};
