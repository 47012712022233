import {FC, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Button, Grid} from '@chakra-ui/react';
import {SystemStyleObject} from '@chakra-ui/styled-system';

import {Input, VirtualTable} from '../../../../index';

interface IProps {
  tableSx?: SystemStyleObject;
}

const HypotheticalComponentsFC: FC<IProps> = ({tableSx}) => {
  const [query, setQuery] = useState('');

  return (
    <Grid templateRows="auto 1fr" gap="8px">
      <Grid gridTemplateColumns="1fr 140px" gap="8px">
        <Input size="xs" variant="outline" value={query} placeholder="Поиск" onChange={setQuery} />
        <Button size="xs" variant="ghost">
          Создать
        </Button>
      </Grid>

      <VirtualTable
        rows={[]}
        columns={[]}
        isCheckboxShown
        noItemsTitle="Компоненты не найдены"
        tableSx={tableSx}
      />
    </Grid>
  );
};

export const HypotheticalComponents = observer(HypotheticalComponentsFC);
