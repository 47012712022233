import {Grid} from '@chakra-ui/react';
import {FieldProps} from '@rjsf/utils';

import {Input} from '../../inputs';
import {JsFieldName} from '../../jsFormBase';

/**
 * Component renders "/schemas/jsf-input" field of JsonSchema.
 * @param props : field props come from JsonSchema.
 * Value of props.formData is string or null.
 */
const InputJsFieldFC = (props: FieldProps) => {
  return (
    <Grid gridTemplateColumns="minmax(0, 180px) 1fr" alignItems="center" gap="4px">
      <JsFieldName name={props.schema.title} />
      <Input
        size="xs"
        variant="outline"
        isOnChangeOnlyOnBlur
        value={props.formData}
        disabled={props.schema.readOnly}
        rightElement={props.schema.$comment ? <>{props.schema.$comment}</> : undefined}
        onChange={(value) => props.onChange(value)}
      />
    </Grid>
  );
};

export const InputJsField = InputJsFieldFC;
