import {FC, memo} from 'react';
import {Box, Flex} from '@chakra-ui/react';
import {ISvgMenuItem, Svg, SvgMenu, TSvgName} from '@progress-fe/ui-kit';
import {RFSvg, TRFSvgName} from '@progress-fe/rf-core';

import {EActionType} from 'core/enums';

interface IProps {
  name: string;
  suffix?: string;
  icon?: TSvgName | null;
  elementIcon?: TRFSvgName | null;
  isActive?: boolean;
  menuItems?: ISvgMenuItem<EActionType>[];
  onMenuAction?: (value: EActionType) => void;
  onClick?: () => void;
}

const EntityItemFC: FC<IProps> = ({
  name,
  suffix,
  icon,
  elementIcon,
  menuItems,
  isActive,
  onClick,
  onMenuAction
}) => {
  return (
    <Flex
      gap="5px"
      p="5px 8px"
      role="group"
      justify="space-between"
      _hover={{bg: isActive ? 'accent' : 'accentMid', cursor: 'pointer'}}
      {...(isActive ? {bg: 'accent', color: 'white'} : {color: 'black'})}
      onClick={() => onClick?.()}
    >
      <Flex gap="5px">
        {!!icon && <Svg name={icon} />}
        {!!elementIcon && <RFSvg name={elementIcon} />}
        <Box maxW={!!suffix ? '130px' : '230px'} className="cut-text">
          {name}
        </Box>
      </Flex>
      <Flex gap="5px">
        {!!suffix && (
          <Box textAlign="right" color={isActive ? 'accentMid' : 'accent'}>
            {suffix}
          </Box>
        )}
        {!!menuItems && (
          <Box visibility="hidden" _groupHover={{visibility: 'visible'}}>
            <SvgMenu
              menuSize={'small'}
              menuIcon={'More'}
              items={menuItems}
              onSelect={(value) => onMenuAction?.(value as EActionType)}
            />
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

export const EntityItem = memo(EntityItemFC);
