import {Instance, SnapshotIn, types} from 'mobx-state-tree';
import {RJSFSchema, UiSchema} from '@rjsf/utils';
import {isObject, isObjectEmpty} from '@progress-fe/core';
import _ from 'lodash-es';

import {ResetModel} from 'core/models';

const JsonSchema = types
  .compose(
    ResetModel,
    types.model('JsonSchema', {
      id: types.string,
      name: types.string,
      uiSchema: types.optional(types.frozen<UiSchema>(), {}),
      schema: types.optional(types.frozen<RJSFSchema>(), {}),
      formData: types.optional(types.frozen<unknown>(), {}),
      resetFormData: types.maybe(types.frozen<unknown>()),
      preventRevertFields: types.optional(types.array(types.string), [])
    })
  )
  .actions((self) => ({
    updateFormData(formData: unknown): void {
      self.formData = formData;
    },
    isResetAllowed(fieldPath?: string): boolean {
      return !self.preventRevertFields.some((field) => field === fieldPath);
    },
    resetFormFields(): void {
      if (!self.resetFormData || isObjectEmpty(self.resetFormData)) return;

      const newFormData = _.cloneDeep(self.formData as object);
      const resetFormData = _.cloneDeep(self.resetFormData as object);

      for (const [keyLevel_1, valueLevel_1] of Object.entries(resetFormData)) {
        if (isObject(valueLevel_1)) {
          for (const [keyLevel_2, valueLevel_2] of Object.entries(valueLevel_1)) {
            _.set(newFormData, `${keyLevel_1}.${keyLevel_2}`, valueLevel_2);
          }
        }
      }

      self.formData = newFormData;
    }
  }))
  .views(() => ({}));

export type TJsonSchemaModel = Instance<typeof JsonSchema>;

export type TJsonSchemaSnapshotIn = SnapshotIn<typeof JsonSchema>;

export {JsonSchema};
