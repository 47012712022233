/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {CreateModelAction} from './CreateModelAction';
import {
  CreateModelActionFromJSON,
  CreateModelActionFromJSONTyped,
  CreateModelActionToJSON
} from './CreateModelAction';
import type {UpdateCalcTaskAction} from './UpdateCalcTaskAction';
import {
  UpdateCalcTaskActionFromJSON,
  UpdateCalcTaskActionFromJSONTyped,
  UpdateCalcTaskActionToJSON
} from './UpdateCalcTaskAction';
import type {UpdateModelAction} from './UpdateModelAction';
import {
  UpdateModelActionFromJSON,
  UpdateModelActionFromJSONTyped,
  UpdateModelActionToJSON
} from './UpdateModelAction';
import type {UpdateElementAction} from './UpdateElementAction';
import {
  UpdateElementActionFromJSON,
  UpdateElementActionFromJSONTyped,
  UpdateElementActionToJSON
} from './UpdateElementAction';
import type {UpdateWorkZoneJsonAction} from './UpdateWorkZoneJsonAction';
import {
  UpdateWorkZoneJsonActionFromJSON,
  UpdateWorkZoneJsonActionFromJSONTyped,
  UpdateWorkZoneJsonActionToJSON
} from './UpdateWorkZoneJsonAction';
import type {CreateElementAction} from './CreateElementAction';
import {
  CreateElementActionFromJSON,
  CreateElementActionFromJSONTyped,
  CreateElementActionToJSON
} from './CreateElementAction';
import type {DeleteElementAction} from './DeleteElementAction';
import {
  DeleteElementActionFromJSON,
  DeleteElementActionFromJSONTyped,
  DeleteElementActionToJSON
} from './DeleteElementAction';
import type {DeleteModelAction} from './DeleteModelAction';
import {
  DeleteModelActionFromJSON,
  DeleteModelActionFromJSONTyped,
  DeleteModelActionToJSON
} from './DeleteModelAction';

/**
 *
 * @export
 * @interface TechProcessApplyActionsRequestInner
 */
export interface TechProcessApplyActionsRequestInner {
  /**
   *
   * @type {any}
   * @memberof TechProcessApplyActionsRequestInner
   */
  workZoneJson?: any | null;
  /**
   *
   * @type {string}
   * @memberof TechProcessApplyActionsRequestInner
   */
  type: TechProcessApplyActionsRequestInnerTypeEnum;
  /**
   *
   * @type {any}
   * @memberof TechProcessApplyActionsRequestInner
   */
  args: any | null;
}

/**
 * @export
 */
export const TechProcessApplyActionsRequestInnerTypeEnum = {
  UpdateWorkZoneJson: 'update_work_zone_json',
  CreateElement: 'create_element',
  UpdateElement: 'update_element',
  DeleteElement: 'delete_element',
  CreateModel: 'create_model',
  UpdateModel: 'update_model',
  DeleteModel: 'delete_model',
  UpdateCalcTask: 'update_calc_task',
  UnknownDefaultOpenApi: '11184809'
} as const;
export type TechProcessApplyActionsRequestInnerTypeEnum =
  (typeof TechProcessApplyActionsRequestInnerTypeEnum)[keyof typeof TechProcessApplyActionsRequestInnerTypeEnum];

/**
 * Check if a given object implements the TechProcessApplyActionsRequestInner interface.
 */
export function instanceOfTechProcessApplyActionsRequestInner(
  value: object
): value is TechProcessApplyActionsRequestInner {
  if (!('type' in value) || value['type'] === undefined) return false;
  if (!('args' in value) || value['args'] === undefined) return false;
  return true;
}

export function TechProcessApplyActionsRequestInnerFromJSON(
  json: any
): TechProcessApplyActionsRequestInner {
  return TechProcessApplyActionsRequestInnerFromJSONTyped(json, false);
}

export function TechProcessApplyActionsRequestInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TechProcessApplyActionsRequestInner {
  if (json == null) {
    return json;
  }
  return {
    workZoneJson: json['work_zone_json'] == null ? undefined : json['work_zone_json'],
    type: json['type'],
    args: json['args']
  };
}

export function TechProcessApplyActionsRequestInnerToJSON(
  value?: TechProcessApplyActionsRequestInner | null
): any {
  if (value == null) {
    return value;
  }
  return {
    work_zone_json: value['workZoneJson'],
    type: value['type'],
    args: value['args']
  };
}
