import {RJSFSchema, UiSchema} from '@rjsf/utils';
import {EElement} from '@progress-fe/core';

export const UI_SCHEMA_COMPRESSOR: UiSchema = {
  basic: {
    name: {
      'ui:field': '/schemas/jsf-input'
    },
    elementType: {
      'ui:field': '/schemas/jsf-select'
    },
    modelType: {
      'ui:field': '/schemas/jsf-select'
    }
  },
  connected_elements: {
    name_1: {
      'ui:field': '/schemas/jsf-select-pick-arrow'
    }
  }
};

export const SCHEMA_COMPRESSOR: RJSFSchema = {
  type: 'object',
  properties: {
    basic: {
      title: 'Базовые свойства',
      type: 'object',
      required: ['name', 'elementType', 'modelType'],
      properties: {
        name: {
          title: 'Имя',
          type: 'string',
          minLength: 1
        },
        elementType: {
          title: 'Тип элемента',
          type: 'string',
          readOnly: true,
          oneOf: [{const: EElement.CompressorElement, title: 'Компрессор'}]
        },
        modelType: {
          title: 'Тип модели',
          type: 'string',
          readOnly: true,
          oneOf: [{const: 'EModelType.Compressor', title: 'Простая'}]
        }
      }
    },
    connected_elements: {
      title: 'Связанные элементы',
      type: 'object',
      properties: {
        name_1: {
          title: 'Компрессор',
          type: 'string',
          oneOf: [{const: '196C89E0-0000-0000-0000-000000000002', title: 'K-100', readOnly: true}],
          readOnly: true
        }
      }
    }
  }
};

export const FORM_DATA_COMPRESSOR = {
  basic: {
    name: 'Компрессор-1',
    elementType: EElement.CompressorElement,
    modelType: 'EModelType.Compressor'
  },
  connected_elements: {
    name_1: '196C89E0-0000-0000-0000-000000000002'
  }
};
