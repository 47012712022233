/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ElementTemplate } from './ElementTemplate';
import {
    ElementTemplateFromJSON,
    ElementTemplateFromJSONTyped,
    ElementTemplateToJSON,
} from './ElementTemplate';
import type { ElementClassName } from './ElementClassName';
import {
    ElementClassNameFromJSON,
    ElementClassNameFromJSONTyped,
    ElementClassNameToJSON,
} from './ElementClassName';

/**
 * 
 * @export
 * @interface Element
 */
export interface Element {
    /**
     * 
     * @type {ElementClassName}
     * @memberof Element
     */
    type: ElementClassName;
    /**
     * 
     * @type {string}
     * @memberof Element
     */
    name: string;
    /**
     * 
     * @type {Array<ElementTemplate>}
     * @memberof Element
     */
    templates: Array<ElementTemplate>;
    /**
     * 
     * @type {boolean}
     * @memberof Element
     */
    isDisabled: boolean;
}

/**
 * Check if a given object implements the Element interface.
 */
export function instanceOfElement(value: object): value is Element {
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('templates' in value) || value['templates'] === undefined) return false;
    if (!('isDisabled' in value) || value['isDisabled'] === undefined) return false;
    return true;
}

export function ElementFromJSON(json: any): Element {
    return ElementFromJSONTyped(json, false);
}

export function ElementFromJSONTyped(json: any, ignoreDiscriminator: boolean): Element {
    if (json == null) {
        return json;
    }
    return {
        
        'type': ElementClassNameFromJSON(json['type']),
        'name': json['name'],
        'templates': ((json['templates'] as Array<any>).map(ElementTemplateFromJSON)),
        'isDisabled': json['is_disabled'],
    };
}

export function ElementToJSON(value?: Element | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': ElementClassNameToJSON(value['type']),
        'name': value['name'],
        'templates': ((value['templates'] as Array<any>).map(ElementTemplateToJSON)),
        'is_disabled': value['isDisabled'],
    };
}

