import {FC, memo, useCallback, useState} from 'react';
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text
} from '@chakra-ui/react';

import {ProjectTypeEnum} from 'api';

import {IProjectTypeProps, ProjectType} from './components';

type TProjectBlocks = Pick<IProjectTypeProps, 'name' | 'description' | 'icon'> & {
  type: ProjectTypeEnum;
};

const PROJECTS_TYPES: TProjectBlocks[] = [
  {
    name: 'Пробы и смеси',
    description: 'Расчёт псевдокомпонентного состава пакета нефтей',
    icon: 'Mix',
    type: ProjectTypeEnum.SamplesAndOilBlend
  },
  {
    name: 'Техпроцесс',
    description: 'Моделирование химико-технологического процесса',
    icon: 'Production',
    type: ProjectTypeEnum.TechProcess
  }
];

interface IProps {
  onCreate: (type: ProjectTypeEnum) => void;
  onClose: () => void;
}

const CreateProjectModalFC: FC<IProps> = ({onCreate, onClose}) => {
  const [selectedType, setSelectedType] = useState<ProjectTypeEnum | null>(null);

  const handleCreateClick = useCallback(() => {
    if (!selectedType) {
      return;
    }

    onCreate(selectedType);
  }, [selectedType, onCreate]);

  const handleTypeSelect = useCallback((type: ProjectTypeEnum) => {
    setSelectedType(type);
  }, []);

  return (
    <Modal isOpen={true} onClose={onClose} isCentered>
      <ModalOverlay background="black50" />
      <ModalContent
        p="40px 68px 48px 68px"
        gap="48px"
        minW="640px"
        maxW="640px"
        boxSizing="border-box"
      >
        <ModalHeader p={0}>
          <Text align="center" mb="8px" textStyle="title">
            Создание проекта
          </Text>
          <Text align="center" textStyle="secondary">
            Выберите категорию проекта
          </Text>
        </ModalHeader>
        <ModalBody p={0}>
          <Flex gap="24px">
            {PROJECTS_TYPES.map((options) => (
              <ProjectType
                key={options.type}
                isSelected={selectedType === options.type}
                onSelect={() => handleTypeSelect(options.type)}
                opacity={selectedType && selectedType !== options.type ? 0.6 : 1}
                {...options}
              />
            ))}
          </Flex>
        </ModalBody>
        <ModalFooter p={0} justifyContent="center">
          <Button
            variant="solid"
            size="md"
            width="140px"
            isDisabled={!selectedType}
            onClick={handleCreateClick}
          >
            Создать
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export const CreateProjectModal = memo(CreateProjectModalFC);
