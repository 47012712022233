import {memo} from 'react';
import {FieldProps} from '@rjsf/utils';
import {Box, Flex, Grid, GridItem} from '@chakra-ui/react';
import {useT} from '@progress-fe/core';

import {InputNumber, Select} from '../../inputs';
import {ISelectOption} from '../../../interfaces';
import {JsFieldName} from '../../jsFormBase';

import {
  TGroupCompositionComponentItem,
  TGroupCompositionCrackingFormData
} from './GroupCompositionCrackingJsField.types';

interface IProps extends FieldProps {
  formData: TGroupCompositionCrackingFormData;
}

/**
 * Component renders "/schemas/jsf-group-composition-cracking" field of JsonSchema.
 * @param props : field props come from JsonSchema.
 * Value of props.formData is an instance of TGroupCompositionCrackingFormData.
 */
const GroupCompositionCrackingJsFieldFC = (props: IProps) => {
  const {t} = useT();

  const formData = props.formData;
  const formValue = props.formData.fieldValues;

  const handleChangeFormOptionValue = (option: ISelectOption<string | number>) => {
    props.onChange({
      ...formData,
      fieldValues: {
        ...formData.fieldValues,
        optionValue: option.value
      }
    });
  };

  const handleChangeValue = (updated: TGroupCompositionComponentItem, value: string | null) => {
    const newItems = formValue.items.map((item) =>
      item.uuid === updated.uuid ? {...updated, value} : item
    );

    props.onChange({
      ...formData,
      fieldValues: {
        ...formData.fieldValues,
        items: newItems
      }
    });
  };

  return (
    <Box data-testid="FlowCompositionJsField-test">
      <Flex p="4px 0" flexDirection="column" gap="8px">
        {/* SELECT FIELD */}
        <Grid gridTemplateColumns={`minmax(0, 180px) 1fr`} alignItems={'center'}>
          <JsFieldName name={formData.fieldConfig.title} />
          <Select
            name={props.name}
            options={formData.fieldConfig.options}
            isDisabled={props.schema.readOnly}
            placeholder={t('common.notSelected')}
            value={formData.fieldConfig.options.find(
              (opt) => opt.value === formData.fieldValues.optionValue
            )}
            onChange={(newValue) => {
              handleChangeFormOptionValue(newValue as ISelectOption<string | number>);
            }}
          />
        </Grid>

        {/* COMPONENTS LIST HEADER */}
        <Grid
          gap="4px"
          p="0 0 4px 0"
          alignItems="center"
          borderBottom="1px solid"
          borderColor="border"
          templateColumns="176px 1fr"
        >
          <GridItem>{t('common.component')}</GridItem>
          <GridItem>{t('common.quantity')}</GridItem>
        </Grid>

        {/* COMPONENTS LIST ITEMS */}
        <Box p="2px 0">
          {formValue.items.map((item) => (
            <Grid key={item.uuid} gap="4px" p="2px 0" templateColumns="176px 1fr">
              <Flex align="center">{item.name}</Flex>
              <InputNumber
                isFloat
                min={0}
                size="xs"
                variant="outline"
                sx={{width: '100%'}}
                isOnChangeOnlyOnBlur
                disabled={props.schema.readOnly}
                value={item.value || undefined}
                onChange={(value) => handleChangeValue(item, value)}
              />
            </Grid>
          ))}
        </Box>
      </Flex>
    </Box>
  );
};

export const GroupCompositionCrackingJsField = memo(GroupCompositionCrackingJsFieldFC);
