/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CalculationsResultEnum = {
    NoCalcResult: 'NO_CALC_RESULT',
    CalcInProgress: 'CALC_IN_PROGRESS',
    CalcResultSuccess: 'CALC_RESULT_SUCCESS',
    CalcResultError: 'CALC_RESULT_ERROR',
    UnknownDefaultOpenApi: '11184809'
} as const;
export type CalculationsResultEnum = typeof CalculationsResultEnum[keyof typeof CalculationsResultEnum];


export function instanceOfCalculationsResultEnum(value: any): boolean {
    for (const key in CalculationsResultEnum) {
        if (Object.prototype.hasOwnProperty.call(CalculationsResultEnum, key)) {
            if ((CalculationsResultEnum as Record<string, CalculationsResultEnum>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function CalculationsResultEnumFromJSON(json: any): CalculationsResultEnum {
    return CalculationsResultEnumFromJSONTyped(json, false);
}

export function CalculationsResultEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalculationsResultEnum {
    return json as CalculationsResultEnum;
}

export function CalculationsResultEnumToJSON(value?: CalculationsResultEnum | null): any {
    return value as any;
}

