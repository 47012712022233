import {cast, flow, Instance, types} from 'mobx-state-tree';
import {v4 as uuidv4} from 'uuid';
import {EElement} from '@progress-fe/core';

import {DeleteModelPossibility, ModelsOut, type TechProcessActionResult, TechProcessApi} from 'api';
import {ModelDetails, RequestModel, ResetModel} from 'core/models';
import {MODELS_LIST} from 'core/mocks/models.mocks';

const ProjectModels = types
  .compose(
    ResetModel,
    types.model('ProjectModels', {
      projectUuid: '',
      checkpointUuid: '',
      fetchRequest: types.optional(RequestModel, {}),
      actionRequest: types.optional(RequestModel, {}),
      models: types.optional(types.array(ModelDetails), [])
    })
  )
  .actions((self) => ({
    clearJsonSchemas() {
      self.models.forEach((model) => {
        model.clearJsonSchemas();
      });
    },

    async loadJsonSchemasByUuid(uuid: string) {
      const model = self.models.find((e) => e.uuid === uuid);
      if (!!model) {
        await model.loadJsonSchemas(self.projectUuid, self.checkpointUuid);
      }
    }
  }))
  .actions((self) => ({
    init: flow(function* (projectUuid: string, checkpointUuid: string) {
      self.projectUuid = projectUuid;
      self.checkpointUuid = checkpointUuid;
      self.models = cast([]);

      // TODO: Temp. Removal
      const mockModels = MODELS_LIST.find((m) => m.projectId === projectUuid)?.items || [];
      if (mockModels.length) {
        self.models = cast(mockModels);
        return;
      }

      const response: ModelsOut = yield self.fetchRequest.send(
        TechProcessApi.techProcessGetModels.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        }
      );

      if (!!response?.models) {
        self.models = cast(
          response.models.map((m) => ({
            uuid: m.uuid,
            name: m.name,
            type: m.type as EElement
          }))
        );
      }
    }),
    addModel: flow(function* (modelUuid: string) {
      const response: TechProcessActionResult = yield self.actionRequest.send(
        TechProcessApi.techProcessCreateModel.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid,
          idempotencyKey: uuidv4(),
          newModel: {
            uuid: modelUuid
          }
        }
      );

      return self.actionRequest.isDone && !!response
        ? ModelDetails.create({
            uuid: response.data.uuid,
            name: response.data.name,
            type: response.data.type as EElement
          })
        : null;
    }),
    removeModel: flow(function* (uuid: string) {
      yield self.actionRequest.send(
        TechProcessApi.techProcessDeleteModelInstance.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid,
          modelInstanceUuid: uuid
        }
      );

      return self.actionRequest.isDone;
    })
  }))
  .actions((self) => ({
    getElementsInUse: flow(function* (uuid: string) {
      const response: DeleteModelPossibility = yield self.actionRequest.send(
        TechProcessApi.techProcessCheckModelInstanceDeleteable.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid,
          modelInstanceUuid: uuid
        }
      );

      return response.elementNames;
    }),
    hasModel(modelId: string) {
      return self.models.some((m) => m.uuid === modelId);
    }
  }))
  .views((self) => ({
    get isLoading(): boolean {
      return self.fetchRequest.isPending;
    }
  }));

export type TProjectModelsModel = Instance<typeof ProjectModels>;

export {ProjectModels};
