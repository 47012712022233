import {Instance, types} from 'mobx-state-tree';
import {v4 as uuidv4} from 'uuid';

import {ResetModel, ProjectEntityJsonSchemas, TProjectEntityJsonSchemasModel} from 'core/models';
import * as BASIC_MOCKS from 'core/mocks/probes/sample_1a/sample_1a_basic';
import * as MODEL_MOCKS from 'core/mocks/probes/sample_1a/sample_1a_model';

const ProjectProbes = types
  .compose(
    ResetModel,
    types.model('ProjectProbes', {
      probes: types.optional(types.array(ProjectEntityJsonSchemas), [])
    })
  )
  .actions((self) => ({
    // TODO: only for dev purpose should be removed
    afterCreate() {
      self.probes.push(
        ProjectEntityJsonSchemas.create({
          id: uuidv4(),
          name: `Тестовая проба ${self.probes.length + 1}`,
          jsonSchemas: [
            {
              id: uuidv4(),
              name: 'Базовые',
              uiSchema: BASIC_MOCKS.UI_SCHEMA_SAMPLE_1A_BASIC,
              schema: BASIC_MOCKS.SCHEMA_SAMPLE_1A_BASIC,
              formData: BASIC_MOCKS.FORM_DATA_SAMPLE_1A_BASIC
            },
            {
              id: uuidv4(),
              name: 'Модель',
              uiSchema: MODEL_MOCKS.UI_SCHEMA_SAMPLE_1A_MODEL,
              schema: MODEL_MOCKS.SCHEMA_SAMPLE_1A_MODEL,
              formData: MODEL_MOCKS.FORM_DATA_SAMPLE_1A_MODEL
            }
          ]
        })
      );
    },
    fetch(projectId: string): void {
      // TODO
      console.info(projectId);
    },
    createProbe(): void {
      self.probes.push(
        ProjectEntityJsonSchemas.create({
          id: uuidv4(),
          name: `Тестовая проба ${self.probes.length + 1}`,
          jsonSchemas: [
            {
              id: uuidv4(),
              name: 'Базовые',
              uiSchema: BASIC_MOCKS.UI_SCHEMA_SAMPLE_1A_BASIC,
              schema: BASIC_MOCKS.SCHEMA_SAMPLE_1A_BASIC,
              formData: BASIC_MOCKS.FORM_DATA_SAMPLE_1A_BASIC
            },
            {
              id: uuidv4(),
              name: 'Модель',
              uiSchema: MODEL_MOCKS.UI_SCHEMA_SAMPLE_1A_MODEL,
              schema: MODEL_MOCKS.SCHEMA_SAMPLE_1A_MODEL,
              formData: MODEL_MOCKS.FORM_DATA_SAMPLE_1A_MODEL
            }
          ]
        })
      );
    },
    hasProbe(elementId: string): boolean {
      return self.probes.some((e) => e.id === elementId);
    },
    findProbe(elementId: string): TProjectEntityJsonSchemasModel | undefined {
      return self.probes.find((e) => e.id === elementId);
    }
  }))
  .views(() => ({}));

export type TProjectProbesModel = Instance<typeof ProjectProbes>;

export {ProjectProbes};
