/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ElementClassName } from './ElementClassName';
import {
    ElementClassNameFromJSON,
    ElementClassNameFromJSONTyped,
    ElementClassNameToJSON,
} from './ElementClassName';

/**
 * 
 * @export
 * @interface Model
 */
export interface Model {
    /**
     * 
     * @type {string}
     * @memberof Model
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof Model
     */
    version: string;
    /**
     * 
     * @type {string}
     * @memberof Model
     */
    name: string;
    /**
     * 
     * @type {ElementClassName}
     * @memberof Model
     */
    elementType: ElementClassName;
    /**
     * 
     * @type {string}
     * @memberof Model
     */
    systemOfUnits: string;
    /**
     * 
     * @type {Date}
     * @memberof Model
     */
    creationDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof Model
     */
    registeredAt: Date;
}

/**
 * Check if a given object implements the Model interface.
 */
export function instanceOfModel(value: object): value is Model {
    if (!('uuid' in value) || value['uuid'] === undefined) return false;
    if (!('version' in value) || value['version'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('elementType' in value) || value['elementType'] === undefined) return false;
    if (!('systemOfUnits' in value) || value['systemOfUnits'] === undefined) return false;
    if (!('creationDate' in value) || value['creationDate'] === undefined) return false;
    if (!('registeredAt' in value) || value['registeredAt'] === undefined) return false;
    return true;
}

export function ModelFromJSON(json: any): Model {
    return ModelFromJSONTyped(json, false);
}

export function ModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): Model {
    if (json == null) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'version': json['version'],
        'name': json['name'],
        'elementType': ElementClassNameFromJSON(json['element_type']),
        'systemOfUnits': json['system_of_units'],
        'creationDate': (new Date(json['creation_date'])),
        'registeredAt': (new Date(json['registered_at'])),
    };
}

export function ModelToJSON(value?: Model | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uuid': value['uuid'],
        'version': value['version'],
        'name': value['name'],
        'element_type': ElementClassNameToJSON(value['elementType']),
        'system_of_units': value['systemOfUnits'],
        'creation_date': ((value['creationDate']).toISOString()),
        'registered_at': ((value['registeredAt']).toISOString()),
    };
}

