import {RJSFSchema, UiSchema} from '@rjsf/utils';
import {EElement} from '@progress-fe/core';

export const UI_SCHEMA_ENERGY_FLOW_BASIC: UiSchema = {
  basic: {
    name: {
      'ui:field': '/schemas/jsf-input'
    },
    elementType: {
      'ui:field': '/schemas/jsf-select'
    }
  },
  connections: {
    source: {
      'ui:field': '/schemas/jsf-select-pick-arrow'
    },
    consumer: {
      'ui:field': '/schemas/jsf-select-pick-arrow'
    }
  },
  characteristics: {
    energyflow2_energy: {
      'ui:field': '/schemas/jsf-input'
    }
  }
};

export const SCHEMA_ENERGY_FLOW_BASIC: RJSFSchema = {
  type: 'object',
  properties: {
    basic: {
      title: 'Базовые свойства',
      type: 'object',
      required: ['name', 'elementType'],
      properties: {
        name: {
          title: 'Имя',
          type: 'string',
          minLength: 1
        },
        elementType: {
          title: 'Тип элемента',
          type: 'string',
          oneOf: [{const: EElement.EnergyFlowElement, title: 'Энергетический поток'}],
          readOnly: true
        }
      }
    },
    connections: {
      title: 'Связи',
      type: 'object',
      required: ['source'],
      properties: {
        source: {
          title: 'Источник',
          type: 'string',
          oneOf: [
            {const: '196C89E0-0000-0000-0000-000000000001', title: 'V-100', readOnly: true},
            {const: '196C89E0-0000-0000-0000-000000000002', title: 'K-100', readOnly: true},
            {const: '196C89E0-0000-0000-0000-000000000003', title: 'E-100'},
            {const: '196C89E0-0000-0000-0000-000000000004', title: 'VLV-100', readOnly: true},
            {const: '196C89E0-0000-0000-0000-000000000005', title: 'V-101', readOnly: true}
          ]
        },
        consumer: {
          title: 'Потребитель',
          type: ['string', 'null'],
          readOnly: true,
          oneOf: [
            {const: null},
            {const: '196C89E0-0000-0000-0000-000000000001', title: 'V-100', readOnly: true},
            {const: '196C89E0-0000-0000-0000-000000000002', title: 'K-100', readOnly: true},
            {const: '196C89E0-0000-0000-0000-000000000003', title: 'E-100', readOnly: true},
            {const: '196C89E0-0000-0000-0000-000000000004', title: 'VLV-100', readOnly: true},
            {const: '196C89E0-0000-0000-0000-000000000005', title: 'V-101', readOnly: true}
          ]
        }
      }
    },
    characteristics: {
      title: 'Характеристики',
      type: 'object',
      properties: {
        energyflow2_energy: {
          title: 'Энергия',
          type: ['number', 'null'],
          exclusiveMinimum: 0,
          readOnly: true,
          $comment: 'кВт'
        }
      }
    }
  }
};

export const FORM_DATA_ENERGY_FLOW_BASIC = {
  basic: {
    name: 'Q-101',
    elementType: EElement.EnergyFlowElement
  },
  connections: {
    source: '196C89E0-0000-0000-0000-000000000003',
    consumer: null
  },
  characteristics: {
    energyflow2_energy: null
  }
};

export const REVERT_FORM_DATA = {
  characteristics: {
    energyflow2_energy: null
  }
};
