import {Instance, SnapshotIn, types} from 'mobx-state-tree';

import {JsonSchema} from 'core/models';
import {EElementResultType} from 'core/enums';
import {CalculationResultItem} from 'core/models/CalculationResultItem';

const ResultDetails = types
  .model('ResultDetails', {
    id: types.string,
    name: types.string,
    element_id: types.string,
    type: types.enumeration(Object.values(EElementResultType)),
    icon: types.string,
    jsonSchemas: types.optional(types.array(JsonSchema), []),
    calculationData: types.maybeNull(CalculationResultItem)
  })

  .actions((self) => ({
    clearCalculationData(): void {
      self.calculationData = null;
    }
  }))
  .views((self) => ({
    get namePropValue(): string {
      return self.jsonSchemas.length > 0
        ? // @ts-ignore
          self.jsonSchemas[0].formData?.basic?.name || ''
        : '';
    }
  }));

export type TResultDetailsModel = Instance<typeof ResultDetails>;

export type TResultDetailsSnapshotIn = SnapshotIn<typeof ResultDetails>;

export {ResultDetails};
