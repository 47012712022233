import {FC, DragEvent} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Flex} from '@chakra-ui/react';
import {ERFElement, IRFMenuItem, RFSvg} from '@progress-fe/rf-core';

interface IProps {
  item: IRFMenuItem;
  updateIsDragging: (isDragging: boolean) => void;
  onDragStart?: (event: DragEvent<HTMLDivElement>, type: ERFElement, template: string) => void;
  onClick?: () => void;
}

const RFMenuGroupItemFC: FC<IProps> = ({item, updateIsDragging, onDragStart, onClick}) => {
  return (
    <Flex
      gap="8px"
      p="0 8px 0 4px"
      height="40px"
      borderRadius="4px"
      draggable={item.isDraggable}
      opacity={item.isDisabled ? '0.4' : '1'}
      alignItems="center"
      onClick={onClick || item.onClick}
      className={item.isDraggable ? 'dndnode' : ''}
      onDragEnd={() => updateIsDragging(false)}
      onDragStart={(event) => {
        if (item.isDraggable && item.type) {
          onDragStart?.(event, item.type, item.templateCode || '');
          updateIsDragging(true);
        }
      }}
      _hover={{
        cursor: item.isDraggable ? 'grab' : 'not-allowed',
        bg: !item.isDisabled ? 'accent' : 'transparent',
        color: !item.isDisabled && 'white'
      }}
    >
      <RFSvg name={item.icon} size={item.iconSize || 's32'} />
      <Box sx={{textWrap: 'nowrap'}}>{item.name}</Box>
    </Flex>
  );
};

export const RFMenuGroupItem = observer(RFMenuGroupItemFC);
