import {Grid} from '@chakra-ui/react';
import {FieldProps} from '@rjsf/utils';
import {isDefined} from '@progress-fe/core';

import {JsFieldName} from '../../jsFormBase';
import {InputNumber} from '../../inputs';

interface IProps extends FieldProps {
  isFloat?: boolean;
}

/**
 * Component renders "/schemas/jsf-number" or "/schemas/jsf-float" field of JsonSchema.
 * @param props : field props come from JsonSchema.
 * Value of props.formData is string or null.
 */
const NumberJsFieldFC = (props: IProps) => {
  const min = isDefined(props.schema.exclusiveMinimum)
    ? props.schema.exclusiveMinimum
    : props.schema.minimum;

  const max = isDefined(props.schema.exclusiveMaximum)
    ? props.schema.exclusiveMaximum
    : props.schema.maximum;

  return (
    <Grid gridTemplateColumns="minmax(0, 180px) 1fr" alignItems="center" gap="4px">
      <JsFieldName name={props.schema.title} />

      <InputNumber
        min={min}
        max={max}
        size="xs"
        variant="outline"
        isOnChangeOnlyOnBlur
        value={props.formData}
        isFloat={props.isFloat}
        disabled={props.schema.readOnly}
        rightElement={props.schema.$comment ? <>{props.schema.$comment}</> : undefined}
        onChange={(value) => props.onChange(value)}
      />
    </Grid>
  );
};

export const NumberJsField = NumberJsFieldFC;
