/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProjectOut } from './ProjectOut';
import {
    ProjectOutFromJSON,
    ProjectOutFromJSONTyped,
    ProjectOutToJSON,
} from './ProjectOut';

/**
 * 
 * @export
 * @interface ResponseStatusProjectOut
 */
export interface ResponseStatusProjectOut {
    /**
     * 
     * @type {string}
     * @memberof ResponseStatusProjectOut
     */
    status: string;
    /**
     * 
     * @type {ProjectOut}
     * @memberof ResponseStatusProjectOut
     */
    data?: ProjectOut | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseStatusProjectOut
     */
    errorMsg?: string | null;
}

/**
 * Check if a given object implements the ResponseStatusProjectOut interface.
 */
export function instanceOfResponseStatusProjectOut(value: object): value is ResponseStatusProjectOut {
    if (!('status' in value) || value['status'] === undefined) return false;
    return true;
}

export function ResponseStatusProjectOutFromJSON(json: any): ResponseStatusProjectOut {
    return ResponseStatusProjectOutFromJSONTyped(json, false);
}

export function ResponseStatusProjectOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseStatusProjectOut {
    if (json == null) {
        return json;
    }
    return {
        
        'status': json['status'],
        'data': json['data'] == null ? undefined : ProjectOutFromJSON(json['data']),
        'errorMsg': json['error_msg'] == null ? undefined : json['error_msg'],
    };
}

export function ResponseStatusProjectOutToJSON(value?: ResponseStatusProjectOut | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status': value['status'],
        'data': ProjectOutToJSON(value['data']),
        'error_msg': value['errorMsg'],
    };
}

