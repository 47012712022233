import {Text, Center, HTMLChakraProps, Stack} from '@chakra-ui/react';
import {Svg, TSvgName} from '@progress-fe/ui-kit';
import {FC, memo, useCallback} from 'react';

export interface IProjectTypeProps extends HTMLChakraProps<'div'> {
  name: string;
  description: string;
  icon: TSvgName;
  isSelected: boolean;
  onSelect: () => void;
}

const ProjectTypeFC: FC<IProjectTypeProps> = ({
  description,
  icon,
  name,
  isSelected,
  onSelect,
  ...rest
}) => {
  const handleClick = useCallback(() => {
    onSelect();
  }, [onSelect]);

  return (
    <Center
      flexDirection="column"
      gap="15px"
      border="2px"
      borderRadius={4}
      borderColor={isSelected ? 'accent' : 'border'}
      p="44px 30px 24px 30px"
      color="black"
      onClick={handleClick}
      cursor="pointer"
      {...rest}
    >
      <Svg name={icon} size="s64" />
      <Stack spacing="4px" alignItems="center">
        <Text fontSize={14} color="black" fontWeight={500}>
          {name}
        </Text>
        <Text textStyle="secondary" align="center" fontSize={12}>
          {description}
        </Text>
      </Stack>
    </Center>
  );
};

export const ProjectType = memo(ProjectTypeFC);
