import {FC} from 'react';
import {FieldProps} from '@rjsf/utils';

import {TABLE_VARIANTS, VirtualTable} from '../../tables';
import {DialogableContainer} from '../../containers';
import {ISimpleTableColumn, ISimpleTableRow} from '../../../interfaces';

import {TTableFormData} from './TableFormData.types';

const DEFAULT_TABLE_HEIGHT = 300;

interface IProps extends FieldProps {
  formData?: TTableFormData;
}

export const TableJsField: FC<IProps> = ({schema, formData, onChange}) => {
  const config = formData?.fieldConfig;
  const values = formData?.fieldValues;

  const columns: ISimpleTableColumn[] = config?.columns || [];

  const rows: ISimpleTableRow<string>[] = (values || []).map((rowValues, idx) => ({
    key: idx.toString(),
    values: rowValues
  }));

  const handleValuChange = (rowId: number, colomnId: number, newValue: string) => {
    if (!values) {
      return;
    }
    const newTableData = structuredClone(values);
    newTableData[rowId][colomnId] = newValue;

    onChange({
      ...formData,
      fieldValues: newTableData
    });
  };

  return (
    <DialogableContainer title={schema.title || ''} dialogMinWidth="70%" floatMinWidth="30%">
      <VirtualTable
        variant={config?.isFixedColumn ? TABLE_VARIANTS.FIRST_COLOMN_FIXED : undefined}
        columns={columns}
        rows={rows}
        tableSx={{
          height: config?.height ?? DEFAULT_TABLE_HEIGHT
        }}
        onValueChange={handleValuChange}
      />
    </DialogableContainer>
  );
};
