import {Instance, SnapshotIn, types} from 'mobx-state-tree';

import {BaseColumnResult} from 'core/models/BaseColumnResult';

const SliderPointItem = types
  .model('SliderPointItem', {
    title: types.string,
    value: types.number,
    columns: types.optional(types.array(BaseColumnResult), [])
  })
  .actions(() => ({}))
  .views(() => ({}));

export type TSliderPointItemModel = Instance<typeof SliderPointItem>;

export type TSliderPointItemSnapshotIn = SnapshotIn<typeof SliderPointItem>;

export {SliderPointItem};
