import {cast, flow, Instance, types} from 'mobx-state-tree';
import {IErrorMessage} from '@progress-fe/ui-kit';
import {hasValue} from '@progress-fe/core';

import {ProjectOut, ProjectsApi} from 'api';
import {RequestModel, ResetModel} from 'core/models';
import {PROJECT_LIST} from 'core/mocks/projects.mocks';

import {UiState} from './models';

const ProjectBase = types
  .compose(
    ResetModel,
    types.model('ProjectBase', {
      isLoading: false,
      checkpointUuid: '',
      uiState: types.optional(UiState, {}),
      projectInfo: types.maybeNull(types.frozen<ProjectOut>()),
      errors: types.optional(types.array(types.frozen<IErrorMessage>()), []),
      isResettingFieldsNeeded: false,
      fetchRequest: types.optional(RequestModel, {})
    })
  )
  .actions((self) => ({
    run: flow(function* () {
      return Promise.resolve(false);
    }),
    resetFields(): void {
      console.info('[Project]: Resetting fields...');
      self.isResettingFieldsNeeded = false;
    }
  }))
  .actions((self) => ({
    _load: flow(function* (projectUuid: string) {
      // FIXME: Temp. Removal
      const mockProject = PROJECT_LIST.find((p) => p.uuid === projectUuid);
      if (mockProject) {
        return mockProject;
      }

      const response: ProjectOut = yield self.fetchRequest.send(
        ProjectsApi.projectsGetProject.bind(ProjectsApi),
        {
          projectUuid
        }
      );

      return response || null;
    })
  }))
  .actions((self) => ({
    _baseInit: flow(function* (projectId: string) {
      const project: ProjectOut | null = yield self._load(projectId);
      if (project && project.checkpoints.length > 0) {
        self.projectInfo = project;
        self.checkpointUuid = project.checkpoints[0].uuid;
      }
    }),
    _setErrors(errors: IErrorMessage[]): void {
      self.errors = cast(errors);
    }
  }))
  .actions((self) => ({
    setCheckpointUuid(index: number): void {
      self.checkpointUuid = self.projectInfo?.checkpoints[index]?.uuid || '';
    },
    createCheckpoint: flow(function* () {
      return Promise.resolve(false);
    })
  }))
  .views((self) => ({
    get projectUuid(): string {
      return self.projectInfo?.uuid || '';
    },
    get checkpointIndex(): number {
      const points = self.projectInfo?.checkpoints;
      const index = points?.findIndex((c) => c.uuid === self.checkpointUuid);
      return hasValue(index) ? index : -1;
    },
    get isCalculating(): boolean {
      return false;
    }
  }));

export type TProjectBaseModel = Instance<typeof ProjectBase>;

export {ProjectBase};
