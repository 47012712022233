import {FC, ReactNode, useCallback, useState} from 'react';
import {Box, Button, Center, Flex, IconButton, Portal} from '@chakra-ui/react';

import {Svg} from '../Svg';

import {DialogWindow} from './components';

export interface IDialogButton {
  title: string;
  isDisabled?: boolean;
  isDanger?: boolean;
  onClick?: () => Promise<void>;
}

export interface IDialogButtons {
  apply?: IDialogButton;
  close?: IDialogButton;
}

interface IProps {
  title: string;
  children?: ReactNode;
  isFloating?: boolean;
  minWidth?: string;
  floatMinWidth?: string;
  buttons?: IDialogButtons;
  onClose?: () => void;
}

const DialogFC: FC<IProps> = ({
  title,
  children,
  isFloating,
  minWidth,
  floatMinWidth,
  buttons,
  onClose
}) => {
  const [isFloat, setIsFloat] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);

  const handleToggleFloat = useCallback(() => {
    setIsFloat((prev) => !prev);
  }, []);

  const renderChildren = () => (
    <Flex direction="column">
      <Box>{children}</Box>
      {!!buttons && (
        <Flex
          gap="8px"
          mt="12px"
          p="12px 0 2px"
          borderTop="1px solid"
          borderColor="border"
          justifyContent="flex-end"
        >
          {!!buttons?.close && (
            <Button
              size="xs"
              variant="ghost"
              isDisabled={buttons.close.isDisabled}
              onClick={async () => {
                await buttons.close?.onClick?.();
                onClose?.();
              }}
            >
              {buttons.close.title}
            </Button>
          )}
          {!!buttons?.apply && (
            <Button
              size="xs"
              isDisabled={buttons.apply.isDisabled || isWaiting}
              variant={buttons.apply.isDanger ? 'danger' : 'solid'}
              onClick={async () => {
                setIsWaiting(true);
                await buttons.apply?.onClick?.();
                setIsWaiting(false);
                onClose?.();
              }}
            >
              {buttons.apply.title}
            </Button>
          )}
        </Flex>
      )}
    </Flex>
  );

  if (isFloat) {
    return (
      <Portal>
        <DialogWindow
          right="0"
          bottom="0"
          zIndex={10}
          position="absolute"
          maxW="30%"
          minW={floatMinWidth}
          title={title}
          onClose={onClose}
          actionElement={
            isFloating ? (
              <IconButton
                size="smSquare"
                aria-label=""
                variant="ghostTr"
                icon={<Svg size="s12" name="Max" />}
                onClick={handleToggleFloat}
              />
            ) : undefined
          }
        >
          {renderChildren()}
        </DialogWindow>
      </Portal>
    );
  }

  return (
    <Portal>
      <Box
        top="0"
        left="0"
        right="0"
        bottom="0"
        zIndex={10}
        position="absolute"
        bg="rgba(255, 255, 255, 0.5)"
      >
        <Center h="100%" w="100%">
          <DialogWindow
            maxW="80%"
            minW={minWidth}
            title={title}
            onClose={onClose}
            actionElement={
              isFloating ? (
                <IconButton
                  size="smSquare"
                  aria-label=""
                  variant="ghostTr"
                  icon={<Svg size="s12" name="Min" />}
                  onClick={handleToggleFloat}
                />
              ) : undefined
            }
          >
            {renderChildren()}
          </DialogWindow>
        </Center>
      </Box>
    </Portal>
  );
};

export const Dialog = DialogFC;
