import {Grid, GridItem, Text} from '@chakra-ui/react';
import {FC, Fragment, memo} from 'react';
import {InputNumber} from '@progress-fe/ui-kit';

import {
  TReactionGroup,
  TReactionItem,
  TReactionsTableConfig
} from '../../ReactionsParametersTableJsField.types';

interface IProps {
  isReadonly: boolean;
  reaction: TReactionGroup;
  fieldConfig: TReactionsTableConfig;
  onChange: (group: TReactionGroup) => void;
}

const GroupReactionTableFC: FC<IProps> = ({reaction, isReadonly, fieldConfig, onChange}) => {
  const handleItemChange = (value: Partial<TReactionItem>, index: number) => {
    const newItems = reaction.items.map((item, idx) =>
      idx === index ? {...item, ...value} : item
    );

    onChange({...reaction, items: newItems});
  };

  return (
    <>
      <Grid
        gap="4px"
        templateColumns="50px 50px minmax(300px, 1fr) 150px 150px 150px 150px"
        gridAutoRows="40px"
        alignItems="center"
        justifyItems="center"
        bg="accentMid"
        display="inline-grid"
        h="40px"
        minW="100%"
      >
        <GridItem>
          <Text textStyle="bold">{reaction.type}</Text>
        </GridItem>
        <GridItem colStart={2} colEnd={-1} textAlign="center">
          <Text textStyle="bold">{reaction.name}</Text>
        </GridItem>
      </Grid>
      <Grid
        gap="4px"
        templateColumns="50px 50px minmax(300px, 1fr) 150px 150px 150px 150px"
        alignItems="center"
        justifyItems="center"
        gridAutoRows="40px"
      >
        {reaction.items.map((item, idx) => {
          return (
            <Fragment key={idx}>
              <GridItem />
              <GridItem textAlign="center">
                <Text>{item.subType}</Text>
              </GridItem>
              <GridItem>
                <Text>{item.formula}</Text>
              </GridItem>
              <GridItem textAlign="center">
                <InputNumber
                  min={0}
                  size="xs"
                  variant="outline"
                  sx={{width: '100%'}}
                  isOnChangeOnlyOnBlur
                  disabled={isReadonly}
                  value={item.energy}
                  rightElement={fieldConfig.units.energy}
                  onChange={(value) => handleItemChange({energy: value || ''}, idx)}
                />
              </GridItem>
              <GridItem textAlign="center">
                <InputNumber
                  min={0}
                  size="xs"
                  variant="outline"
                  sx={{width: '100%'}}
                  isOnChangeOnlyOnBlur
                  disabled={isReadonly}
                  value={item.factor}
                  onChange={(value) => handleItemChange({factor: value || ''}, idx)}
                />
              </GridItem>
              <GridItem textAlign="center">
                <InputNumber
                  min={0}
                  size="xs"
                  variant="outline"
                  sx={{width: '100%'}}
                  isOnChangeOnlyOnBlur
                  disabled={isReadonly}
                  value={item.temperature}
                  rightElement={fieldConfig.units.temperature}
                  onChange={(value) => handleItemChange({temperature: value || ''}, idx)}
                />
              </GridItem>
              <GridItem justifySelf="flex-start">
                <Text>
                  {item.speedConstant} ({item.speedConstantUnit})
                </Text>
              </GridItem>
            </Fragment>
          );
        })}
      </Grid>
    </>
  );
};

export const GroupReactionTable = memo(GroupReactionTableFC);
