import {Box, Flex, Center} from '@chakra-ui/react';
import {Svg} from '@progress-fe/ui-kit';
import {FC, PropsWithChildren} from 'react';
import {useNavigate} from 'react-router';
import {observer} from 'mobx-react-lite';

import {ROUTES} from 'core/constants';

interface IProps extends PropsWithChildren {
  title?: string;
}

const HeaderFC: FC<IProps> = ({title, children}) => {
  const navigate = useNavigate();

  return (
    <Box boxShadow="shadow_1" position="relative" zIndex={2}>
      <Flex bg="bg" h="48px" p="0 8px 0 0" justify="space-between">
        <Center gap="16px">
          <Box
            onClick={() => navigate(ROUTES.MyProjects)}
            _hover={{opacity: '0.8', cursor: 'pointer'}}
          >
            <Svg name="Favicon" size="s48" />
          </Box>
          <Box fontSize="lg" fontWeight="600" color="black" className="cut-text">
            {title}
          </Box>
        </Center>

        {children}
      </Flex>
    </Box>
  );
};

export const Header = observer(HeaderFC);
