import {extendTheme, ThemeConfig} from '@chakra-ui/react';

const config: ThemeConfig = {
  initialColorMode: 'light', // 'dark' | 'light'
  useSystemColorMode: false
};

const styles = {
  global: {
    body: {
      fontWeight: 400,
      overflow: 'hidden',
      fontSize: '12px',
      color: 'bodyText'
    }
  }
};

const colors = {
  bg: '#F0F1F5',
  black: '#000000',
  black50: '#00000050',
  bodyText: '#3D3D3D',
  border: '#D7DAE0',
  border70: '#D7DAE070',
  border50: '#D7DAE050',
  buttonBg: '#DDE1EB',
  error: '#A53232',
  gray: '#4E4E4E',
  highlight: '#A44FA9',
  lightGray: '#B3B3B3',
  disabledGray: '#F6F7F9',
  success: '#59A532',
  white: '#FFFFFF',
  darkWhite: '#F7F8FA',
  accent: '#3B568C',
  accent50: '#AFBCD7',
  accentMid: '#D1DCF3',
  accentLight: '#C6CFE2',
  accentIcon: '#0040A4',
  contrastIcon: '#808080',
  coolIcon: '#5899FF',
  heatIcon: '#D03436',
  mainIcon: '#C5C5C5',
  mFlow: '#333BAF',
  eFlow: '#CC2F5A'
};

const fonts = {
  body: 'Inter, sans-serif',
  heading: 'Inter, sans-serif',
  mono: 'Inter, sans-serif'
};

const textStyles = {
  'modal-header': {
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '24px'
  },
  secondary: {
    fontSize: 14,
    fontWeight: 400,
    opacity: 0.6,
    lineHeight: '16px'
  },
  lg: {
    fontSize: '18px'
  },
  md: {
    fontSize: '16px'
  },
  sm: {
    fontSize: '12px'
  },
  bold: {
    fontWeight: 'bold'
  }
};

const modalStyles = {
  lg: {},
  md: {},
  sm: {}
};

const breakpoints = {};

const components = {
  Text: {
    baseStyle: {
      color: 'black'
    }
  },
  Divider: {
    baseStyle: {
      borderColor: 'border'
    }
  },
  Checkbox: {
    baseStyle: {
      control: {
        bg: 'white',
        borderRadius: '3px',
        border: '1px solid',
        borderColor: 'border',
        _checked: {
          bg: 'accent',
          borderColor: 'accent',
          _hover: {
            bg: 'accent',
            borderColor: 'accent'
          }
        },
        _focus: {
          boxShadow: 'none'
        },
        _disabled: {
          opacity: '0.5'
        }
      }
    },
    sizes: {
      md: {
        control: {
          boxSize: '14px'
        },
        icon: {
          fontSize: '9px'
        }
      }
    }
  },
  Button: {
    variants: {
      solid: () => ({
        bg: 'accent',
        borderRadius: '4px',
        boxShadow: 'shadow_7',
        color: 'white',
        _hover: {
          opacity: '0.9',
          bg: 'accent'
        },
        _disabled: {
          opacity: '0.7',
          _hover: {
            opacity: '0.7'
          }
        }
      }),
      ghost: () => ({
        bg: 'buttonBg',
        borderRadius: '4px',
        color: 'black',
        boxShadow: 'shadow_5',
        _hover: {
          bg: 'border'
        },
        _disabled: {
          opacity: '0.4',
          bg: 'border !important'
        }
      }),
      ghostBg: () => ({
        bg: 'bg',
        borderRadius: '4px',
        color: 'black',
        boxShadow: 'shadow_5',
        _hover: {
          bg: 'buttonBg'
        },
        _disabled: {
          opacity: '0.4',
          bg: 'border !important'
        }
      }),
      ghostTr: () => ({
        bg: 'transparent',
        borderRadius: '4px',
        color: 'black',
        boxShadow: 'none',
        _hover: {
          bg: 'buttonBg'
        },
        _disabled: {
          opacity: '0.4',
          bg: 'border !important'
        }
      }),
      transparent: () => ({
        bg: 'transparent',
        borderRadius: '4px',
        color: 'black',
        boxShadow: 'none',
        _hover: {
          bg: 'transparent'
        },
        _disabled: {
          opacity: '0.4',
          bg: 'transparent !important'
        }
      }),
      danger: () => ({
        bg: 'error',
        borderRadius: '4px',
        color: 'white',
        boxShadow: 'shadow_8',
        _hover: {
          bg: 'error',
          opacity: 0.9
        },
        _disabled: {
          opacity: '0.4',
          bg: 'error !important'
        }
      })
    },
    sizes: {
      xxs: {
        fontWeight: 400,
        width: '20px'
      },
      md: {
        fontWeight: 500,
        fontSize: '14px'
      },
      xs: {
        fontWeight: 400
      },
      sm: {
        fontWeight: 500,
        fontSize: '12px'
      },
      smSquare: {
        h: '24px',
        w: '24px',
        fontWeight: 400,
        borderRadius: '4px'
      }
    }
  },
  Input: {
    sizes: {
      xs: {
        field: {
          padding: '4px'
        },
        addon: {
          p: '0 5px',
          borderRadius: '4px'
        }
      }
    },
    variants: {
      outline: {
        field: {
          bg: 'white',
          borderRadius: '4px',
          border: '1px solid',
          borderColor: 'border70',
          color: 'bodyText',
          _placeholder: {
            color: 'lightGray'
          },
          _hover: {
            bg: 'white'
          },
          _focus: {
            border: '1px solid',
            borderColor: 'border70',
            boxShadow: 'none'
          },
          _disabled: {
            color: 'bodyText',
            bg: 'disabledGray',
            borderColor: 'border50',
            opacity: 1,
            pointerEvents: 'none'
          },
          _invalid: {
            boxShadow: 'none',
            borderColor: 'error'
          }
        },
        addon: {
          border: '1px solid',
          borderColor: 'border70'
        }
      }
    }
  },
  NumberInput: {
    sizes: {
      xs: {
        field: {
          padding: '4px'
        },
        addon: {
          p: '0 5px',
          borderRadius: '4px'
        }
      }
    },
    variants: {
      outline: {
        field: {
          bg: 'white',
          borderRadius: '4px',
          border: '1px solid',
          borderColor: 'border70',
          color: 'bodyText',
          _placeholder: {
            color: 'lightGray'
          },
          _hover: {
            bg: 'white'
          },
          _focus: {
            border: '1px solid',
            borderColor: 'border70',
            boxShadow: 'none'
          },
          _disabled: {
            color: 'bodyText',
            bg: 'disabledGray',
            borderColor: 'border50',
            opacity: 1,
            pointerEvents: 'none'
          },
          _invalid: {
            boxShadow: 'none',
            borderColor: 'error'
          }
        },
        stepperGroup: {
          width: '0 !important'
        }
      }
    }
  },
  Textarea: {
    sizes: {
      xs: {
        padding: '4px'
      }
    },
    variants: {
      outline: {
        bg: 'white',
        borderRadius: '4px',
        border: '1px solid',
        borderColor: 'border70',
        color: 'bodyText',
        _placeholder: {
          color: 'lightGray'
        },
        _hover: {
          bg: 'white'
        },
        _focus: {
          border: '1px solid',
          borderColor: 'border70',
          boxShadow: 'none'
        },
        _disabled: {
          color: 'bodyText',
          bg: 'disabledGray',
          borderColor: 'border50',
          opacity: 1,
          pointerEvents: 'none'
        },
        _invalid: {
          boxShadow: 'none',
          borderColor: 'error'
        }
      }
    }
  },
  Tabs: {
    variants: {
      'soft-rounded': {
        tablist: {
          gap: '4px',
          flexWrap: 'wrap'
        },
        tab: {
          p: '4px 6px',
          borderRadius: '4px',
          color: 'bodyText',
          bg: 'buttonBg',
          fontSize: '12px',
          fontWeight: 400,
          _selected: {
            bg: 'accent',
            color: 'white'
          },
          _disabled: {
            opacity: 1,
            color: 'lightGray'
          }
        },
        tabpanel: {
          p: 0
        }
      },
      'solid-rounded': {
        tablist: {
          p: '1px 1px 2px 1px',
          boxShadow: 'shadow_2',
          borderRadius: '4px',
          bg: 'bg',
          gap: '2px'
        },
        tab: {
          p: '4px 16px',
          borderRadius: '4px',
          color: 'bodyText',
          bg: 'bg',
          fontSize: '14px',
          fontWeight: 500,
          minW: '100px',
          _selected: {
            bg: 'accent',
            color: 'white'
          }
        },
        tabpanel: {
          p: 0
        }
      }
    }
  },
  Menu: {
    variants: {
      outline: {
        list: {
          p: '2px',
          border: 'none',
          bg: 'buttonBg'
        },
        item: {
          display: 'block',
          p: '3px 12px',
          h: '24px',
          bg: 'buttonBg',
          color: 'bodyText',
          fontSize: '12px',
          borderRadius: '4px',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          width: '100%',
          _hover: {
            bg: 'accent',
            color: 'white'
          },
          _disabled: {
            pointerEvents: 'none'
          }
        },
        divider: {
          m: '2px 0',
          borderColor: 'white'
        }
      },
      multi: {
        list: {
          p: '2px',
          border: 'none',
          bg: 'buttonBg',
          minW: 0,
          width: 'auto',
          borderRadius: '4px'
        },
        item: {
          display: 'block',
          p: '0',
          bg: 'buttonBg',
          color: 'bodyText',
          fontSize: '12px',
          whiteSpace: 'nowrap',
          _disabled: {
            pointerEvents: 'none'
          }
        },
        divider: {
          m: '2px 0',
          borderColor: 'white'
        }
      }
    }
  },
  Stepper: {
    baseStyle: {
      indicator: {},
      separator: {}
    },
    sizes: {
      sm: {
        indicator: {
          width: '12px',
          height: '12px',
          border: '2px solid',
          borderColor: 'accent !important',
          '&[data-status=complete]': {
            background: 'white'
          },
          '&[data-status=active]': {
            background: 'accent'
          },
          '$[data-status=incomplete]': {
            background: 'white'
          },
          _hover: {
            cursor: 'pointer',
            background: 'accent'
          }
        },
        separator: {
          background: 'accent !important',
          '&[data-orientation=vertical]': {
            maxH: 'calc(100% - 12px)',
            top: '12px',
            left: '5px'
          }
        }
      },
      m: {
        step: {
          gap: 0
        },
        title: {
          position: 'absolute',
          textWrap: 'nowrap',
          left: '6px',
          top: '26px'
        },
        indicator: {
          zIndex: 1,
          width: '18px',
          height: '18px',
          borderWidth: '1px !important',
          borderColor: 'border !important',
          '&[data-status=complete]': {
            background: 'white'
          },
          '&[data-status=active]': {
            background: 'white'
          },
          '$[data-status=incomplete]': {},
          _hover: {
            cursor: 'pointer'
          }
        },
        separator: {
          borderTop: '1px solid',
          borderBottom: '1px solid',
          borderColor: 'border',
          '&[data-orientation=horizontal]': {
            marginInlineStart: '-3px',
            marginInlineEnd: '-3px',
            height: '10px',
            top: '12px',
            left: '5px'
          },
          '&[data-status=complete]': {
            background: 'accent',
            borderTop: 'none',
            borderBottom: 'none'
          }
        }
      }
    }
  }
};

const shadows = {
  shadow_0: '0px 16px 32px rgba(0,0,0, 0.12)',
  shadow_1: '0px 0px 12px rgba(0,0,0, 0.12)',
  shadow_2: '0px -1px 0px rgba(215,218,224, 1) inset, 0px 0px 4px rgba(0,0,0, 0.2)',
  shadow_3: '0px 0px 4px rgba(0,0,0, 0.2)',
  shadow_4: '1px 0px 4px rgba(215,218,224, 1)',
  shadow_5: '0px 1px 0px rgba(215,218,224, 1)',
  shadow_6: '0px 4px 12px rgba(0,0,0, 0.24)',
  shadow_7: '0px 2px 0px rgba(40,53,79, 1)',
  shadow_8: '0px 1px 0px rgba(106,37,37, 1)'
};

export const theme = extendTheme({
  colors,
  config,
  styles,
  textStyles,
  breakpoints,
  modalStyles,
  components,
  shadows,
  fonts
});
