/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CheckpointOut } from './CheckpointOut';
import {
    CheckpointOutFromJSON,
    CheckpointOutFromJSONTyped,
    CheckpointOutToJSON,
} from './CheckpointOut';

/**
 * 
 * @export
 * @interface CheckpointsInfoOut
 */
export interface CheckpointsInfoOut {
    /**
     * 
     * @type {Array<CheckpointOut>}
     * @memberof CheckpointsInfoOut
     */
    checkpoints: Array<CheckpointOut>;
    /**
     * 
     * @type {number}
     * @memberof CheckpointsInfoOut
     */
    limit: number;
}

/**
 * Check if a given object implements the CheckpointsInfoOut interface.
 */
export function instanceOfCheckpointsInfoOut(value: object): value is CheckpointsInfoOut {
    if (!('checkpoints' in value) || value['checkpoints'] === undefined) return false;
    if (!('limit' in value) || value['limit'] === undefined) return false;
    return true;
}

export function CheckpointsInfoOutFromJSON(json: any): CheckpointsInfoOut {
    return CheckpointsInfoOutFromJSONTyped(json, false);
}

export function CheckpointsInfoOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckpointsInfoOut {
    if (json == null) {
        return json;
    }
    return {
        
        'checkpoints': ((json['checkpoints'] as Array<any>).map(CheckpointOutFromJSON)),
        'limit': json['limit'],
    };
}

export function CheckpointsInfoOutToJSON(value?: CheckpointsInfoOut | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'checkpoints': ((value['checkpoints'] as Array<any>).map(CheckpointOutToJSON)),
        'limit': value['limit'],
    };
}

