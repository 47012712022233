/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CheckpointOut } from './CheckpointOut';
import {
    CheckpointOutFromJSON,
    CheckpointOutFromJSONTyped,
    CheckpointOutToJSON,
} from './CheckpointOut';

/**
 * 
 * @export
 * @interface ResponseStatusCheckpointOut
 */
export interface ResponseStatusCheckpointOut {
    /**
     * 
     * @type {string}
     * @memberof ResponseStatusCheckpointOut
     */
    status: string;
    /**
     * 
     * @type {CheckpointOut}
     * @memberof ResponseStatusCheckpointOut
     */
    data?: CheckpointOut | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseStatusCheckpointOut
     */
    errorMsg?: string | null;
}

/**
 * Check if a given object implements the ResponseStatusCheckpointOut interface.
 */
export function instanceOfResponseStatusCheckpointOut(value: object): value is ResponseStatusCheckpointOut {
    if (!('status' in value) || value['status'] === undefined) return false;
    return true;
}

export function ResponseStatusCheckpointOutFromJSON(json: any): ResponseStatusCheckpointOut {
    return ResponseStatusCheckpointOutFromJSONTyped(json, false);
}

export function ResponseStatusCheckpointOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseStatusCheckpointOut {
    if (json == null) {
        return json;
    }
    return {
        
        'status': json['status'],
        'data': json['data'] == null ? undefined : CheckpointOutFromJSON(json['data']),
        'errorMsg': json['error_msg'] == null ? undefined : json['error_msg'],
    };
}

export function ResponseStatusCheckpointOutToJSON(value?: ResponseStatusCheckpointOut | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status': value['status'],
        'data': CheckpointOutToJSON(value['data']),
        'error_msg': value['errorMsg'],
    };
}

