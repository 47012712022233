import {EElementResultType} from 'core/enums';
import {TResultDetailsSnapshotIn} from 'core/models';
/* SEPARATOR RESULTS */
import * as V_100 from 'core/mocks/results/Separator/2_phase_separator/V_100_basic';
import * as K_100 from 'core/mocks/results/Separator/compressor/K_100_basic';
import * as E_100 from 'core/mocks/results/Separator/cooler/E_100_basic';
import * as VLV_100 from 'core/mocks/results/Separator/valve/VLV_100_basic';
import * as V_101 from 'core/mocks/results/Separator/3_phase_separator/V_101_basic';
/* COKER RESULTS */
import * as COKER_OUTPUT from 'core/mocks/results/Coker/coker_output/COKER_100_output';
import * as COKER_BALANCE from 'core/mocks/results/Coker/coker_balance/COKER_100_balance';

export const RESULTS_LIST: {
  projectId: string;
  projectName: string;
  items: TResultDetailsSnapshotIn[];
}[] = [
  {
    projectId: '47CB2F0C-0000-0000-0000-000000000001',
    projectName: 'Схема с сепараторами',
    items: [
      {
        id: 'C36FFC58-0000-0000-0000-000000000001',
        element_id: '196C89E0-0000-0000-0000-000000000001',
        type: EElementResultType.Separator_2ph_Threads,
        name: 'V-100',
        icon: 'Table',
        jsonSchemas: [
          {
            id: '0425B81E-0000-0000-0000-000000000001',
            name: 'Базовые',
            uiSchema: V_100.UI_SCHEMA,
            schema: V_100.SCHEMA,
            formData: V_100.FORM_DATA
          }
        ]
      },
      {
        id: 'C36FFC58-0000-0000-0000-000000000002',
        element_id: '196C89E0-0000-0000-0000-000000000002',
        type: EElementResultType.Compressor_Threads,
        name: 'K-100',
        icon: 'Table',
        jsonSchemas: [
          {
            id: '0425B81E-0000-0000-0000-000000000002',
            name: 'Базовые',
            uiSchema: K_100.UI_SCHEMA,
            schema: K_100.SCHEMA,
            formData: K_100.FORM_DATA
          }
        ]
      },
      {
        id: 'C36FFC58-0000-0000-0000-000000000003',
        element_id: '196C89E0-0000-0000-0000-000000000003',
        type: EElementResultType.Cooler_Threads,
        name: 'E-100',
        icon: 'Table',
        jsonSchemas: [
          {
            id: '0425B81E-0000-0000-0000-000000000003',
            name: 'Базовые',
            uiSchema: E_100.UI_SCHEMA,
            schema: E_100.SCHEMA,
            formData: E_100.FORM_DATA
          }
        ]
      },
      {
        id: 'C36FFC58-0000-0000-0000-000000000004',
        element_id: '196C89E0-0000-0000-0000-000000000004',
        type: EElementResultType.Valve_Threads,
        name: 'VLV-100',
        icon: 'Table',
        jsonSchemas: [
          {
            id: '0425B81E-0000-0000-0000-000000000004',
            name: 'Базовые',
            uiSchema: VLV_100.UI_SCHEMA,
            schema: VLV_100.SCHEMA,
            formData: VLV_100.FORM_DATA
          }
        ]
      },
      {
        id: 'C36FFC58-0000-0000-0000-000000000005',
        element_id: '196C89E0-0000-0000-0000-000000000005',
        type: EElementResultType.Separator_3ph_Threads,
        name: 'V-101',
        icon: 'Table',
        jsonSchemas: [
          {
            id: '0425B81E-0000-0000-0000-000000000005',
            name: 'Базовые',
            uiSchema: V_101.UI_SCHEMA,
            schema: V_101.SCHEMA,
            formData: V_101.FORM_DATA
          }
        ]
      }
    ]
  },
  {
    projectId: '47CB2F0C-0000-0000-0000-000000000002',
    projectName: 'Схема с УЗК',
    items: [
      {
        id: '601F64F3-0000-0000-0000-000000000001',
        element_id: '704F784D-0000-0000-0000-000000000001',
        type: EElementResultType.Coker_Output,
        name: 'COKER-100 [Выход]',
        icon: 'Table',
        jsonSchemas: [
          {
            id: 'C3155248-0000-0000-0000-000000000001',
            name: 'Базовые',
            uiSchema: COKER_OUTPUT.UI_SCHEMA,
            schema: COKER_OUTPUT.SCHEMA,
            formData: COKER_OUTPUT.FORM_DATA
          }
        ]
      },
      {
        id: '601F64F3-0000-0000-0000-000000000002',
        element_id: '704F784D-0000-0000-0000-000000000001',
        type: EElementResultType.Coker_M_Balance,
        name: 'COKER-100 [Баланс]',
        icon: 'Coker',
        jsonSchemas: [
          {
            id: 'C3155248-0000-0000-0000-000000000002',
            name: 'Базовые',
            uiSchema: COKER_BALANCE.UI_SCHEMA,
            schema: COKER_BALANCE.SCHEMA,
            formData: COKER_BALANCE.FORM_DATA
          }
        ]
      }
    ]
  }
];
