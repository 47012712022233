/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
/**
 *
 * @export
 * @interface DeleteModelPossibility
 */
export interface DeleteModelPossibility {
  /**
   *
   * @type {boolean}
   * @memberof DeleteModelPossibility
   */
  isDeleteable: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof DeleteModelPossibility
   */
  elementNames: Array<string>;
}

/**
 * Check if a given object implements the DeleteModelPossibility interface.
 */
export function instanceOfDeleteModelPossibility(value: object): value is DeleteModelPossibility {
  if (!('isDeleteable' in value) || value['isDeleteable'] === undefined) return false;
  if (!('elementNames' in value) || value['elementNames'] === undefined) return false;
  return true;
}

export function DeleteModelPossibilityFromJSON(json: any): DeleteModelPossibility {
  return DeleteModelPossibilityFromJSONTyped(json, false);
}

export function DeleteModelPossibilityFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DeleteModelPossibility {
  if (json == null) {
    return json;
  }
  return {
    isDeleteable: json['is_deleteable'],
    elementNames: json['element_names']
  };
}

export function DeleteModelPossibilityToJSON(value?: DeleteModelPossibility | null): any {
  if (value == null) {
    return value;
  }
  return {
    is_deleteable: value['isDeleteable'],
    element_names: value['elementNames']
  };
}
