import {FC, useState} from 'react';
import {Grid} from '@chakra-ui/react';
import {SystemStyleObject} from '@chakra-ui/styled-system';
import {ELumpChemicalComponentClass, ILumpComponent} from '@progress-fe/core';

import {VirtualTable} from '../../../../tables';
import {Input, Select} from '../../../../inputs';
import {ISelectOption, ISimpleTableColumn, ISimpleTableRow} from '../../../../../interfaces';

interface IProps {
  allComponents: ILumpComponent[];
  selectedComponents: ILumpComponent[];
  onAddComponent: (value: string) => void;
  onDeleteComponent: (value: string) => void;
  onAddAllComponents?: () => void;
  onDeleteAllComponents?: () => void;
  tableSx?: SystemStyleObject;
}

const LumpComponentsFC: FC<IProps> = ({
  allComponents,
  selectedComponents,
  onAddComponent,
  onDeleteComponent,
  onAddAllComponents,
  onDeleteAllComponents,
  tableSx
}) => {
  const [query, setQuery] = useState('');
  const [selectedClassType, setSelectedClassType] = useState(ELumpChemicalComponentClass.ALL);

  const columns: ISimpleTableColumn[] = [{name: 'Название'}];

  const lumpChemicalClassTypeOptions: ISelectOption<ELumpChemicalComponentClass>[] = [
    {value: ELumpChemicalComponentClass.ALL, label: 'Все классы'},
    {value: ELumpChemicalComponentClass.RAW_UZK, label: 'Сырьё'},
    {value: ELumpChemicalComponentClass.PRODUCT_UZK, label: 'Продукт'}
  ];

  const filteredOptionsByName = allComponents.filter((opt) =>
    opt.name.toLowerCase().includes(query.toLowerCase())
  );

  const filteredOptionsByNameAndClass = filteredOptionsByName.filter(
    (opt) =>
      selectedClassType === ELumpChemicalComponentClass.ALL || selectedClassType === opt.classType
  );

  const rows: ISimpleTableRow<string>[] = filteredOptionsByNameAndClass.map((opt) => ({
    key: opt.uuid,
    values: [opt.name],
    isSelected: selectedComponents.map((c) => c.uuid).includes(opt.uuid),
    isDisabled: false
  }));

  return (
    <Grid templateRows="auto 1fr" gap="8px">
      <Grid gridTemplateColumns="1fr 140px" gap="8px">
        <Input size="xs" variant="outline" value={query} placeholder="Поиск" onChange={setQuery} />
        <Select
          name="chemical_class"
          options={lumpChemicalClassTypeOptions}
          value={lumpChemicalClassTypeOptions.find((opt) => opt.value === selectedClassType)}
          onChange={(option) =>
            setSelectedClassType((option as ISelectOption<ELumpChemicalComponentClass>).value)
          }
        />
      </Grid>

      <VirtualTable
        rows={rows}
        isCheckboxShown
        columns={columns}
        noItemsTitle="Компоненты не найдены"
        tableSx={tableSx}
        onSelectAll={onAddAllComponents}
        onUnselectAll={onDeleteAllComponents}
        onSelect={(value: string, isSelected: boolean) => {
          if (isSelected) {
            onAddComponent(value);
          } else {
            onDeleteComponent(value);
          }
        }}
      />
    </Grid>
  );
};

export const LumpComponents = LumpComponentsFC;
