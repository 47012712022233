import {RJSFSchema, UiSchema} from '@rjsf/utils';

export const UI_SCHEMA_COKER_PRODUCTS: UiSchema = {
  products: {
    units: {
      'ui:field': '/schemas/jsf-tabs'
    },
    gas_mf: {
      'ui:field': '/schemas/jsf-input'
    },
    c3_c4_mf: {
      'ui:field': '/schemas/jsf-input'
    },
    naphtha_mf: {
      'ui:field': '/schemas/jsf-input'
    },
    light_gasoil_mf: {
      'ui:field': '/schemas/jsf-input'
    },
    heavy_gasoil_mf: {
      'ui:field': '/schemas/jsf-input'
    },
    coke_mf: {
      'ui:field': '/schemas/jsf-input'
    },
    gas_mass_flow_rate: {
      'ui:field': '/schemas/jsf-input'
    },
    c3_c4_mass_flow_rate: {
      'ui:field': '/schemas/jsf-input'
    },
    naphtha_mass_flow_rate: {
      'ui:field': '/schemas/jsf-input'
    },
    light_gasoil_mass_flow_rate: {
      'ui:field': '/schemas/jsf-input'
    },
    heavy_gasoil_mass_flow_rate: {
      'ui:field': '/schemas/jsf-input'
    },
    coke_mass_flow_rate: {
      'ui:field': '/schemas/jsf-input'
    }
  }
};

export const SCHEMA_COKER_PRODUCTS: RJSFSchema = {
  type: 'object',
  properties: {
    products: {
      title: 'Характеристики',
      type: 'object',
      required: ['units'],
      properties: {
        units: {
          title: 'Единицы измерения',
          type: 'string',
          oneOf: [
            {const: 'UNIT_1', title: '% масс.'},
            {const: 'UNIT_2', title: 'т/ч'}
          ]
        }
      },
      allOf: [
        {
          if: {
            properties: {
              units: {
                const: 'UNIT_1'
              }
            }
          },
          then: {
            properties: {
              gas_mf: {
                title: 'Топливный газ',
                type: ['number', 'null'],
                minimum: 0,
                maximum: 15,
                readOnly: true,
                $comment: '% масс.'
              },
              c3_c4_mf: {
                title: 'С3-С4',
                type: ['number', 'null'],
                minimum: 0,
                maximum: 15,
                readOnly: true,
                $comment: '% масс.'
              },
              naphtha_mf: {
                title: 'С5-180',
                type: ['number', 'null'],
                minimum: 0,
                maximum: 15,
                readOnly: true,
                $comment: '% масс.'
              },
              light_gasoil_mf: {
                title: '180-365',
                type: ['number', 'null'],
                minimum: 0,
                maximum: 35,
                readOnly: true,
                $comment: '% масс.'
              },
              heavy_gasoil_mf: {
                title: '365+',
                type: ['number', 'null'],
                minimum: 0,
                maximum: 35,
                readOnly: true,
                $comment: '% масс.'
              },
              coke_mf: {
                title: 'Кокс нефтяной',
                type: ['number', 'null'],
                minimum: 0,
                maximum: 50,
                readOnly: true,
                $comment: '% масс.'
              }
            }
          }
        },
        {
          if: {
            properties: {
              units: {
                const: 'UNIT_2'
              }
            }
          },
          then: {
            properties: {
              gas_mass_flow_rate: {
                title: 'Топливный газ',
                type: ['number', 'null'],
                exclusiveMinimum: 0,
                readOnly: true,
                $comment: 'т/ч'
              },
              c3_c4_mass_flow_rate: {
                title: 'С3-С4',
                type: ['number', 'null'],
                exclusiveMinimum: 0,
                readOnly: true,
                $comment: 'т/ч'
              },
              naphtha_mass_flow_rate: {
                title: 'С5-180',
                type: ['number', 'null'],
                exclusiveMinimum: 0,
                readOnly: true,
                $comment: 'т/ч'
              },
              light_gasoil_mass_flow_rate: {
                title: '180-365',
                type: ['number', 'null'],
                exclusiveMinimum: 0,
                readOnly: true,
                $comment: 'т/ч'
              },
              heavy_gasoil_mass_flow_rate: {
                title: '365+',
                type: ['number', 'null'],
                exclusiveMinimum: 0,
                readOnly: true,
                $comment: 'т/ч'
              },
              coke_mass_flow_rate: {
                title: 'Кокс нефтяной',
                type: ['number', 'null'],
                exclusiveMinimum: 0,
                readOnly: true,
                $comment: 'т/ч'
              }
            }
          }
        }
      ]
    }
  }
};

export const FORM_DATA_COKER_PRODUCTS = {
  products: {
    units: 'UNIT_1',
    gas_mf: null,
    c3_c4_mf: null,
    naphtha_mf: null,
    light_gasoil_mf: null,
    heavy_gasoil_mf: null,
    coke_mf: null,
    gas_mass_flow_rate: null,
    c3_c4_mass_flow_rate: null,
    naphtha_mass_flow_rate: null,
    light_gasoil_mass_flow_rate: null,
    heavy_gasoil_mass_flow_rate: null,
    coke_mass_flow_rate: null
  }
};

export const REVERT_FORM_DATA = {
  products: {
    units: 'UNIT_1',
    gas_mf: null,
    c3_c4_mf: null,
    naphtha_mf: null,
    light_gasoil_mf: null,
    heavy_gasoil_mf: null,
    coke_mf: null,
    gas_mass_flow_rate: null,
    c3_c4_mass_flow_rate: null,
    naphtha_mass_flow_rate: null,
    light_gasoil_mass_flow_rate: null,
    heavy_gasoil_mass_flow_rate: null,
    coke_mass_flow_rate: null
  }
};

export const PREVENT_REVERT_FIELDS = ['root_products_units'];
