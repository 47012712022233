import {RJSFSchema, UiSchema} from '@rjsf/utils';

export const UI_SCHEMA_COOLER_CHARACTERISTICS: UiSchema = {
  characteristics: {
    chiller_key_value_type: {
      'ui:field': '/schemas/jsf-select'
    },
    chiller_key_value: {
      'ui:field': '/schemas/jsf-input'
    },
    chiller1_delta_T: {
      'ui:field': '/schemas/jsf-input'
    },
    chiller1_duty: {
      'ui:field': '/schemas/jsf-input'
    }
  }
};

export const SCHEMA_COOLER_CHARACTERISTICS: RJSFSchema = {
  type: 'object',
  properties: {
    characteristics: {
      title: 'Характеристики',
      type: 'object',
      required: ['chiller_key_value_type', 'chiller_key_value'],
      properties: {
        chiller_key_value_type: {
          title: 'Тип расчёта',
          type: 'string',
          oneOf: [
            {const: 'temperature_out', title: 'Целевая температура'},
            {const: 'COOLER_COMP_TYPE_2', title: 'Перепад температур', readOnly: true},
            {const: 'COOLER_COMP_TYPE_3', title: 'Тепловая нагрузка', readOnly: true}
          ]
        },
        chiller_key_value: {
          title: 'Целевое значение',
          type: ['number', 'null'],
          minimum: -273.15,
          $comment: '°C'
        },
        chiller1_delta_T: {
          title: 'Перепад температур',
          type: ['number', 'null'],
          exclusiveMinimum: 0,
          readOnly: true,
          $comment: '°C'
        },
        chiller1_duty: {
          title: 'Тепловая нагрузка',
          type: ['number', 'null'],
          exclusiveMinimum: 0,
          readOnly: true,
          $comment: 'кВт'
        }
      }
    }
  }
};

export const FORM_DATA_COOLER_CHARACTERISTICS = {
  characteristics: {
    chiller_key_value_type: 'temperature_out',
    chiller_key_value: null,
    chiller1_delta_T: null,
    chiller1_duty: null
  }
};

export const REVERT_FORM_DATA = {
  characteristics: {
    chiller1_delta_T: null,
    chiller1_duty: null
  }
};
