import {FC} from 'react';
import {observer} from 'mobx-react-lite';

import {SideMenu} from 'ui-kit';
import {TProjectsMenu} from 'core/types';
import {IMenuOption} from 'core/interfaces';

interface IProps {
  activeMenuItem: TProjectsMenu;
  updateActiveMenuItem: (item: TProjectsMenu) => void;
}

const MENU_ITEMS: IMenuOption<TProjectsMenu>[] = [
  {icon: 'All', label: 'Все', value: 'All'},
  {icon: 'Recent', label: 'Недавние', value: 'Recent'},
  {icon: 'Mix', label: 'Техпроцессы', value: 'TechProcess'},
  {icon: 'Production', label: 'Пробы и смеси', value: 'Mix'},
  {icon: 'Shared', label: 'Общие', value: 'Shared'},
  {icon: 'Favorite', label: 'Избранные', value: 'Favorites'},
  {icon: 'Bin', label: 'Корзина', value: 'Bin'}
];

const ProjectMenuFC: FC<IProps> = ({activeMenuItem, updateActiveMenuItem}) => {
  return (
    <SideMenu
      options={MENU_ITEMS}
      activeMenuItem={activeMenuItem}
      onSelect={updateActiveMenuItem}
    />
  );
};

export const ProjectMenu = observer(ProjectMenuFC);
