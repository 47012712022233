import {cast, flow, types} from 'mobx-state-tree';
import {v4 as uuidv4} from 'uuid';

import {TProjectsMenu} from 'core/types';
import {Paginator, RequestModel, ResetModel} from 'core/models';
import {
  ProjectsApi,
  ProjectTypeEnum,
  ResponseStatusProjectOut,
  PageProjectOut,
  ProjectOut
} from 'api';
import {PROJECT_LIST} from 'core/mocks/projects.mocks';
import {ProjectsGetProjectsOrderByEnum, ProjectsGetProjectsRequest} from 'api/generated';

import ProjectsFilter from './models/ProjectsFilter/ProjectsFilter';

const TypeFilters: Record<TProjectsMenu, ProjectsGetProjectsRequest> = {
  TechProcess: {type: ProjectTypeEnum.TechProcess},
  Mix: {type: ProjectTypeEnum.SamplesAndOilBlend},
  Bin: {deleted: true},
  Favorites: {favorites: true},
  Recent: {orderBy: ProjectsGetProjectsOrderByEnum.Desc, orderByField: 'updated_at'},
  Shared: {},
  All: {}
};

const MyProjectsStore = types
  .compose(
    ResetModel,
    types.model('MyProjectsStore', {
      projects: types.optional(types.array(types.frozen<ProjectOut>()), []),
      projectsRequest: types.optional(RequestModel, {}),
      actionRequest: types.optional(RequestModel, {}),
      filters: types.optional(ProjectsFilter, {}),
      paginator: types.optional(Paginator, {size: 50, page: 1, total: 0})
    })
  )
  .actions((self) => {
    const actions = {
      loadProjects: flow(function* () {
        //TODO: until favorites api will not be ready will set mock projects there
        if (self.filters.activeType === 'Favorites') {
          self.projects = cast(PROJECT_LIST);
          self.paginator.total = PROJECT_LIST.length;
          return;
        }

        const projects: PageProjectOut = yield self.projectsRequest.send(
          ProjectsApi.projectsGetProjects.bind(ProjectsApi),
          {
            ...TypeFilters[self.filters.activeType as TProjectsMenu],
            name: self.filters.query,
            page: self.paginator.page,
            size: self.paginator.size
          }
        );

        self.projects = cast(projects.items);
        self.paginator.total = projects.total || projects.items.length;
      }),

      createProject: flow(function* (type: ProjectTypeEnum) {
        const uuid = uuidv4();
        const response: ResponseStatusProjectOut = yield self.actionRequest.send(
          ProjectsApi.projectsCreateProject.bind(ProjectsApi),
          {
            idempotencyKey: uuid,
            projectInCreate: {
              name: `Новый проект (${uuid.split('-')[0]})`,
              type
            }
          }
        );

        if (response.data) {
          self.projects.push(response.data);
        }

        return response.data;
      }),

      setActiveType: (type: TProjectsMenu) => {
        self.filters.activeType = type;
        self.paginator.resetModel();
        actions.loadProjects();
      },
      setPage: (page: number) => {
        self.paginator.page = page;
        actions.loadProjects();
      },
      setQuery: (query: string) => {
        self.filters.query = query;
        self.paginator.resetModel();
      }
    };
    return actions;
  })
  .views((self) => ({
    get isLoading(): boolean {
      return self.projectsRequest.isPending;
    },
    get searchText(): string {
      return self.filters.query;
    },
    get activeMenu(): TProjectsMenu {
      return self.filters.activeType as TProjectsMenu;
    },
    get isNeedPagination(): boolean {
      return self.paginator.total > self.projects.length;
    }
  }));

export {MyProjectsStore};
