import {ROUTES} from 'core/constants';
import {IRoute} from 'core/interfaces';

import AuthScene from './AuthScene/AuthScene';
import MyProjectsScene from './MyProjectsScene/MyProjectsScene';
import SystemScene from './SystemScene/SystemScene';
import {SamplesAndOilScene} from './SamplesAndOilScene';
import {TechProcessScene} from './TechProcessScene';

export const MAIN_ROUTES: IRoute[] = [
  {
    path: ROUTES.Login,
    element: <AuthScene />
  },
  {
    path: ROUTES.MyProjects,
    element: <MyProjectsScene />
  },
  {
    path: ROUTES.TechProcess,
    element: <TechProcessScene />
  },
  {
    path: ROUTES.SamplesAndOil,
    element: <SamplesAndOilScene />
  },
  {
    path: ROUTES.System,
    element: <SystemScene />
  }
];
