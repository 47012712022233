import {cast, Instance, types} from 'mobx-state-tree';
import {Edge, Node} from '@xyflow/react';
import {TRFGraphDataConfig} from '@progress-fe/rf-core';

import {RESULTS_LIST} from 'core/mocks/results.mocks';
import {ResultDetails, ResetModel} from 'core/models';
import {GRAPH_LIST} from 'core/mocks/graph.mocks';

const ProjectResults = types
  .compose(
    ResetModel,
    types.model('ProjectResults', {
      results: types.optional(types.array(ResultDetails), []),
      nodes: types.optional(types.array(types.frozen<Node<TRFGraphDataConfig>>()), []),
      edges: types.optional(types.array(types.frozen<Edge>()), [])
    })
  )
  .actions((self) => ({
    fetch(projectId: string): void {
      const results = RESULTS_LIST.find((m) => m.projectId === projectId)?.items || [];
      self.results = cast(results);

      const drawingData = GRAPH_LIST.find((d) => d.projectId === projectId);
      if (!drawingData) return;

      self.nodes = cast(drawingData.nodes);
      self.edges = cast(drawingData.edges);
    },
    hasResult(resultId: string) {
      return self.results.some((r) => r.id === resultId);
    },
    clearAllCalculationData(): void {
      self.results.forEach((item) => {
        item.clearCalculationData();
      });
    }
  }))
  .views(() => ({}));

export type TProjectResultsModel = Instance<typeof ProjectResults>;

export {ProjectResults};
