import {FC, memo} from 'react';
import {Textarea as ChakraTextarea} from '@chakra-ui/react';

interface IProps {
  size?: 'xs';
  rows?: number;
  variant?: 'outline';
  value?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
}

const TextareaFC: FC<IProps> = ({
  size = 'xs',
  rows,
  variant = 'outline',
  value,
  disabled,
  onChange,
  onBlur
}) => {
  return (
    <ChakraTextarea
      size={size}
      rows={rows}
      variant={variant}
      value={value}
      disabled={disabled}
      onChange={(event) => onChange?.(event.target.value)}
      onBlur={(event) => onBlur?.(event.target.value)}
    />
  );
};

export const Textarea = memo(TextareaFC);
