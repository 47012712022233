import {FC, memo} from 'react';
import {useT} from '@progress-fe/core';

import {ISelectOption, TEditValueType} from '../../../../../interfaces';
import {InputNumber, Select} from '../../../../inputs';

interface IProps {
  value: string;
  isDisabled: boolean;
  type?: TEditValueType;
  options?: ISelectOption<string>[];
  onChangeValue: (value: string) => void;
}

const TableEditableValueFC: FC<IProps> = ({
  value,
  isDisabled,
  type = 'number',
  options = [],
  onChangeValue
}) => {
  const {t} = useT();

  switch (type) {
    case 'select':
      return (
        <Select
          wide
          inPortal
          name="model"
          options={options}
          isDisabled={isDisabled}
          placeholder={t('common.notSelected')}
          value={options.find((opt) => opt.value === value)}
          onChange={(selected) => {
            onChangeValue((selected as ISelectOption<string>).value);
          }}
        />
      );
    default:
      return (
        <InputNumber
          isFloat={type === 'float'}
          min={0}
          size="xs"
          variant="outline"
          sx={{width: '100%'}}
          isOnChangeOnlyOnBlur
          disabled={isDisabled}
          value={value}
          onChange={(newValue) => onChangeValue(newValue || '')}
        />
      );
  }
};

export const TableEditableValue = memo(TableEditableValueFC);
