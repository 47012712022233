import {memo} from 'react';
import {Grid, GridItem} from '@chakra-ui/react';

const CompositionHeaderFC = () => {
  return (
    <Grid
      gap="4px"
      p="2px 0 6px 0"
      alignItems="center"
      borderBottom="1px solid"
      borderColor="border"
      templateColumns="172px 1fr"
    >
      <GridItem p="0 0 0 2px">Пробы</GridItem>
      <GridItem p="0 0 0 2px">Расход</GridItem>
    </Grid>
  );
};

export const CompositionHeader = memo(CompositionHeaderFC);
