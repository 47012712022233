import {memo} from 'react';
import {cloneDeep} from 'lodash-es';
import {FieldProps} from '@rjsf/utils';
import {Box, Button, Flex, Grid} from '@chakra-ui/react';
import {EventEmitter, hasValue} from '@progress-fe/core';

import {JsFieldName} from '../../jsFormBase';
import {ISelectOption} from '../../../interfaces';

import {TJsPortOption, TJsConnectionFormData} from './ConnectionsJsField.types';
import {SelectNode} from './components';

interface IProps extends FieldProps {}

/**
 * Component renders "/schemas/jsf-connections" field of JsonSchema.
 * @param props : field props come from JsonSchema.
 * Value of props.formData is an instance of TNodeHandleFormData[].
 */
const ConnectionsJsFieldFC = (props: IProps) => {
  const formData = cloneDeep(props.formData) as Array<TJsConnectionFormData>;

  const handleConnect = (index: number, valueIndex: number, connector: TJsPortOption) => {
    const formDataCopy = cloneDeep(formData);
    formDataCopy[index].values[valueIndex] = {...connector};

    console.info('Connect from', formData[index].values[valueIndex]);
    console.info('Connect to', formDataCopy[index].values[valueIndex]);

    // TODO: Implementation on BE side
    // We must call "onConnect" callback instead of "props.onChange".
    // BE must change formData and send back formData.
    props.onChange(formDataCopy);
  };

  const handleReconnect = (index: number, valueIndex: number, connector: TJsPortOption) => {
    const formDataCopy = cloneDeep(formData);
    formDataCopy[index].values[valueIndex] = {...connector};

    console.info('Reconnect from', formData[index].values[valueIndex]);
    console.info('Reconnect to', formDataCopy[index].values[valueIndex]);

    // TODO: Implementation on BE side
    // We must call "onReconnect" callback instead of "props.onChange".
    // BE must change formData and send back formData.
    props.onChange(formDataCopy);
  };

  const handleChange = (index: number, valueIndex: number, option: ISelectOption<string>) => {
    const connector = formData[index].options.find((o) => o.portCode === option.value);

    if (!!connector && formData[index]?.values[valueIndex] === null) {
      handleConnect(index, valueIndex, connector);
    }

    if (!!connector && hasValue(formData[index]?.values[valueIndex])) {
      handleReconnect(index, valueIndex, connector);
    }
  };

  const handleEmptyConnection = (targetIndex: number) => {
    const formDataCopy = [...formData];
    formDataCopy[targetIndex].values.push(null);
    props.onChange(formDataCopy);
  };

  const handlePickEntity = (id: string) => {
    EventEmitter.emit('PickEntity', id);
  };

  const handleSelectEntity = (id: string) => {
    EventEmitter.emit('SelectEntity', id);
  };

  const mapSelectOptions = (options: Array<TJsPortOption>): ISelectOption<string>[] => {
    return options.map((opt) => ({
      value: opt.portCode,
      label: opt.elementName
    }));
  };

  return (
    <Box data-testid="ConnectionsJsField-test">
      <Flex flexDirection="column" gap="4px">
        {formData.length === 0 ? (
          <Box borderRadius="4px" border="1px solid" borderColor="border">
            <Flex h="64px" alignItems="center" justifyContent="center">
              Нет связей
            </Flex>
          </Box>
        ) : (
          <>
            {formData.map((item, index) => {
              const options = mapSelectOptions(item.options);
              return (
                <Grid key={item.name} gridTemplateColumns="minmax(0, 180px) 1fr" gap="4px">
                  <Box p="3px 0 0 0">
                    <JsFieldName name={item.name} />
                  </Box>
                  <Box>
                    {item.capacitance === 1 ? (
                      <SelectNode
                        name={item.name}
                        value={item.values[0]?.portCode || undefined}
                        options={options}
                        isDisabled={props.schema.readOnly}
                        onChange={(selected) => handleChange(index, 0, selected)}
                        onSelect={handleSelectEntity}
                        onPick={handlePickEntity}
                      />
                    ) : (
                      <Flex flexDirection="column" gap="4px">
                        {item.values.map((valueItem, valueIndex) => (
                          <SelectNode
                            key={`${index}${valueIndex}`}
                            name={`${item.name}${valueIndex}`}
                            value={valueItem?.portCode}
                            options={options}
                            isDisabled={props.schema.readOnly}
                            onChange={(selected) => handleChange(index, valueIndex, selected)}
                            onSelect={handleSelectEntity}
                            onPick={handlePickEntity}
                          />
                        ))}

                        {/* ADDING A NEW CONNECTION TO EXISTING ELEMENT */}
                        {!props.schema.readOnly && item.values.length < item.capacitance && (
                          <Button
                            size="smSquare"
                            width="100%"
                            variant="ghost"
                            onClick={() => handleEmptyConnection(index)}
                          >
                            Добавить
                          </Button>
                        )}
                      </Flex>
                    )}
                  </Box>
                </Grid>
              );
            })}
          </>
        )}
      </Flex>
    </Box>
  );
};

export const ConnectionsJsField = memo(ConnectionsJsFieldFC);
