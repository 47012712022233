import {RJSFSchema, UiSchema} from '@rjsf/utils';

export const UI_SCHEMA_COMPRESSOR_PERFORMANCE: UiSchema = {
  performance: {
    compressor1_head_poly: {
      'ui:field': '/schemas/jsf-input'
    },
    compressor1_head_adi: {
      'ui:field': '/schemas/jsf-input'
    },
    compressor1_efficiency_poly: {
      'ui:field': '/schemas/jsf-input'
    },
    compressor1_efficiency_adi: {
      'ui:field': '/schemas/jsf-input'
    },
    compressor1_adiabatic_exp: {
      'ui:field': '/schemas/jsf-input'
    },
    compressor1_polytropic_exp: {
      'ui:field': '/schemas/jsf-input'
    }
  }
};

export const SCHEMA_COMPRESSOR_PERFORMANCE: RJSFSchema = {
  type: 'object',
  properties: {
    performance: {
      title: 'Производительность',
      type: 'object',
      properties: {
        compressor1_head_poly: {
          title: 'Адиабатный напор флюида',
          type: ['number', 'null'],
          exclusiveMinimum: 0,
          readOnly: true,
          $comment: 'кДж/кг'
        },
        compressor1_head_adi: {
          title: 'Политропный напор флюида',
          type: ['number', 'null'],
          exclusiveMinimum: 0,
          readOnly: true,
          $comment: 'кДж/кг'
        },
        compressor1_efficiency_poly: {
          title: 'Политропный КПД',
          type: ['number', 'null'],
          minimum: 0,
          maximum: 100,
          readOnly: true
        },
        compressor1_efficiency_adi: {
          title: 'Адиабатный КПД',
          type: ['number', 'null'],
          minimum: 0,
          maximum: 100,
          readOnly: true
        },
        compressor1_adiabatic_exp: {
          title: 'Коэффициент адиабаты',
          type: ['number', 'null'],
          exclusiveMinimum: 0,
          readOnly: true
        },
        compressor1_polytropic_exp: {
          title: 'Коэффициент политропный',
          type: ['number', 'null'],
          exclusiveMinimum: 0,
          readOnly: true
        }
      }
    }
  }
};

export const FORM_DATA_COMPRESSOR_PERFORMANCE = {
  performance: {
    compressor1_head_poly: null,
    compressor1_head_adi: null,
    compressor1_efficiency_poly: null,
    compressor1_efficiency_adi: null,
    compressor1_adiabatic_exp: null,
    compressor1_polytropic_exp: null
  }
};

export const REVERT_FORM_DATA = {
  performance: {
    compressor1_head_poly: null,
    compressor1_head_adi: null,
    compressor1_efficiency_poly: null,
    compressor1_efficiency_adi: null,
    compressor1_adiabatic_exp: null,
    compressor1_polytropic_exp: null
  }
};
