import {Instance, SnapshotIn, types} from 'mobx-state-tree';

import {BaseColumnValue} from 'core/models/BaseColumnValue';

const BaseColumnResult = types
  .model('BaseColumnResult', {
    title: types.string,
    values: types.optional(types.array(BaseColumnValue), [])
  })
  .actions(() => ({}))
  .views(() => ({}));

export type TBaseColumnResultModel = Instance<typeof BaseColumnResult>;

export type TBaseColumnResultSnapshotIn = SnapshotIn<typeof BaseColumnResult>;

export {BaseColumnResult};
