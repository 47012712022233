import {Grid} from '@chakra-ui/react';
import {FieldProps} from '@rjsf/utils';

import {SelectTabs} from '../../inputs';
import {JsFieldName} from '../../jsFormBase';
import {ISchemaSelectOption, ISelectOption} from '../../../interfaces';

type TValue = number | string;

/**xw
 * Component renders "/schemas/jsf-tabs" field of JsonSchema.
 * @param props : field props come from JsonSchema.
 * Value of props.formData is string or number.
 */
const TabsJsFieldFC = (props: FieldProps) => {
  const options: ISelectOption<TValue>[] =
    props.schema?.oneOf?.map((opt) => {
      const schemaOpt = opt as ISchemaSelectOption;
      return {value: schemaOpt.const, label: schemaOpt.title, readonly: schemaOpt.readOnly};
    }) || [];

  const handleChange = (value: TValue) => {
    props.onChange(value);
  };

  return (
    <Grid gridTemplateColumns="minmax(0, 180px) 1fr" alignItems="center" gap="4px">
      <JsFieldName name={props.schema.title} />
      <SelectTabs
        options={options}
        value={props.formData}
        onChange={(newValue) => {
          handleChange(newValue as TValue);
        }}
      />
    </Grid>
  );
};

export const TabsJsField = TabsJsFieldFC;
