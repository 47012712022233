/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  DeleteModelPossibility,
  ElementsOut,
  HTTPValidationError,
  ModelsOut,
  NewElement,
  NewModel,
  RJSFSchemas,
  TechProcessActionResult,
  TechProcessApplyActionsRequestInner
} from '../models/index';
import {
  DeleteModelPossibilityFromJSON,
  DeleteModelPossibilityToJSON,
  ElementsOutFromJSON,
  ElementsOutToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  ModelsOutFromJSON,
  ModelsOutToJSON,
  NewElementFromJSON,
  NewElementToJSON,
  NewModelFromJSON,
  NewModelToJSON,
  RJSFSchemasFromJSON,
  RJSFSchemasToJSON,
  TechProcessActionResultFromJSON,
  TechProcessActionResultToJSON,
  TechProcessApplyActionsRequestInnerFromJSON,
  TechProcessApplyActionsRequestInnerToJSON
} from '../models/index';

export interface TechProcessApplyActionsRequest {
  projectUuid: string;
  checkpointUuid: string;
  idempotencyKey: string;
  techProcessApplyActionsRequestInner: Array<TechProcessApplyActionsRequestInner>;
}

export interface TechProcessCheckModelInstanceDeleteableRequest {
  projectUuid: string;
  modelInstanceUuid: string;
  checkpointUuid: string;
}

export interface TechProcessCreateElementRequest {
  projectUuid: string;
  checkpointUuid: string;
  idempotencyKey: string;
  newElement: NewElement;
}

export interface TechProcessCreateModelRequest {
  projectUuid: string;
  checkpointUuid: string;
  idempotencyKey: string;
  newModel: NewModel;
}

export interface TechProcessDeleteElementRequest {
  projectUuid: string;
  elementUuid: string;
  checkpointUuid: string;
}

export interface TechProcessDeleteModelInstanceRequest {
  projectUuid: string;
  modelInstanceUuid: string;
  checkpointUuid: string;
}

export interface TechProcessGetElementSchemasRequest {
  projectUuid: string;
  elementUuid: string;
  checkpointUuid: string;
}

export interface TechProcessGetElementsRequest {
  projectUuid: string;
  checkpointUuid: string;
}

export interface TechProcessGetModelSchemasRequest {
  projectUuid: string;
  modelInstanceUuid: string;
  checkpointUuid: string;
}

export interface TechProcessGetModelsRequest {
  projectUuid: string;
  checkpointUuid: string;
}

export interface TechProcessGetWorkZoneRequest {
  projectUuid: string;
  checkpointUuid: string;
}

export interface TechProcessSaveWorkZoneRequest {
  projectUuid: string;
  checkpointUuid: string;
  body: object;
}

/**
 *
 */
export class TechProcessApi extends runtime.BaseAPI {
  /**
   * Apply Actions
   */
  async techProcessApplyActionsRaw(
    requestParameters: TechProcessApplyActionsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<TechProcessActionResult>>> {
    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessApplyActions().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessApplyActions().'
      );
    }

    if (requestParameters['idempotencyKey'] == null) {
      throw new runtime.RequiredError(
        'idempotencyKey',
        'Required parameter "idempotencyKey" was null or undefined when calling techProcessApplyActions().'
      );
    }

    if (requestParameters['techProcessApplyActionsRequestInner'] == null) {
      throw new runtime.RequiredError(
        'techProcessApplyActionsRequestInner',
        'Required parameter "techProcessApplyActionsRequestInner" was null or undefined when calling techProcessApplyActions().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['idempotencyKey'] != null) {
      headerParameters['idempotency-key'] = String(requestParameters['idempotencyKey']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/actions`
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters['techProcessApplyActionsRequestInner']!.map(
          TechProcessApplyActionsRequestInnerToJSON
        )
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(TechProcessActionResultFromJSON)
    );
  }

  /**
   * Apply Actions
   */
  async techProcessApplyActions(
    requestParameters: TechProcessApplyActionsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<TechProcessActionResult>> {
    const response = await this.techProcessApplyActionsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Check Model Instance Deleteable
   */
  async techProcessCheckModelInstanceDeleteableRaw(
    requestParameters: TechProcessCheckModelInstanceDeleteableRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<DeleteModelPossibility>> {
    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessCheckModelInstanceDeleteable().'
      );
    }

    if (requestParameters['modelInstanceUuid'] == null) {
      throw new runtime.RequiredError(
        'modelInstanceUuid',
        'Required parameter "modelInstanceUuid" was null or undefined when calling techProcessCheckModelInstanceDeleteable().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessCheckModelInstanceDeleteable().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/models/{model_instance_uuid}/deleteable`
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          )
          .replace(
            `{${'model_instance_uuid'}}`,
            encodeURIComponent(String(requestParameters['modelInstanceUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      DeleteModelPossibilityFromJSON(jsonValue)
    );
  }

  /**
   * Check Model Instance Deleteable
   */
  async techProcessCheckModelInstanceDeleteable(
    requestParameters: TechProcessCheckModelInstanceDeleteableRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<DeleteModelPossibility> {
    const response = await this.techProcessCheckModelInstanceDeleteableRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Create Element
   */
  async techProcessCreateElementRaw(
    requestParameters: TechProcessCreateElementRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TechProcessActionResult>> {
    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessCreateElement().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessCreateElement().'
      );
    }

    if (requestParameters['idempotencyKey'] == null) {
      throw new runtime.RequiredError(
        'idempotencyKey',
        'Required parameter "idempotencyKey" was null or undefined when calling techProcessCreateElement().'
      );
    }

    if (requestParameters['newElement'] == null) {
      throw new runtime.RequiredError(
        'newElement',
        'Required parameter "newElement" was null or undefined when calling techProcessCreateElement().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['idempotencyKey'] != null) {
      headerParameters['idempotency-key'] = String(requestParameters['idempotencyKey']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/elements`
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: NewElementToJSON(requestParameters['newElement'])
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TechProcessActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Create Element
   */
  async techProcessCreateElement(
    requestParameters: TechProcessCreateElementRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TechProcessActionResult> {
    const response = await this.techProcessCreateElementRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Create Model
   */
  async techProcessCreateModelRaw(
    requestParameters: TechProcessCreateModelRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TechProcessActionResult>> {
    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessCreateModel().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessCreateModel().'
      );
    }

    if (requestParameters['idempotencyKey'] == null) {
      throw new runtime.RequiredError(
        'idempotencyKey',
        'Required parameter "idempotencyKey" was null or undefined when calling techProcessCreateModel().'
      );
    }

    if (requestParameters['newModel'] == null) {
      throw new runtime.RequiredError(
        'newModel',
        'Required parameter "newModel" was null or undefined when calling techProcessCreateModel().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['idempotencyKey'] != null) {
      headerParameters['idempotency-key'] = String(requestParameters['idempotencyKey']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/models`
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: NewModelToJSON(requestParameters['newModel'])
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TechProcessActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Create Model
   */
  async techProcessCreateModel(
    requestParameters: TechProcessCreateModelRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TechProcessActionResult> {
    const response = await this.techProcessCreateModelRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Delete Element
   */
  async techProcessDeleteElementRaw(
    requestParameters: TechProcessDeleteElementRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessDeleteElement().'
      );
    }

    if (requestParameters['elementUuid'] == null) {
      throw new runtime.RequiredError(
        'elementUuid',
        'Required parameter "elementUuid" was null or undefined when calling techProcessDeleteElement().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessDeleteElement().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/elements/{element_uuid}`
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          )
          .replace(
            `{${'element_uuid'}}`,
            encodeURIComponent(String(requestParameters['elementUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<any>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Delete Element
   */
  async techProcessDeleteElement(
    requestParameters: TechProcessDeleteElementRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<any> {
    const response = await this.techProcessDeleteElementRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Delete Model Instance
   */
  async techProcessDeleteModelInstanceRaw(
    requestParameters: TechProcessDeleteModelInstanceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessDeleteModelInstance().'
      );
    }

    if (requestParameters['modelInstanceUuid'] == null) {
      throw new runtime.RequiredError(
        'modelInstanceUuid',
        'Required parameter "modelInstanceUuid" was null or undefined when calling techProcessDeleteModelInstance().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessDeleteModelInstance().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/models/{model_instance_uuid}`
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          )
          .replace(
            `{${'model_instance_uuid'}}`,
            encodeURIComponent(String(requestParameters['modelInstanceUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<any>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Delete Model Instance
   */
  async techProcessDeleteModelInstance(
    requestParameters: TechProcessDeleteModelInstanceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<any> {
    const response = await this.techProcessDeleteModelInstanceRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get Element Schemas
   */
  async techProcessGetElementSchemasRaw(
    requestParameters: TechProcessGetElementSchemasRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<{[key: string]: RJSFSchemas}>> {
    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessGetElementSchemas().'
      );
    }

    if (requestParameters['elementUuid'] == null) {
      throw new runtime.RequiredError(
        'elementUuid',
        'Required parameter "elementUuid" was null or undefined when calling techProcessGetElementSchemas().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessGetElementSchemas().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/elements/{element_uuid}`
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          )
          .replace(
            `{${'element_uuid'}}`,
            encodeURIComponent(String(requestParameters['elementUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      runtime.mapValues(jsonValue, RJSFSchemasFromJSON)
    );
  }

  /**
   * Get Element Schemas
   */
  async techProcessGetElementSchemas(
    requestParameters: TechProcessGetElementSchemasRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<{[key: string]: RJSFSchemas}> {
    const response = await this.techProcessGetElementSchemasRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get Elements
   */
  async techProcessGetElementsRaw(
    requestParameters: TechProcessGetElementsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ElementsOut>> {
    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessGetElements().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessGetElements().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/elements`
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ElementsOutFromJSON(jsonValue));
  }

  /**
   * Get Elements
   */
  async techProcessGetElements(
    requestParameters: TechProcessGetElementsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ElementsOut> {
    const response = await this.techProcessGetElementsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get Model Schemas
   */
  async techProcessGetModelSchemasRaw(
    requestParameters: TechProcessGetModelSchemasRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<{[key: string]: RJSFSchemas}>> {
    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessGetModelSchemas().'
      );
    }

    if (requestParameters['modelInstanceUuid'] == null) {
      throw new runtime.RequiredError(
        'modelInstanceUuid',
        'Required parameter "modelInstanceUuid" was null or undefined when calling techProcessGetModelSchemas().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessGetModelSchemas().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/models/{model_instance_uuid}`
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          )
          .replace(
            `{${'model_instance_uuid'}}`,
            encodeURIComponent(String(requestParameters['modelInstanceUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      runtime.mapValues(jsonValue, RJSFSchemasFromJSON)
    );
  }

  /**
   * Get Model Schemas
   */
  async techProcessGetModelSchemas(
    requestParameters: TechProcessGetModelSchemasRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<{[key: string]: RJSFSchemas}> {
    const response = await this.techProcessGetModelSchemasRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get Models
   */
  async techProcessGetModelsRaw(
    requestParameters: TechProcessGetModelsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ModelsOut>> {
    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessGetModels().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessGetModels().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/models`
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ModelsOutFromJSON(jsonValue));
  }

  /**
   * Get Models
   */
  async techProcessGetModels(
    requestParameters: TechProcessGetModelsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ModelsOut> {
    const response = await this.techProcessGetModelsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get Work Zone
   */
  async techProcessGetWorkZoneRaw(
    requestParameters: TechProcessGetWorkZoneRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessGetWorkZone().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessGetWorkZone().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/work_zone`
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<any>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Get Work Zone
   */
  async techProcessGetWorkZone(
    requestParameters: TechProcessGetWorkZoneRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<any> {
    const response = await this.techProcessGetWorkZoneRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Save Work Zone
   */
  async techProcessSaveWorkZoneRaw(
    requestParameters: TechProcessSaveWorkZoneRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessSaveWorkZone().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessSaveWorkZone().'
      );
    }

    if (requestParameters['body'] == null) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter "body" was null or undefined when calling techProcessSaveWorkZone().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/work_zone`
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters['body'] as any
      },
      initOverrides
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<any>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Save Work Zone
   */
  async techProcessSaveWorkZone(
    requestParameters: TechProcessSaveWorkZoneRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<any> {
    const response = await this.techProcessSaveWorkZoneRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
