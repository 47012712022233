import {FC, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {Box} from '@chakra-ui/react';
import {ResultTable, ISimpleTableColumn, ISimpleTableRow} from '@progress-fe/ui-kit';

import {
  TCalculationResultItemModel,
  TCalculationTableResultModel
} from '../../../../../core/models';

interface IProps {
  resultsInstance: TCalculationResultItemModel;
}

const TableResultsFC: FC<IProps> = ({resultsInstance}) => {
  const initialData = resultsInstance.data as TCalculationTableResultModel;

  const columns: ISimpleTableColumn[] = useMemo(() => {
    return initialData.columns.map((c, index) => ({
      name: c.title,
      columnTemplate: index === 0 ? '2fr' : '1fr',
      align: index === 0 ? 'left' : 'right'
    }));
  }, [initialData.columns]);

  const rows: ISimpleTableRow<string>[] = useMemo(() => {
    const rowsCount = initialData.columns[0]?.values.length || 0;
    if (rowsCount === 0) return [];

    const rowsList: ISimpleTableRow<string>[] = [];
    for (let i = 0; i < rowsCount; i++) {
      rowsList.push({
        key: `${i}`,
        values: initialData.columns.map((c) => c.values[i]?.value?.toString() || '')
      });
    }

    return rowsList;
  }, [initialData.columns]);

  return (
    <Box padding="10px 0 0 0">
      <ResultTable columns={columns} rows={rows} />
    </Box>
  );
};

export const TableResults = observer(TableResultsFC);
