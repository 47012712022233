import {FC, memo} from 'react';
import {Center, CircularProgress} from '@chakra-ui/react';

interface IProps {
  fullScreen?: boolean;
}

const LoaderFC: FC<IProps> = ({fullScreen}) => {
  return fullScreen ? (
    <Center
      position="absolute"
      h="var(--height-layout)"
      bg="rgba(255, 255, 255, 0.5)"
      zIndex={2}
      bottom={0}
      right={0}
      left={0}
    >
      <CircularProgress isIndeterminate color="accent" />
    </Center>
  ) : (
    <Center w="100%" h="100%" alignItems="center" justifyContent="center">
      <CircularProgress isIndeterminate color="accent" />
    </Center>
  );
};

export const Loader = memo(LoaderFC);
