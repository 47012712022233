import {Text} from '@chakra-ui/react';
import {FC, memo} from 'react';

interface IProps {
  name?: string | null;
}

const JsFieldNameFC: FC<IProps> = ({name}) => {
  return (
    <Text
      fontSize="12px"
      color="bodyText"
      overflow="hidden"
      whiteSpace="nowrap"
      textOverflow="ellipsis"
    >
      {name}
    </Text>
  );
};

export const JsFieldName = memo(JsFieldNameFC);
