import {FC, useEffect, useMemo, useState} from 'react';
import {Box, Flex, GridItem, Text} from '@chakra-ui/react';
import {SystemStyleObject} from '@chakra-ui/styled-system';
import {Svg, SelectTabs, ISelectOption} from '@progress-fe/ui-kit';
import {EComponentBaseType, ILumpComponent, IPureComponent} from '@progress-fe/core';

import {
  HypotheticalComponents,
  LumpComponents,
  PseudoComponents,
  PureComponents
} from './components';
import {FavoriteComponents} from './components/FavoriteComponents';

interface IProps {
  title: string;
  isClose?: boolean;
  isFavorite?: boolean;
  defaultComponentType: EComponentBaseType;
  allPureComponents: IPureComponent[];
  allLumpComponents: ILumpComponent[];
  favoritePureComponents?: IPureComponent[];
  favoriteLumpComponents?: ILumpComponent[];
  selectedPureComponents: IPureComponent[];
  selectedLumpComponents: ILumpComponent[];
  onAddPureComponent: (value: string) => void;
  onAddLumpComponent: (value: string) => void;
  onDeletePureComponent: (value: string) => void;
  onDeleteLumpComponent: (value: string) => void;
  onAddAllPureComponents?: () => void;
  onAddAllLumpComponents?: () => void;
  onDeleteAllPureComponents?: () => void;
  onDeleteAllLumpComponents?: () => void;
  tableSx?: SystemStyleObject;
  onClose?: () => void;
}

interface ITabOption {
  value: EComponentBaseType | null;
  label: string;
  readonly?: boolean;
}

const ComponentsListsFC: FC<IProps> = ({
  title,
  isClose,
  isFavorite,
  defaultComponentType,
  allPureComponents,
  allLumpComponents,
  selectedPureComponents,
  selectedLumpComponents,
  favoritePureComponents = [],
  favoriteLumpComponents = [],
  onAddPureComponent,
  onAddLumpComponent,
  onDeletePureComponent,
  onDeleteLumpComponent,
  onAddAllPureComponents,
  onAddAllLumpComponents,
  onDeleteAllPureComponents,
  onDeleteAllLumpComponents,
  tableSx,
  onClose
}) => {
  const [selectedType, setSelectedType] = useState<EComponentBaseType | null>(defaultComponentType);

  useEffect(() => {
    if (isFavorite) {
      setSelectedType(null);
    }
  }, [isFavorite]);

  const defaultOptions: ISelectOption<EComponentBaseType>[] = useMemo(
    () => [
      {value: EComponentBaseType.PURE, label: 'Чистые'},
      {value: EComponentBaseType.HYPOTHETICAL, label: 'Гипотетич.'},
      {value: EComponentBaseType.PSEUDO, label: 'Псевдокомп.'},
      {value: EComponentBaseType.LUMP, label: 'Lump'}
    ],
    []
  );

  const options: Array<ITabOption> = useMemo(() => {
    const optList: Array<ITabOption> = [];
    if (isFavorite) optList.push({value: null, label: 'Избран.'});

    defaultOptions.forEach((option) => {
      optList.push({
        ...option,
        readonly: false
      });
    });

    return optList;
  }, [defaultOptions, isFavorite]);

  return (
    <Box>
      <Flex h="26px" justify="space-between">
        <Text fontSize="12px" fontWeight={600}>
          {title}
        </Text>
        {isClose && <Svg name="Cross" onClick={onClose} />}
      </Flex>

      <SelectTabs
        value={selectedType}
        options={options}
        onChange={(newValue) =>
          setSelectedType(!!newValue ? (newValue as EComponentBaseType) : null)
        }
      />

      <Box m="8px 0 0 0">
        {/* SELECTED CHEMICAL COMPONENT TYPE */}
        <GridItem>
          {selectedType === null && (
            <FavoriteComponents
              selectedPureComponents={selectedPureComponents}
              selectedLumpComponents={selectedLumpComponents}
              favoriteLumpComponents={favoriteLumpComponents}
              favoritePureComponents={favoritePureComponents}
              onAddPureComponent={onAddPureComponent}
              onAddLumpComponent={onAddLumpComponent}
              onDeletePureComponent={onDeletePureComponent}
              onDeleteLumpComponent={onDeleteLumpComponent}
              tableSx={tableSx}
            />
          )}

          {selectedType === EComponentBaseType.PURE && (
            <PureComponents
              allComponents={allPureComponents}
              selectedComponents={selectedPureComponents}
              onAddComponent={onAddPureComponent}
              onAddAllComponents={onAddAllPureComponents}
              onDeleteAllComponents={onDeleteAllPureComponents}
              onDeleteComponent={onDeletePureComponent}
              tableSx={tableSx}
            />
          )}

          {selectedType === EComponentBaseType.LUMP && (
            <LumpComponents
              allComponents={allLumpComponents}
              selectedComponents={selectedLumpComponents}
              onAddComponent={onAddLumpComponent}
              onDeleteComponent={onDeleteLumpComponent}
              onAddAllComponents={onAddAllLumpComponents}
              onDeleteAllComponents={onDeleteAllLumpComponents}
              tableSx={tableSx}
            />
          )}

          {selectedType === EComponentBaseType.HYPOTHETICAL && (
            <HypotheticalComponents tableSx={tableSx} />
          )}

          {selectedType === EComponentBaseType.PSEUDO && <PseudoComponents tableSx={tableSx} />}
        </GridItem>
      </Box>
    </Box>
  );
};

export const ComponentsLists = ComponentsListsFC;
