import {observer} from 'mobx-react-lite';
import {Box, Button, Center, Grid, GridItem, Text} from '@chakra-ui/react';
import {useCallback, useEffect} from 'react';
import {useNavigate} from 'react-router';
import {Controller, useForm} from 'react-hook-form';
import {Input, Svg} from '@progress-fe/ui-kit';

import {useStore} from 'core/hooks';
import {ROUTES} from 'core/constants';
import {ISignInForm} from 'core/interfaces';

export const AuthSceneFC = () => {
  const {authStore} = useStore();

  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    setError,
    formState: {errors}
  } = useForm<ISignInForm>({
    defaultValues: {
      email: '',
      password: ''
    }
  });

  const handleSignIn = useCallback(
    (data: ISignInForm) => {
      const isSuccess = authStore.signIn(data);
      if (!isSuccess) {
        setError('password', {message: 'Пароль невалидный'});
      }
    },
    [authStore, setError]
  );

  useEffect(() => {
    if (authStore.isUser) {
      navigate(ROUTES.MyProjects, {replace: true});
    }
  }, [authStore.isUser, navigate]);

  return (
    <Center width="100%" height="100vh" bg="darkWhite">
      <Box bg="white" width="400px" borderRadius="8px" p="28px 40px 48px 40px" boxShadow="shadow_0">
        <Center flexDirection="column">
          <Svg name="Favicon" size="s48" />
          <Text p="12px 0 0 0" fontSize="24px" fontWeight={700} color="black">
            Авторизация
          </Text>
          <Text fontSize="14px" fontWeight={400} color="black" opacity="0.6">
            Войдите в аккаунт, чтобы продолжить.
          </Text>

          <Grid p="30px 0 0 0" templateRows="auto auto" gap="24px" width="100%">
            <GridItem>
              <Text pb="8px" fontSize="14px" fontWeight={400} color="black" opacity="0.6">
                Эл. почта
              </Text>

              <Controller
                name="email"
                control={control}
                rules={{required: true}}
                render={({field: {onChange, value}}) => (
                  <Input
                    size="md"
                    variant="outline"
                    isInvalid={!!errors.email}
                    placeholder="Введите эл. почту"
                    value={value}
                    onChange={onChange}
                    leftElement={<Svg name={'Email'} />}
                  />
                )}
              />
            </GridItem>

            <GridItem>
              <Text pb="8px" fontSize="14px" fontWeight={400} color="black" opacity="0.6">
                Пароль
              </Text>

              <Controller
                name="password"
                control={control}
                rules={{required: true}}
                render={({field: {onChange, value}}) => (
                  <Input
                    size="md"
                    type="password"
                    variant="outline"
                    isInvalid={!!errors.password}
                    placeholder="Введите пароль"
                    value={value}
                    onChange={onChange}
                    leftElement={<Svg name={'Password'} />}
                  />
                )}
              />
            </GridItem>
          </Grid>
        </Center>

        <Center p="44px 0 0 0">
          <Button variant="solid" size="md" width="180px" onClick={handleSubmit(handleSignIn)}>
            Войти
          </Button>
        </Center>
      </Box>
    </Center>
  );
};

export const AuthScene = observer(AuthSceneFC);

export default AuthScene;
