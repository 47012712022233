import {RJSFSchema, UiSchema} from '@rjsf/utils';

export const UI_SCHEMA_COMPRESSOR_CHARACTERISTICS: UiSchema = {
  characteristics: {
    compressor_efficiency_value: {
      'ui:field': '/schemas/jsf-input'
    },
    compressor_efficiency_type: {
      'ui:field': '/schemas/jsf-select'
    },
    compressor_key_value_type: {
      'ui:field': '/schemas/jsf-select'
    },
    compressor_key_value: {
      'ui:field': '/schemas/jsf-input'
    },
    compressor1_delta_pressure: {
      'ui:field': '/schemas/jsf-input'
    },
    compressor1_compress_ratio: {
      'ui:field': '/schemas/jsf-input'
    },
    compressor1_duty: {
      'ui:field': '/schemas/jsf-input'
    }
  }
};

export const SCHEMA_COMPRESSOR_CHARACTERISTICS: RJSFSchema = {
  type: 'object',
  properties: {
    characteristics: {
      title: 'Характеристики',
      type: 'object',
      required: [
        'compressor_efficiency_value',
        'compressor_efficiency_type',
        'compressor_key_value_type',
        'compressor_key_value'
      ],
      properties: {
        compressor_efficiency_value: {
          title: 'КПД',
          type: 'number',
          minimum: 0,
          maximum: 100
        },
        compressor_efficiency_type: {
          title: 'Тип КПД',
          type: 'string',
          oneOf: [
            {const: 'adiabatic', title: 'Адиабатный'},
            {const: 'polytropic', title: 'Политропный', readOnly: true}
          ]
        },
        compressor_key_value_type: {
          title: 'Тип расчёта',
          type: 'string',
          oneOf: [
            {const: 'pressure_out', title: 'Выходное давление'},
            {const: 'REAL_VALUE_1', title: 'Перепад давления', readOnly: true},
            {const: 'REAL_VALUE_3', title: 'Мощность', readOnly: true}
          ]
        },
        compressor_key_value: {
          title: 'Целевое значение',
          type: ['number', 'null'],
          exclusiveMinimum: 0,
          $comment: 'кПа'
        },
        compressor1_delta_pressure: {
          title: 'Перепад давления',
          type: ['number', 'null'],
          exclusiveMinimum: 0,
          readOnly: true,
          $comment: 'кПа'
        },
        compressor1_compress_ratio: {
          title: 'Степень сжатия',
          type: ['number', 'null'],
          exclusiveMinimum: 1,
          readOnly: true
        },
        compressor1_duty: {
          title: 'Мощность',
          type: ['number', 'null'],
          exclusiveMinimum: 0,
          readOnly: true,
          $comment: 'кВт'
        }
      }
    }
  }
};

export const FORM_DATA_COMPRESSOR_CHARACTERISTICS = {
  characteristics: {
    compressor_efficiency_value: 75,
    compressor_efficiency_type: 'adiabatic',
    compressor_key_value_type: 'pressure_out',
    compressor_key_value: null,
    compressor1_delta_pressure: null,
    compressor1_compress_ratio: null,
    compressor1_duty: null
  }
};

export const REVERT_FORM_DATA = {
  characteristics: {
    compressor1_delta_pressure: null,
    compressor1_compress_ratio: null,
    compressor1_duty: null
  }
};
