import {FC, useMemo} from 'react';
import {Box} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';
import {JsFormName} from '@progress-fe/ui-kit';

import {IJsonSchemaTab, JsTabsFormWrapper} from 'ui-kit';
import {TJsonSchemaModel, TProjectEntityJsonSchemasModel} from 'core/models';

interface IProps {
  probeInstance: TProjectEntityJsonSchemasModel;
  onResetCalculatedFields?: () => void;
}

const ProbeFormsFC: FC<IProps> = ({probeInstance, onResetCalculatedFields}) => {
  const {jsonSchemas} = probeInstance;

  const tabs: IJsonSchemaTab[] = useMemo(() => {
    return jsonSchemas.map((jsonSchema, index) => ({
      index: index,
      name: jsonSchema.name,
      jsonSchema
    }));
  }, [jsonSchemas]);

  const handleChangeFormData = (
    newFormData: unknown,
    jsonSchema: TJsonSchemaModel,
    changedFieldPath?: string
  ) => {
    jsonSchema.updateFormData(newFormData);

    if (!!onResetCalculatedFields && jsonSchema.isResetAllowed(changedFieldPath)) {
      onResetCalculatedFields();
    }
  };

  return (
    <Box>
      <JsFormName name={probeInstance.name} />
      <JsTabsFormWrapper
        tabs={tabs}
        onChange={(formData, tabId: number, changedFieldPath?: string) => {
          handleChangeFormData(formData, tabs[tabId]?.jsonSchema, changedFieldPath);
        }}
      />
    </Box>
  );
};

export const ProbeForms = observer(ProbeFormsFC);
