import {Grid, GridItem, Text} from '@chakra-ui/react';
import {useT} from '@progress-fe/core';
import {FC, memo} from 'react';

interface IProps {}

const ReactionsTableHeaderFC: FC<IProps> = () => {
  const {t} = useT();

  return (
    <Grid
      gap="4px"
      templateColumns="50px 50px minmax(300px, 1fr) 150px 150px 150px 150px"
      alignItems="center"
      justifyItems="center"
      bg="accentLight"
      display="inline-grid"
      minW="100%"
      gridAutoRows="40px"
      p="10px 0"
      textStyle="bold"
    >
      <GridItem textAlign="center">
        <Text>{t('jsfields.ReactionParametersTable.type')}</Text>
      </GridItem>
      <GridItem textAlign="center">
        <Text>{t('jsfields.ReactionParametersTable.subType')}</Text>
      </GridItem>
      <GridItem textAlign="center">
        <Text>{t('jsfields.ReactionParametersTable.reaction')}</Text>
      </GridItem>
      <GridItem textAlign="center">
        <Text>{t('jsfields.ReactionParametersTable.energy')}</Text>
      </GridItem>
      <GridItem textAlign="center">
        <Text>{t('jsfields.ReactionParametersTable.factor')}</Text>
      </GridItem>
      <GridItem textAlign="center">
        <Text>{t('jsfields.ReactionParametersTable.temperature')}</Text>
      </GridItem>
      <GridItem textAlign="center">
        <Text>{t('jsfields.ReactionParametersTable.speedConstant')}</Text>
      </GridItem>
    </Grid>
  );
};

export const ReactionsTableHeader = memo(ReactionsTableHeaderFC);
