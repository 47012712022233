import {Box, HStack} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';
import {FC, useCallback, useEffect, useState} from 'react';
interface IProps {
  page: number;
  total: number;
  size: number;
  onPageChange: (page: number) => void;
}

const PaginatorFC: FC<IProps> = ({page, total, size, onPageChange}) => {
  const [pagesNum, setPagesNum] = useState(0);

  useEffect(() => {
    setPagesNum(Math.ceil(total / size));
  }, [total, size]);

  const handlePageSelect = useCallback(
    (val: number) => {
      onPageChange(val);
    },
    [onPageChange]
  );

  return (
    <HStack spacing="8px">
      {new Array(pagesNum).fill(null).map((_, idx) => (
        <Box
          key={idx}
          pointerEvents={page === idx + 1 ? 'none' : 'auto'}
          cursor="pointer"
          p="6px 12px"
          border="1px"
          borderColor={page === idx + 1 ? 'accent' : 'border'}
          borderRadius="4px"
          color={page === idx ? 'accent' : 'black'}
          onClick={() => handlePageSelect(idx + 1)}
        >
          {idx + 1}
        </Box>
      ))}
    </HStack>
  );
};

export const Paginator = observer(PaginatorFC);
