import {FC, PropsWithChildren} from 'react';
import {observer} from 'mobx-react-lite';
import {Button, Center, Box} from '@chakra-ui/react';
import {useT} from '@progress-fe/core';

import {useStore} from 'core/hooks';

interface IProps extends PropsWithChildren {}

const AppAuthFC: FC<IProps> = ({children}) => {
  const {authStore} = useStore();

  const {t} = useT();

  if (authStore.isFailed) {
    return (
      <Center bg="bg" h="100vh" flexDirection="column">
        <Box fontSize="14px">{t('common.hm')}</Box>
        <Box fontSize="14px">{t('auth.error')}</Box>
        <Box p="12px 0 0">
          <Button
            size="md"
            variant="solid"
            onClick={() => {
              document.location = '/';
            }}
          >
            {t('actions.tryAgain')}
          </Button>
        </Box>
      </Center>
    );
  }

  return <>{children}</>;
};

export const AppAuth = observer(AppAuthFC);
