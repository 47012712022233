import {Box, Grid, IconButton} from '@chakra-ui/react';
import {FieldProps} from '@rjsf/utils';
import {SingleValue} from 'chakra-react-select';
import {EventEmitter, doesSchemaHaveType} from '@progress-fe/core';

import {Svg} from '../../helpers';
import {Select} from '../../inputs';
import {JsFieldName} from '../../jsFormBase';
import {ISchemaSelectOption, ISelectOption} from '../../../interfaces';

interface IProps extends FieldProps {
  isPick?: boolean;
  isArrow?: boolean;
}

type TValue = number | string;

/**
 * Component renders "/schemas/jsf-select", "/schemas/jsf-select-arrow", "/schemas/jsf-select-pick-arrow" field of JsonSchema.
 * @param props : field props come from JsonSchema.
 * Value of props.formData is string or number.
 */
const SelectJsFieldFC = (props: IProps) => {
  const options: ISelectOption<TValue>[] =
    props.schema?.oneOf
      ?.map((opt) => {
        const schemaOpt = opt as ISchemaSelectOption;
        return {value: schemaOpt.const, label: schemaOpt.title, readonly: schemaOpt.readOnly};
      })
      .filter((opt) => opt.value !== null) || [];

  const handleChange = (newValue: SingleValue<ISelectOption<TValue>>) => {
    props.onChange(
      doesSchemaHaveType(props.schema, 'number')
        ? Number(newValue?.value)
        : newValue?.value.toString()
    );
  };

  const btn1 = props.isPick || props.isArrow ? '24px' : '';
  const btn2 = props.isPick && props.isArrow ? '24px' : '';

  return (
    <Grid
      gridTemplateColumns={`minmax(0, 180px) 1fr ${btn1} ${btn2}`}
      alignItems="center"
      gap="8px"
    >
      <JsFieldName name={props.schema.title} />

      <Select
        name={props.name}
        options={options}
        placeholder="Не выбрано"
        isDisabled={props.readonly}
        value={options.find((opt) => opt.value === props.formData) || undefined}
        onChange={(newValue) => {
          handleChange(newValue as ISelectOption<TValue>);
        }}
      />

      {props.isPick && (
        <IconButton
          size="smSquare"
          aria-label=""
          variant="ghost"
          isDisabled={!props.formData}
          icon={<Svg size="s12" name="Pick" />}
          onClick={() => {
            if (typeof props.formData === 'string') {
              EventEmitter.emit('PickEntity', props.formData.toString());
            }
          }}
        />
      )}
      {props.isArrow && (
        <IconButton
          size="smSquare"
          aria-label=""
          variant="ghost"
          isDisabled={!props.formData}
          icon={
            <Box transform="rotate(180deg)">
              <Svg size="s12" name="Back" />
            </Box>
          }
          onClick={() => {
            if (typeof props.formData === 'string') {
              EventEmitter.emit('SelectEntity', props.formData.toString());
            }
          }}
        />
      )}
    </Grid>
  );
};

export const SelectJsField = SelectJsFieldFC;
