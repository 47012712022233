import {FC, memo, useEffect, useState} from 'react';
import {Grid, Box, Flex, Button, IconButton} from '@chakra-ui/react';
import {useT} from '@progress-fe/core';

import {ISelectOption} from '../../../../../interfaces';
import {Input, InputNumber, Select} from '../../../../inputs';
import {JsFieldName} from '../../../../jsFormBase';
import {TCompressorCurveConfig, TCompressorCurve} from '../../CompressorCurvesFormData.types';
import {Svg} from '../../../../helpers';
import {ICurveErrors} from '../../CurveErrors.interface';

import {CompressorCurveTable} from './components';

const getOptionLabel = (
  options: ISelectOption<string | number>[],
  value?: string | number
): string => {
  if (typeof value === undefined) {
    return '';
  }

  return options.find((item) => item.value === value)?.label ?? '';
};

interface IProps {
  isReadonly: boolean;
  fieldConfig: TCompressorCurveConfig;
  curve: TCompressorCurve;
  errors?: ICurveErrors;
  onChange: (curve: TCompressorCurve) => void;
  onDelete: () => void;
}

const CompressorCurveFC: FC<IProps> = ({
  isReadonly,
  fieldConfig,
  curve,
  errors,
  onChange,
  onDelete
}) => {
  const {t} = useT();

  const [flowTitle, setFlowTitle] = useState('');
  const [pressureTitle, setPressureTitle] = useState('');

  useEffect(() => {
    const title =
      typeof curve.flowType === undefined
        ? ''
        : getOptionLabel(fieldConfig.flowTypeOptions, curve.flowType);
    setFlowTitle(title);
  }, [curve.flowType, fieldConfig.flowTypeOptions]);

  useEffect(() => {
    const title =
      typeof curve.pressureType === undefined
        ? ''
        : getOptionLabel(fieldConfig.pressureTypeOptions, curve.pressureType);
    setPressureTitle(title);
  }, [curve.pressureType, fieldConfig.pressureTypeOptions]);

  const handleChangeCurve = (value: Partial<TCompressorCurve>) => {
    onChange({
      ...curve,
      ...value
    });
  };

  const handleDeleteValue = (index: number) => {
    const newValues = [...curve.values];
    newValues.splice(index, 1);
    handleChangeCurve({values: newValues});
  };

  const handleAddItem = () => {
    const newValues = [...curve.values];
    newValues.push({
      efficiency: '',
      flow: '',
      pressure: ''
    });

    handleChangeCurve({values: newValues});
  };

  return (
    <Flex flexDirection="column" gap="8px">
      <Grid gap="4px" p="2px 0" templateColumns="1fr 2fr">
        <JsFieldName name={t('jsfields.CompressorCurve.name')} />
        <Flex gap="4px">
          <Input
            size="xs"
            variant="outline"
            isOnChangeOnlyOnBlur
            value={curve.name}
            disabled={isReadonly}
            isInvalid={errors?.isNameInvalid}
            onChange={(value) => handleChangeCurve({name: value})}
          />
          <IconButton
            size="smSquare"
            aria-label=""
            variant="ghost"
            flexShrink="0"
            isDisabled={isReadonly}
            icon={<Svg size="s12" name="Cross" />}
            onClick={onDelete}
          />
        </Flex>
        <JsFieldName name={t('jsfields.CompressorCurve.flowType')} />
        <Select
          name="model"
          options={fieldConfig.flowTypeOptions}
          isDisabled={isReadonly}
          placeholder={t('common.notSelected')}
          value={fieldConfig.flowTypeOptions.find((opt) => opt.value === curve.flowType)}
          onChange={(selected) => {
            handleChangeCurve({flowType: (selected as ISelectOption<string | number>).value});
          }}
        />
        <JsFieldName name={t('jsfields.CompressorCurve.pressureType')} />
        <Select
          name="model"
          options={fieldConfig.pressureTypeOptions}
          isDisabled={isReadonly}
          placeholder={t('common.notSelected')}
          value={fieldConfig.pressureTypeOptions.find((opt) => opt.value === curve.pressureType)}
          onChange={(selected) => {
            handleChangeCurve({
              pressureType: (selected as ISelectOption<string | number>).value
            });
          }}
        />
        <JsFieldName name={t('jsfields.CompressorCurve.rotationSpeed')} />
        <InputNumber
          size="xs"
          variant="outline"
          value={curve.rotationSpeed}
          rightElement="об.мин"
          disabled={isReadonly}
          isOnChangeOnlyOnBlur
          onChange={(value) => {
            if (!value) {
              return;
            }

            handleChangeCurve({rotationSpeed: value});
          }}
        />
      </Grid>
      {!!curve.values.length && (
        <Box w="100%" borderRadius="4px" border="1px" borderColor="border" p="2px">
          <CompressorCurveTable
            isReadonly={isReadonly}
            values={curve.values}
            flowTitle={flowTitle}
            pressureTitle={pressureTitle}
            onDeleteItem={handleDeleteValue}
            onValuesChanged={(values) => handleChangeCurve({values})}
          />
        </Box>
      )}
      {!isReadonly && (
        <Button variant="ghost" size="smSquare" width="100%" onClick={handleAddItem}>
          {t('jsfields.CompressorCurve.addPoint')}
        </Button>
      )}
    </Flex>
  );
};

export const CompressorCurve = memo(CompressorCurveFC);
