/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ElementPort } from './ElementPort';
import {
    ElementPortFromJSON,
    ElementPortFromJSONTyped,
    ElementPortToJSON,
} from './ElementPort';

/**
 * 
 * @export
 * @interface ElementTemplate
 */
export interface ElementTemplate {
    /**
     * 
     * @type {string}
     * @memberof ElementTemplate
     */
    templateCode: string;
    /**
     * 
     * @type {Array<ElementPort>}
     * @memberof ElementTemplate
     */
    ports: Array<ElementPort>;
}

/**
 * Check if a given object implements the ElementTemplate interface.
 */
export function instanceOfElementTemplate(value: object): value is ElementTemplate {
    if (!('templateCode' in value) || value['templateCode'] === undefined) return false;
    if (!('ports' in value) || value['ports'] === undefined) return false;
    return true;
}

export function ElementTemplateFromJSON(json: any): ElementTemplate {
    return ElementTemplateFromJSONTyped(json, false);
}

export function ElementTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ElementTemplate {
    if (json == null) {
        return json;
    }
    return {
        
        'templateCode': json['template_code'],
        'ports': ((json['ports'] as Array<any>).map(ElementPortFromJSON)),
    };
}

export function ElementTemplateToJSON(value?: ElementTemplate | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'template_code': value['templateCode'],
        'ports': ((value['ports'] as Array<any>).map(ElementPortToJSON)),
    };
}

