/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FlowType } from './FlowType';
import {
    FlowTypeFromJSON,
    FlowTypeFromJSONTyped,
    FlowTypeToJSON,
} from './FlowType';
import type { PortType } from './PortType';
import {
    PortTypeFromJSON,
    PortTypeFromJSONTyped,
    PortTypeToJSON,
} from './PortType';

/**
 * 
 * @export
 * @interface ElementPort
 */
export interface ElementPort {
    /**
     * 
     * @type {string}
     * @memberof ElementPort
     */
    code: string;
    /**
     * 
     * @type {PortType}
     * @memberof ElementPort
     */
    type: PortType;
    /**
     * 
     * @type {FlowType}
     * @memberof ElementPort
     */
    flowType: FlowType;
}

/**
 * Check if a given object implements the ElementPort interface.
 */
export function instanceOfElementPort(value: object): value is ElementPort {
    if (!('code' in value) || value['code'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('flowType' in value) || value['flowType'] === undefined) return false;
    return true;
}

export function ElementPortFromJSON(json: any): ElementPort {
    return ElementPortFromJSONTyped(json, false);
}

export function ElementPortFromJSONTyped(json: any, ignoreDiscriminator: boolean): ElementPort {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'],
        'type': PortTypeFromJSON(json['type']),
        'flowType': FlowTypeFromJSON(json['flow_type']),
    };
}

export function ElementPortToJSON(value?: ElementPort | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'code': value['code'],
        'type': PortTypeToJSON(value['type']),
        'flow_type': FlowTypeToJSON(value['flowType']),
    };
}

