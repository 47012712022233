import {RJSFSchema, UiSchema} from '@rjsf/utils';
import {EElement} from '@progress-fe/core';

export const UI_SCHEMA_FLOW1_BASIC: UiSchema = {
  basic: {
    name: {
      'ui:field': '/schemas/jsf-input'
    },
    elementType: {
      'ui:field': '/schemas/jsf-select'
    },
    modelId: {
      'ui:field': '/schemas/jsf-select-arrow'
    }
  },
  connections: {
    source: {
      'ui:field': '/schemas/jsf-select-pick-arrow'
    },
    consumer: {
      'ui:field': '/schemas/jsf-select-pick-arrow'
    }
  }
};

export const SCHEMA_FLOW1_BASIC: RJSFSchema = {
  type: 'object',
  properties: {
    basic: {
      title: 'Базовые свойства',
      type: 'object',
      required: ['name', 'elementType', 'modelId'],
      properties: {
        name: {
          title: 'Имя',
          type: 'string',
          minLength: 1
        },
        elementType: {
          title: 'Тип элемента',
          type: 'string',
          oneOf: [{const: EElement.MaterialFlowElement, title: 'Материальный поток'}],
          readOnly: true
        },
        modelId: {
          title: 'Модель',
          type: 'string',
          oneOf: [{const: 'D03B80FA-0000-0000-0000-000000000002', title: 'Пенг-Робинсон-1'}]
        }
      }
    },
    connections: {
      title: 'Связи',
      type: 'object',
      required: ['consumer'],
      properties: {
        source: {
          title: 'Источник',
          type: ['string', 'null'],
          readOnly: true,
          oneOf: [
            {const: null},
            {const: '704F784D-0000-0000-0000-000000000001', title: 'COKER-100', readOnly: true}
          ]
        },
        consumer: {
          title: 'Потребитель',
          type: 'string',
          oneOf: [{const: '704F784D-0000-0000-0000-000000000001', title: 'COKER-100'}]
        }
      }
    }
  }
};

export const FORM_DATA_FLOW1_BASIC = {
  basic: {
    name: '1',
    elementType: EElement.MaterialFlowElement,
    modelId: 'D03B80FA-0000-0000-0000-000000000002'
  },
  connections: {
    source: null,
    consumer: '704F784D-0000-0000-0000-000000000001'
  }
};

export const REVERT_FORM_DATA = {};
