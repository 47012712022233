/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    id: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    roles: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    preferredUsername: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    givenName: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    familyName: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    name: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email: string | null;
}

/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value: object): value is User {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('roles' in value) || value['roles'] === undefined) return false;
    if (!('preferredUsername' in value) || value['preferredUsername'] === undefined) return false;
    if (!('givenName' in value) || value['givenName'] === undefined) return false;
    if (!('familyName' in value) || value['familyName'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    return true;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'roles': json['roles'],
        'preferredUsername': json['preferred_username'],
        'givenName': json['given_name'],
        'familyName': json['family_name'],
        'name': json['name'],
        'email': json['email'],
    };
}

export function UserToJSON(value?: User | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'roles': value['roles'],
        'preferred_username': value['preferredUsername'],
        'given_name': value['givenName'],
        'family_name': value['familyName'],
        'name': value['name'],
        'email': value['email'],
    };
}

