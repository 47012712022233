import {RJSFSchema, UiSchema} from '@rjsf/utils';

export const UI_SCHEMA_FLOW7_PROPS: UiSchema = {
  characteristics: {
    flow7_mw_avg: {
      'ui:field': '/schemas/jsf-input'
    },
    flow7_density_mass: {
      'ui:field': '/schemas/jsf-input'
    },
    flow7_z_factor: {
      'ui:field': '/schemas/jsf-input'
    },
    flow7_volume_flow_actual: {
      'ui:field': '/schemas/jsf-input'
    },
    flow7_Cp: {
      'ui:field': '/schemas/jsf-input'
    },
    flow7_Cv: {
      'ui:field': '/schemas/jsf-input'
    },
    flow7_Reid_pressure: {
      'ui:field': '/schemas/jsf-input'
    },
    flow7_dew_point_water: {
      'ui:field': '/schemas/jsf-input'
    },
    flow7_dew_point_hc: {
      'ui:field': '/schemas/jsf-input'
    }
  }
};

export const SCHEMA_FLOW7_PROPS: RJSFSchema = {
  type: 'object',
  properties: {
    characteristics: {
      title: 'Характеристики',
      type: 'object',
      properties: {
        flow7_mw_avg: {
          title: 'Средняя молекулярная масса',
          type: ['number', 'null'],
          exclusiveMinimum: 0,
          readOnly: true,
          $comment: 'кг/кмоль'
        },
        flow7_density_mass: {
          title: 'Массовая плотность',
          type: ['number', 'null'],
          exclusiveMinimum: 0,
          readOnly: true,
          $comment: 'кг/м³'
        },
        flow7_z_factor: {
          title: 'Коэффициент сжимаемости (Z)',
          type: ['number', 'null'],
          minimum: 0,
          maximum: 1,
          readOnly: true
        },
        flow7_volume_flow_actual: {
          title: 'Действительный объемный расход',
          type: ['number', 'null'],
          exclusiveMinimum: 0,
          readOnly: true,
          $comment: 'м³/ч'
        },
        flow7_Cp: {
          title: 'Cp',
          type: ['number', 'null'],
          exclusiveMinimum: 0,
          readOnly: true,
          $comment: 'кДж/(кмоль*°C)'
        },
        flow7_Cv: {
          title: 'Cv',
          type: ['number', 'null'],
          exclusiveMinimum: 0,
          readOnly: true,
          $comment: 'кДж/(кмоль*°C)'
        },
        flow7_Reid_pressure: {
          title: 'ДНП по Рейду',
          type: ['number', 'null'],
          exclusiveMinimum: 0,
          maximum: 100000,
          readOnly: true,
          $comment: 'кПа (абс.)'
        },
        flow7_dew_point_water: {
          title: 'Температура точки росы по воде (ТТРв)',
          type: ['number', 'null'],
          minimum: -273.15,
          maximum: 1000,
          readOnly: true,
          $comment: '°C'
        },
        flow7_dew_point_hc: {
          title: 'Температура точки росы по углеводородам (ТТРув)',
          type: ['number', 'null'],
          minimum: -273.15,
          maximum: 1000,
          readOnly: true,
          $comment: '°C'
        }
      }
    }
  }
};

export const FORM_DATA_FLOW7_PROPS = {
  characteristics: {
    flow7_mw_avg: null,
    flow7_density_mass: null,
    flow7_z_factor: null,
    flow7_volume_flow_actual: null,
    flow7_Cp: null,
    flow7_Cv: null,
    flow7_Reid_pressure: null,
    flow7_dew_point_water: null,
    flow7_dew_point_hc: null
  }
};

export const REVERT_FORM_DATA = {
  characteristics: {
    flow7_mw_avg: null,
    flow7_density_mass: null,
    flow7_z_factor: null,
    flow7_volume_flow_actual: null,
    flow7_Cp: null,
    flow7_Cv: null,
    flow7_Reid_pressure: null,
    flow7_dew_point_water: null,
    flow7_dew_point_hc: null
  }
};
