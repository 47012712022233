import {Grid} from '@chakra-ui/react';
import {FieldProps} from '@rjsf/utils';

import {JsFieldName} from '../../jsFormBase';
import {Checkbox} from '../../inputs';

/**
 * Component renders "/schemas/jsf-checkbox" field of JsonSchema.
 * @param props : field props come from JsonSchema.
 * Value of props.formData is boolean or null.
 */
const CheckboxJsFieldFC = (props: FieldProps) => {
  return (
    <Grid gridTemplateColumns="minmax(0, 180px) 1fr" alignItems="center" gap="4px">
      <JsFieldName name={props.schema.title} />
      <Checkbox checked={Boolean(props.formData)} onChange={props.onChange} />
    </Grid>
  );
};

export const CheckboxJsField = CheckboxJsFieldFC;
