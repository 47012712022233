import {types} from 'mobx-state-tree';

const BaseColumnValue = types
  .model('BaseColumnValue', {
    value: types.maybeNull(types.union(types.string, types.number))
  })
  .actions(() => ({}))
  .views(() => ({}));

export {BaseColumnValue};
