/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {ModelOut} from './ModelOut';
import {ModelOutFromJSON, ModelOutFromJSONTyped, ModelOutToJSON} from './ModelOut';
import type {ElementOut} from './ElementOut';
import {ElementOutFromJSON, ElementOutFromJSONTyped, ElementOutToJSON} from './ElementOut';
import type {ElementClassName} from './ElementClassName';
import {
  ElementClassNameFromJSON,
  ElementClassNameFromJSONTyped,
  ElementClassNameToJSON
} from './ElementClassName';

/**
 *
 * @export
 * @interface Data
 */
export interface Data {
  /**
   *
   * @type {string}
   * @memberof Data
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof Data
   */
  name: string;
  /**
   *
   * @type {ElementClassName}
   * @memberof Data
   */
  type: ElementClassName;
}

/**
 * Check if a given object implements the Data interface.
 */
export function instanceOfData(value: object): value is Data {
  if (!('uuid' in value) || value['uuid'] === undefined) return false;
  if (!('name' in value) || value['name'] === undefined) return false;
  if (!('type' in value) || value['type'] === undefined) return false;
  return true;
}

export function DataFromJSON(json: any): Data {
  return DataFromJSONTyped(json, false);
}

export function DataFromJSONTyped(json: any, ignoreDiscriminator: boolean): Data {
  if (json == null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    name: json['name'],
    type: ElementClassNameFromJSON(json['type'])
  };
}

export function DataToJSON(value?: Data | null): any {
  if (value == null) {
    return value;
  }
  return {
    uuid: value['uuid'],
    name: value['name'],
    type: ElementClassNameToJSON(value['type'])
  };
}
