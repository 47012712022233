import {FC, useEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Button, Flex, useToken} from '@chakra-ui/react';
import {Tooltip} from 'react-tooltip';
import {Svg} from '@progress-fe/ui-kit';
import {getTimeAgo} from '@progress-fe/core';

interface IProps {
  isDisabled: boolean;
  lastSaveDate: string | null;
  onSave: () => void;
}

const REFRESH_TIME_MS = 5 * 1000;
const TOOLTIP_ID = 'SAVE_REDO_TOOLTIP_ID';

const SaveIconButtonFC: FC<IProps> = ({isDisabled, lastSaveDate, onSave}) => {
  const [timeAgo, setTimeAgo] = useState<string | null>(null);
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);

  const [borderHex] = useToken('colors', ['border']);

  useEffect(() => {
    if (!lastSaveDate) {
      setTimeAgo(null);
      return;
    }

    setTimeAgo(getTimeAgo(lastSaveDate, true, true));

    intervalIdRef.current = setInterval(() => {
      setTimeAgo(getTimeAgo(lastSaveDate, true, true));
    }, REFRESH_TIME_MS);

    return () => {
      if (!!intervalIdRef.current) {
        setTimeAgo(null);
        clearInterval(intervalIdRef.current);
      }
    };
  }, [lastSaveDate]);

  return (
    <>
      <Button
        size="sm"
        aria-label=""
        variant="ghost"
        leftIcon={<Svg name="Save" />}
        isDisabled={isDisabled}
        data-tooltip-id={TOOLTIP_ID}
        onClick={onSave}
      >
        <Flex
          gap="1px"
          flexDirection="column"
          w={!lastSaveDate ? '70px' : '100px'}
          textAlign={!lastSaveDate ? 'center' : 'left'}
        >
          {!!lastSaveDate ? (
            <>
              <Box lineHeight="12px">Сохранено</Box>
              <Box
                fontSize="10px"
                fontWeight={400}
                lineHeight="10px"
                color="contrastIcon"
                className="cut-text"
              >
                {timeAgo}
              </Box>
            </>
          ) : (
            <Box lineHeight="12px">Сохранить</Box>
          )}
        </Flex>
      </Button>

      <Tooltip
        id={TOOLTIP_ID}
        delayShow={250}
        border={`1px solid ${borderHex}`}
        closeEvents={{click: true, mouseleave: true}}
        style={{padding: '0', backgroundColor: 'white'}}
        opacity="1"
      >
        <Box color="bodyText" p="6px 10px" fontSize="12px">
          Сохранить изменения
        </Box>
      </Tooltip>
    </>
  );
};

export const SaveIconButton = observer(SaveIconButtonFC);
