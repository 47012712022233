/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResponseStatusNoneType
 */
export interface ResponseStatusNoneType {
    /**
     * 
     * @type {string}
     * @memberof ResponseStatusNoneType
     */
    status: string;
    /**
     * 
     * @type {any}
     * @memberof ResponseStatusNoneType
     */
    data?: any | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseStatusNoneType
     */
    errorMsg?: string | null;
}

/**
 * Check if a given object implements the ResponseStatusNoneType interface.
 */
export function instanceOfResponseStatusNoneType(value: object): value is ResponseStatusNoneType {
    if (!('status' in value) || value['status'] === undefined) return false;
    return true;
}

export function ResponseStatusNoneTypeFromJSON(json: any): ResponseStatusNoneType {
    return ResponseStatusNoneTypeFromJSONTyped(json, false);
}

export function ResponseStatusNoneTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseStatusNoneType {
    if (json == null) {
        return json;
    }
    return {
        
        'status': json['status'],
        'data': json['data'] == null ? undefined : json['data'],
        'errorMsg': json['error_msg'] == null ? undefined : json['error_msg'],
    };
}

export function ResponseStatusNoneTypeToJSON(value?: ResponseStatusNoneType | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status': value['status'],
        'data': value['data'],
        'error_msg': value['errorMsg'],
    };
}

