import {cast, flow, Instance, types} from 'mobx-state-tree';
import {ISvgMenuItem} from '@progress-fe/ui-kit';
import {IModel, EElement, i18n} from '@progress-fe/core';

import {CatalogsApi, Model} from 'api';
import {RequestModel, ResetModel} from 'core/models';

const ModelsCatalog = types.compose(
  ResetModel,
  types
    .model('ModelsCatalog', {
      models: types.optional(types.array(types.frozen<IModel>()), []),
      fetchRequest: types.optional(RequestModel, {})
    })
    .actions((self) => ({
      load: flow(function* () {
        const response: Array<Model> = yield self.fetchRequest.send(
          CatalogsApi.catalogsGetModelsCatalog.bind(CatalogsApi),
          {}
        );

        const models: IModel[] = response.map((model) => ({
          ...model,
          elementType: model.elementType as EElement
        }));

        self.models = cast(models);
      })
    }))
    .views((self) => ({
      get svgMenuItems(): ISvgMenuItem<string>[] {
        const elementTypes = [...new Set(self.models.map((m) => m.elementType))];

        const menuItems: ISvgMenuItem<string>[] = [];
        elementTypes.forEach((elementType) => {
          const group: ISvgMenuItem<string> = {
            id: elementType,
            name: i18n.t(`enum.element.long.${elementType}`),
            items: []
          };

          self.models.forEach((model) => {
            if (model.elementType === elementType) {
              group.items?.push({
                id: model.uuid,
                name: model.name,
                isDisabled: false
              });
            }
          });

          menuItems.push(group);
        });

        return menuItems;
      }
    }))
);

export type TModelsCatalogModel = Instance<typeof ModelsCatalog>;

export {ModelsCatalog};
