import {memo} from 'react';
import {hasValue} from '@progress-fe/core';
import {Box, Grid, IconButton} from '@chakra-ui/react';

import {Svg} from '../../../../helpers';
import {InputNumber} from '../../../../inputs';

interface IProps {
  value: Array<{sampleId: string; sampleValue: string | null}>;
  suffix?: string;
  readOnly?: boolean;
  onChangeSimpleValue: (index: number, sampleValue: string | null) => void;
  onDeleteSample: (sampleId: string) => void;
}

const CompositionTableFC = ({
  value,
  readOnly,
  suffix,
  onChangeSimpleValue,
  onDeleteSample
}: IProps) => {
  return (
    <Box p="2px 0 0 0">
      {value.map((item, index) => (
        <Grid key={index} p="2px 0" gap="4px" alignItems="center" templateColumns="172px 1fr 24px">
          <Box p="0 0 0 2px">{item.sampleId}</Box>
          <InputNumber
            isFloat
            min={0}
            size="xs"
            variant="outline"
            sx={{width: '100%'}}
            disabled={readOnly}
            isOnChangeOnlyOnBlur
            value={item.sampleValue || undefined}
            isInvalid={!hasValue(item.sampleValue)}
            rightElement={suffix ? <>{suffix}</> : undefined}
            onChange={(v) => onChangeSimpleValue(index, v)}
          />
          <IconButton
            size="smSquare"
            aria-label=""
            variant="ghost"
            isDisabled={readOnly}
            icon={<Svg size="s12" name="Cross" />}
            onClick={() => onDeleteSample(item.sampleId)}
          />
        </Grid>
      ))}
    </Box>
  );
};

export const CompositionTable = memo(CompositionTableFC);
