import {FC} from 'react';
import {Box, Center} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';
import {JsFormName, Loader} from '@progress-fe/ui-kit';

import {TModelDetailsModel, TJsonSchemaModel} from 'core/models';
import {IJsonSchemaTab, JsTabsFormWrapper} from 'ui-kit';

interface IProps {
  modelDetails: TModelDetailsModel;
}

const ModelFormsFC: FC<IProps> = ({modelDetails}) => {
  const {name, jsonSchemas, isSchemasLoading} = modelDetails;

  const tabs: IJsonSchemaTab[] = jsonSchemas.map((jsonSchema, index) => ({
    index: index,
    name: jsonSchema.name,
    isDisabled: jsonSchema.schema.readOnly,
    jsonSchema
  }));

  const handleChangeFormData = (newFormData: unknown, jsonSchema: TJsonSchemaModel) => {
    jsonSchema.updateFormData(newFormData);
    console.info('FormData:', newFormData);
  };

  return (
    <Box>
      <JsFormName name={name} />

      {isSchemasLoading ? (
        <Center p="100px 0 0">
          <Loader />
        </Center>
      ) : (
        <JsTabsFormWrapper
          tabs={tabs}
          onChange={(formData, tabId: number) => {
            handleChangeFormData(formData, tabs[tabId]?.jsonSchema);
          }}
        />
      )}
    </Box>
  );
};

export const ModelForms = observer(ModelFormsFC);
