import {FC, memo} from 'react';
import {Box} from '@chakra-ui/react';

interface IProps {
  name: string;
}

const JsFormNameFC: FC<IProps> = ({name}) => {
  return (
    <Box fontSize="16px" fontWeight="700" pb="8px" h="32px" color="black" className="cut-text">
      {name}
    </Box>
  );
};

export const JsFormName = memo(JsFormNameFC);
