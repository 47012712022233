import {cast, flow, types} from 'mobx-state-tree';
import {EElement, EStructureItem, EventEmitter} from '@progress-fe/core';
import {RFElementByElement, TRFWorkZoneDataConfig} from '@progress-fe/rf-core';
import {Node, XYPosition} from '@xyflow/react';

import {
  ProjectBase,
  TElementDetailsModel,
  TModelDetailsModel,
  TResultDetailsModel
} from 'core/models';

import {
  ProjectElements,
  ProjectModels,
  ProjectResults,
  ProjectSettings,
  ProjectTask
} from './models';

const TechProcessStore = types
  .compose(
    ProjectBase,
    types.model('TechProcessStore', {
      projectSettings: types.optional(ProjectSettings, {}),
      projectModels: types.optional(ProjectModels, {}),
      projectElements: types.optional(ProjectElements, {}),
      projectTask: types.optional(ProjectTask, {}),
      projectResults: types.optional(ProjectResults, {})
    })
  )
  .actions((self) => ({
    _clearAllJsonSchemas(): void {
      self.projectModels.clearJsonSchemas();
      self.projectElements.clearJsonSchemas();
    },
    _selectEntityById(entityId: string): void {
      this._clearAllJsonSchemas();

      if (self.projectElements.hasElement(entityId)) {
        self.uiState.select(EStructureItem.Element, entityId);
        self.projectElements.loadJsonSchemasByUuid(entityId).then();
      } else if (self.projectModels.hasModel(entityId)) {
        self.uiState.select(EStructureItem.Model, entityId);
        self.projectModels.loadJsonSchemasByUuid(entityId).then();
      } else if (self.projectResults.hasResult(entityId)) {
        self.uiState.select(EStructureItem.Result, entityId);
      }
    },
    selectEntityByType(entityType: EStructureItem, entityId?: string | null) {
      this._clearAllJsonSchemas();
      self.uiState.select(entityType, entityId);

      if (entityType === EStructureItem.Element && !!entityId) {
        self.projectElements.loadJsonSchemasByUuid(entityId).then();
      } else if (entityType === EStructureItem.Model && !!entityId) {
        self.projectModels.loadJsonSchemasByUuid(entityId).then();
      }
    }
  }))
  .actions((self) => ({
    _subscribe() {
      EventEmitter.on('SelectEntity', self._selectEntityById);
    },
    _unsubscribe() {
      EventEmitter.off('SelectEntity', self._selectEntityById);
    },
    uninitialize() {
      this._unsubscribe();
      self.resetModel();
    }
  }))
  .actions((self) => ({
    initProject: flow(function* (projectId: string) {
      self.isLoading = true;
      yield self._baseInit(projectId);

      if (!!self.projectInfo) {
        const {uuid} = self.projectInfo;

        yield self.projectModels.init(uuid, self.checkpointUuid);
        yield self.projectElements.init(uuid, self.checkpointUuid);
        self.projectResults.fetch(projectId); // TODO
        self.projectSettings.init(projectId); // TODO

        self._subscribe();
      }

      self.isLoading = false;
    })
  }))
  .actions((self) => ({
    createElement: flow(function* (type: EElement, templateCode?: string, position?: XYPosition) {
      const element = yield self.projectElements.addElement(type);
      if (!!element) {
        const node: Node<TRFWorkZoneDataConfig> = {
          id: element.uuid,
          type: RFElementByElement[type] || undefined,
          position: position || {x: 0, y: 0},
          data: {
            elementName: element.name,
            templateCode: templateCode || 'default'
          }
        };

        self.projectElements.nodes.push(node);
        self.projectElements.elements.push(element);
        self.selectEntityByType(EStructureItem.Element, element.uuid);
        yield self.projectElements.updateWorkZone();
      }
    }),
    deleteElement: flow(function* (uuid: string) {
      const isDone = yield self.projectElements.removeElement(uuid);
      if (isDone) {
        const {nodes, elements} = self.projectElements;
        self.projectElements.nodes = cast([...nodes.filter((n) => n.id !== uuid)]);
        self.projectElements.elements = cast([...elements.filter((n) => n.uuid !== uuid)]);
        self.selectEntityByType(EStructureItem.Settings);
        yield self.projectElements.updateWorkZone();
      }
    }),
    createModel: flow(function* (uuid: string) {
      const model = yield self.projectModels.addModel(uuid);
      if (!!model) {
        self.projectModels.models.push(model);
        self.selectEntityByType(EStructureItem.Model, model.uuid);
      }
    }),
    deleteModel: flow(function* (uuid: string) {
      const isDone = yield self.projectModels.removeModel(uuid);
      if (isDone) {
        const {models} = self.projectModels;
        self.projectModels.models = cast([...models.filter((n) => n.uuid !== uuid)]);
        self.selectEntityByType(EStructureItem.Settings);
      }
    })
  }))
  .views((self) => ({
    get selectedElement(): TElementDetailsModel | null {
      if (self.uiState.entityType !== EStructureItem.Element) return null;
      return self.projectElements.elements.find((el) => el.uuid === self.uiState.entityId) || null;
    },
    get selectedModel(): TModelDetailsModel | null {
      if (self.uiState.entityType !== EStructureItem.Model) return null;
      return self.projectModels.models.find((el) => el.uuid === self.uiState.entityId) || null;
    },
    get selectedResult(): TResultDetailsModel | null {
      if (self.uiState.entityType !== EStructureItem.Result) return null;
      return self.projectResults.results.find((el) => el.id === self.uiState.entityId) || null;
    }
  }));

export {TechProcessStore};
