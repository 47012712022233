import {TableVirtuoso} from 'react-virtuoso';
import {Grid, Text, Flex, Center, useToken} from '@chakra-ui/react';
import {SystemStyleObject} from '@chakra-ui/styled-system';
import {forwardRef} from 'react';

import {Checkbox} from '../../inputs';
import {ISimpleTableColumn, ISimpleTableRow} from '../../../interfaces';

import {TableValue} from './components';
import {TableEditableValue} from './components/TableEditableValue';

export enum TABLE_VARIANTS {
  FIRST_COLOMN_FIXED
}

interface IProps<T> {
  isCheckboxShown?: boolean;
  noItemsTitle?: string;
  columns: ISimpleTableColumn[];
  rows: ISimpleTableRow<T>[];
  tableSx?: SystemStyleObject;
  variant?: TABLE_VARIANTS;
  onSelect?: (value: T, isSelected: boolean) => void;
  onSelectAll?: () => void;
  onUnselectAll?: () => void;
  onValueChange?: (rowId: number, colomnId: number, newValue: string) => void;
}

const VirtualTableFC = <T,>({
  isCheckboxShown,
  noItemsTitle,
  rows,
  columns,
  tableSx = {},
  variant,
  onSelect,
  onSelectAll,
  onUnselectAll,
  onValueChange = () => {}
}: IProps<T>) => {
  const [bgHex] = useToken('colors', ['bg']);

  const gridTemplate = `${isCheckboxShown ? '20px' : ''} ${columns.map((column) => `minmax(${column.width ?? '90px'} , ${column.columnTemplate ?? '1fr'})`).join(' ')}`;

  const isAllSelected = (): boolean => {
    return rows.every((row) => row.isDisabled || row.isSelected);
  };

  const isFirstColumnSticky = variant === TABLE_VARIANTS.FIRST_COLOMN_FIXED;

  const columnFixedPosition = isCheckboxShown ? '24px' : '0px';

  return (
    <Flex
      flexDirection="column"
      border="1px solid"
      borderColor="border"
      borderRadius="4px"
      w="100%"
      h="100%"
      sx={tableSx}
      overflow="hidden"
    >
      {rows.length > 0 && (
        <TableVirtuoso
          data={rows}
          style={{flexGrow: 1, overflowY: 'scroll', width: '100%'}}
          components={{
            Table: ({style, ...props}) => (
              <table
                {...props}
                style={{
                  ...style,
                  width: '100%',
                  background: bgHex
                }}
              />
            ),
            TableBody: forwardRef(({style, ...props}, ref) => (
              <tbody {...props} style={{...style, padding: '2px 0'}} ref={ref} />
            ))
          }}
          fixedHeaderContent={() => (
            <Grid
              gap="4px"
              templateColumns={gridTemplate}
              borderBottom="1px solid"
              borderColor="border"
              bg="bg"
            >
              {isCheckboxShown && (
                <Flex
                  pl="4px"
                  position={isFirstColumnSticky ? 'sticky' : 'static'}
                  left={isFirstColumnSticky ? '0' : undefined}
                >
                  {!!onSelectAll && !!onUnselectAll && (
                    <Checkbox
                      checked={isAllSelected()}
                      onChange={() => {
                        if (isAllSelected()) {
                          onUnselectAll();
                        } else {
                          onSelectAll();
                        }
                      }}
                    />
                  )}
                </Flex>
              )}
              {columns.map((column, idx) => (
                <Flex
                  key={column.name}
                  textAlign={column.align ?? 'center'}
                  align="center"
                  justify={column.align === 'right' ? 'end' : 'start'}
                  borderRight={isFirstColumnSticky && idx === 0 ? '1px solid' : ''}
                  borderColor="border"
                  minH="24px"
                  position={idx === 0 && isFirstColumnSticky ? 'sticky' : 'static'}
                  left={idx === 0 && isFirstColumnSticky ? columnFixedPosition : undefined}
                  zIndex={idx === 0 && isFirstColumnSticky ? 1 : undefined}
                  bg="bg"
                  _last={{paddingRight: '4px'}}
                  _first={{
                    paddingLeft: '4px'
                  }}
                >
                  <Text fontSize="12px" color="bodyText">
                    {column.name} {!!column.unit && `(${column.unit})`}
                  </Text>
                </Flex>
              ))}
            </Grid>
          )}
          itemContent={(rowindex, {key, values, isSelected, isDisabled}) => {
            return (
              <Grid gap="4px" templateColumns={gridTemplate} _last={{borderBottom: 'none'}}>
                {isCheckboxShown && (
                  <Flex
                    pl="4px"
                    position={isFirstColumnSticky ? 'sticky' : 'static'}
                    left={isFirstColumnSticky ? '0' : undefined}
                  >
                    <Checkbox
                      checked={isSelected}
                      isDisabled={isDisabled}
                      onChange={(checked) => {
                        onSelect?.(key, checked);
                      }}
                    />
                  </Flex>
                )}

                {values.map((value, index) => (
                  <Flex
                    key={index}
                    className="table-column"
                    align="center"
                    h="24px"
                    boxSizing="content-box"
                    overflow="hidden"
                    justifyContent={columns[index]?.align === 'right' ? 'end' : 'start'}
                    borderRight={isFirstColumnSticky && index === 0 ? '1px solid' : ''}
                    borderColor="border"
                    bg="bg"
                    p="2px 0"
                    position={index === 0 && isFirstColumnSticky ? 'sticky' : 'static'}
                    left={index === 0 && isFirstColumnSticky ? columnFixedPosition : undefined}
                    _last={{paddingRight: '4px'}}
                    _first={{
                      paddingLeft: '4px'
                    }}
                  >
                    {typeof columns[index].readOnly === 'boolean' && !columns[index].readOnly ? (
                      <TableEditableValue
                        isDisabled={!!isDisabled}
                        type={columns[index].type}
                        options={columns[index].options}
                        value={value}
                        onChangeValue={(newValue: string) => {
                          onValueChange(rowindex, index, newValue);
                        }}
                      />
                    ) : (
                      <TableValue isDisabled={!!isDisabled} value={value} />
                    )}
                  </Flex>
                ))}
              </Grid>
            );
          }}
        />
      )}

      {rows.length === 0 && (
        <Center h="60px" fontSize="12px" color="bodyText">
          {noItemsTitle}
        </Center>
      )}
    </Flex>
  );
};

export const VirtualTable = VirtualTableFC;
