import {ISelectOption} from '@progress-fe/ui-kit';

export enum ETaskType {
  Calculation = 'Calculation',
  CalculationByGrid = 'CalculationByGrid',
  Optimization = 'Optimization'
}

export const TaskTypeOptions: ISelectOption<ETaskType>[] = [
  {value: ETaskType.Calculation, label: 'Расчёт'},
  {value: ETaskType.CalculationByGrid, label: 'Расчёт по сетке', readonly: true},
  {value: ETaskType.Optimization, label: 'Оптимизация', readonly: true}
];
