import {Instance, types} from 'mobx-state-tree';

import {ResetModel} from 'core/models';
import {ETaskType} from 'core/enums';

const ProjectTask = types
  .compose(
    ResetModel,
    types.model('ProjectTask', {
      type: types.optional(types.enumeration(Object.values(ETaskType)), ETaskType.Calculation),
      description: types.optional(types.string, '')
    })
  )
  .actions((self) => ({
    setType(type: ETaskType): void {
      self.type = type;
    },
    setDescription(description: string): void {
      self.description = description;
    }
  }))
  .views(() => ({}));

export type TProjectTaskModel = Instance<typeof ProjectTask>;

export {ProjectTask};
