import {Instance, types} from 'mobx-state-tree';

import {ResetModel} from 'core/models';

import {ChemicalComponents} from './models';

const ProjectSettings = types
  .compose(
    ResetModel,
    types.model('ProjectSettings', {
      chemicalComponents: types.optional(ChemicalComponents, {})
    })
  )
  .actions(() => ({
    init(projectId: string): void {
      console.info(projectId);
    }
  }))
  .views(() => ({}));

export type TProjectSettingsModel = Instance<typeof ProjectSettings>;

export {ProjectSettings};
