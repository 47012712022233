import {FC, memo, ReactElement, useEffect, useRef, useState} from 'react';
import {
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightAddon,
  NumberInput,
  NumberInputField
} from '@chakra-ui/react';
import {hasValue} from '@progress-fe/core';

interface IProps extends Omit<InputProps, 'onChange' | 'onBlur'> {
  isOnChangeOnlyOnBlur?: boolean;
  isFloat?: boolean;
  disabled?: boolean;
  clampValueOnBlur?: boolean;
  leftElement?: ReactElement | string;
  rightElement?: ReactElement | string;
  onChange?: (value: string | null) => void;
}

const InputNumberFC: FC<IProps> = ({
  isOnChangeOnlyOnBlur,
  min,
  max,
  value,
  size,
  variant,
  disabled,
  isFloat,
  clampValueOnBlur = false,
  leftElement,
  rightElement,
  isInvalid,
  isReadOnly,
  onChange
}) => {
  const [validValue, setValidValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setInputValue(value?.toString() || '');
    setValidValue(value?.toString() || '');
  }, [value]);

  const minValue = hasValue(min) ? Number(min) : undefined;
  const maxValue = hasValue(max) ? Number(max) : undefined;
  const isNegativeAllowed = hasValue(minValue) ? minValue < 0 : true;

  const isMinInvalid =
    hasValue(minValue) && hasValue(inputValue) && !!inputValue && Number(inputValue) < minValue;
  const isMaxInvalid =
    hasValue(maxValue) && hasValue(inputValue) && !!inputValue && Number(inputValue) > maxValue;

  return (
    <InputGroup size={size}>
      {!!leftElement && <InputLeftElement>{leftElement}</InputLeftElement>}

      <NumberInput
        size={size}
        min={minValue}
        max={maxValue}
        variant={variant}
        value={inputValue}
        isDisabled={disabled}
        isInvalid={isInvalid || isMinInvalid || isMaxInvalid}
        clampValueOnBlur={clampValueOnBlur}
        isReadOnly={isReadOnly}
        sx={{width: '100%'}} // FYI: Without stepper
        // TODO: Refactoring
        onChange={(valueString) => {
          const rgx = /\./g;

          // skip second "." symbol
          const dotsCount = valueString.match(rgx)?.length || 0;
          if (dotsCount > 1 || (dotsCount === 1 && !isFloat)) return;

          // negative value is not allowed
          if (!isNegativeAllowed && valueString.includes('-')) return;

          // skip second "-" symbol
          if (isNegativeAllowed && valueString.startsWith('--')) return;

          // skip "+" symbol
          if (valueString.endsWith('+')) return;

          // skip "e" symbol
          if (valueString.includes('e')) return;

          setInputValue(valueString);
          if (!isOnChangeOnlyOnBlur) {
            onChange?.(valueString || null);
          }

          // skip when value ends with "." symbol
          if (valueString.endsWith('.')) return;

          // skip when value equals to "-" symbol
          if (valueString === '-') return;

          setValidValue(valueString);
        }}
        onBlur={() => {
          onChange?.(validValue || null);
        }}
      >
        <NumberInputField ref={inputRef} sx={rightElement ? {borderRightRadius: '0'} : {}} />
      </NumberInput>

      {!!rightElement && (
        <InputRightAddon
          color="lightGray"
          justifyContent="flex-end"
          bg={disabled ? 'disabledGray' : 'white'}
        >
          {rightElement}
        </InputRightAddon>
      )}
    </InputGroup>
  );
};

export const InputNumber = memo(InputNumberFC);
