import {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {Flex, Text} from '@chakra-ui/react';
import {Svg} from '@progress-fe/ui-kit';

interface IProps {
  username: string;
  onSignOut: () => void;
}

const UserSignOutFC: FC<IProps> = ({username, onSignOut}) => {
  return (
    <Flex flexDirection="column">
      <Flex
        align="center"
        h="38px"
        p="8px"
        gap="8px"
        borderRadius="4px"
        _hover={{cursor: 'pointer'}}
      >
        <Svg name="User" />
        <Text fontSize="14px" color="black">
          {username}
        </Text>
      </Flex>

      <Flex
        align="center"
        h="38px"
        p="8px"
        gap="8px"
        borderRadius="4px"
        _hover={{cursor: 'pointer'}}
        onClick={onSignOut}
      >
        <Svg name="SignOut" />
        <Text fontSize="14px" color="black">
          Выйти
        </Text>
      </Flex>
    </Flex>
  );
};

export const UserSignOut = observer(UserSignOutFC);
