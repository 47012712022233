import {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {useParams} from 'react-router';
import {Grid, GridItem} from '@chakra-ui/react';
import {EStructureItem} from '@progress-fe/core';

import {useStore} from 'core/hooks';
import {ProjectLayout} from 'ui-kit';

import {ProjectForms, ProjectStructure, ProjectZones} from './components';

const TechProcessSceneFC = () => {
  const {techProcessStore} = useStore();
  const {uiState} = techProcessStore;

  const {id} = useParams();

  useEffect(() => {
    techProcessStore.initProject(id || '');

    return () => {
      techProcessStore.uninitialize();
    };
  }, [id, techProcessStore]);

  return (
    <ProjectLayout project={techProcessStore}>
      <Grid position="relative" h="var(--height-layout)" gridTemplateColumns={'280px 440px 1fr'}>
        {/* PROJECT ITEMS: SETTINGS, ELEMENTS, MODELS ... */}
        <GridItem bg="bg" borderRight="1px" borderColor="border">
          <ProjectStructure />
        </GridItem>

        {/* SELECTED PROJECT ITEM */}
        <GridItem
          bg="bg"
          borderRight="1px"
          borderColor="border"
          overflowY={uiState.entityType === EStructureItem.Settings ? 'hidden' : 'auto'}
        >
          <ProjectForms />
        </GridItem>

        {/* SHOW STRUCTURE, CALCULATION OR RESULTS */}
        <GridItem>
          <ProjectZones />
        </GridItem>
      </Grid>
    </ProjectLayout>
  );
};

export const TechProcessScene = observer(TechProcessSceneFC);

export default TechProcessScene;
