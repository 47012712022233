/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ElementGroupNameCode = {
    DebugGroup: 'DebugGroup',
    FlowsGroup: 'FlowsGroup',
    PipelineGroup: 'PipelineGroup',
    PressureChangeGroup: 'PressureChangeGroup',
    HeatTransferGroup: 'HeatTransferGroup',
    CapacitiveDevicesGroup: 'CapacitiveDevicesGroup',
    ColumnDevicesGroup: 'ColumnDevicesGroup',
    UnknownDefaultOpenApi: '11184809'
} as const;
export type ElementGroupNameCode = typeof ElementGroupNameCode[keyof typeof ElementGroupNameCode];


export function instanceOfElementGroupNameCode(value: any): boolean {
    for (const key in ElementGroupNameCode) {
        if (Object.prototype.hasOwnProperty.call(ElementGroupNameCode, key)) {
            if ((ElementGroupNameCode as Record<string, ElementGroupNameCode>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ElementGroupNameCodeFromJSON(json: any): ElementGroupNameCode {
    return ElementGroupNameCodeFromJSONTyped(json, false);
}

export function ElementGroupNameCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ElementGroupNameCode {
    return json as ElementGroupNameCode;
}

export function ElementGroupNameCodeToJSON(value?: ElementGroupNameCode | null): any {
    return value as any;
}

