import {observer} from 'mobx-react-lite';
import {Text, Flex} from '@chakra-ui/react';
import {Svg} from '@progress-fe/ui-kit';

import {IMenuOption} from 'core/interfaces';

interface IProps<T> {
  activeMenuItem: T;
  options: IMenuOption<T>[];
  onSelect: (value: T) => void;
}

const SideMenuFC = <T,>({activeMenuItem, options, onSelect}: IProps<T>) => {
  return (
    <Flex flexDirection="column">
      {options.map((option) => (
        <Flex
          key={option.label}
          align="center"
          h="38px"
          p="8px"
          gap="8px"
          borderRadius="4px"
          bg={activeMenuItem === option.value ? 'buttonBg' : ''}
          _hover={{cursor: 'pointer'}}
          onClick={() => onSelect(option.value)}
        >
          <Svg name={option.icon} />
          <Text fontSize="14px" color="bodyText">
            {option.label}
          </Text>
        </Flex>
      ))}
    </Flex>
  );
};

export const SideMenu = observer(SideMenuFC);
