import {memo, useMemo, useState} from 'react';
import {FieldProps} from '@rjsf/utils';
import {isClose} from 'is-close';
import _ from 'lodash-es';
import {hasValue, IPureComponent, ILumpComponent, EComponentBaseType} from '@progress-fe/core';
import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList
} from '@chakra-ui/react';

import {Svg} from '../../helpers';
import {ComponentsLists} from '../../lists';
import {Input, InputNumber, Select} from '../../inputs';
import {ISelectOption} from '../../../interfaces';
import {JsFieldName} from '../../jsFormBase';

interface IProps extends FieldProps {
  pureList: IPureComponent[];
  lumpList: ILumpComponent[];
  favoritePureList: IPureComponent[];
  favoriteLumpList: ILumpComponent[];
  componentBaseType: EComponentBaseType;
}

const PRECISION = 10;

type TFormDataOption = ISelectOption<string | number> & {unit?: string};

type TFormDataConfig = {
  title: string;
  normalizable: boolean;
  options: TFormDataOption[];
};

type TFormDataFieldValue = {
  optionValue: string | number;
  value: Record<string, string | null>;
};

type TFormData = {
  fieldConfig: TFormDataConfig;
  fieldValues: TFormDataFieldValue;
};

/**
 * Component renders "/schemas/jsf-flow-composition" field of JsonSchema.
 * @param props : field props come from JsonSchema.
 * Value of props.formData is an instance of TFormData.
 */
const FlowCompositionJsFieldFC = (props: IProps) => {
  const [isDictionaryShown, setIsDictionaryShown] = useState(false);

  const formData = props.formData as TFormData;
  const keys = Object.keys(formData.fieldValues.value);

  const pureListSelected = props.pureList.filter((c) => keys.includes(c.uuid));
  const lumpListSelected = props.lumpList.filter((c) => keys.includes(c.uuid));

  const hasComponents = pureListSelected.length + lumpListSelected.length > 0;
  const selectedComponentsAmount = pureListSelected.length + lumpListSelected.length;
  const isFieldInvalid = !!props.rawErrors || selectedComponentsAmount !== keys.length;

  const selectedOption = useMemo(() => {
    return formData.fieldConfig.options.find(
      (opt) => opt.value === formData.fieldValues.optionValue
    );
  }, [formData.fieldConfig.options, formData.fieldValues.optionValue]);

  // TODO: Sum of components must be calculated on BE side
  const sumOfComponents = useMemo(() => {
    const valuesArray = Object.values(formData.fieldValues.value);
    if (valuesArray.every((v) => v === null)) {
      return undefined;
    }

    const sum = _.sum(valuesArray.map((i) => (hasValue(i) ? Number(i) : undefined)));
    const isCloseToOne = isClose(1.0, sum, 0.0000000001);
    return sum === 1 || isCloseToOne ? 1 : sum;
  }, [formData.fieldValues.value]);

  const handleChangeFormOptionValue = (option: ISelectOption<string | number>) => {
    props.onChange({
      ...formData,
      fieldValues: {
        ...formData.fieldValues,
        optionValue: option.value
      }
    });
  };

  const handleChangeFormValue = (value: Record<string, string | null>) => {
    props.onChange({
      ...formData,
      fieldValues: {
        ...formData.fieldValues,
        value
      }
    });
  };

  const handleAddComponent = (key: string) => {
    handleChangeFormValue({
      ...formData.fieldValues.value,
      [key]: null
    });
  };

  const handleChangeValue = (key: string, value: string | null) => {
    handleChangeFormValue({
      ...formData.fieldValues.value,
      [key]: value
    });
  };

  const handleDeleteComponent = (key: string) => {
    handleChangeFormValue({..._.omit(formData.fieldValues.value, [key])});
  };

  const handleClearValues = () => {
    const existingKeys = Object.keys(formData.fieldValues.value);

    let newValue = {};
    existingKeys.forEach((key) => {
      newValue = {...newValue, [key]: null};
    });

    handleChangeFormValue(newValue);
  };

  // TODO: Sum of components must be calculated on BE side
  const handleNormalize = () => {
    if (!formData.fieldConfig.normalizable) {
      return;
    }

    let newFormData = {};

    // Normalize summary
    let sumAfterNormalization = 0;
    let keyOfLastComponent = null;
    let valueOfLastComponent = 0;

    // Try normalize values
    keys.forEach((key) => {
      const currentValue = formData.fieldValues.value[key];
      const isNormalizePossible = currentValue && sumOfComponents;
      const normValue = isNormalizePossible ? Number(currentValue) / sumOfComponents : 0;
      const normValueFixed = Number(normValue.toFixed(PRECISION));

      newFormData = {...newFormData, [key]: normValueFixed};

      sumAfterNormalization = sumAfterNormalization + normValueFixed;
      keyOfLastComponent = key;
      valueOfLastComponent = normValueFixed;
    });

    // Sum may equal to 0.9999 after normalization
    // Try to increase value of last component
    if (sumAfterNormalization !== 1 && !!keyOfLastComponent) {
      const diff = Number((1 - sumAfterNormalization).toFixed(PRECISION));
      newFormData = {...newFormData, [keyOfLastComponent]: valueOfLastComponent + diff};
    }

    handleChangeFormValue(newFormData);
  };

  return (
    <Box data-testid="FlowCompositionJsField-test">
      {!isDictionaryShown && (
        <Flex p="4px 0" flexDirection="column" gap="8px">
          {/* SELECT FIELD */}
          <Grid gridTemplateColumns={`minmax(0, 180px) 1fr`} alignItems={'center'}>
            <JsFieldName name={formData.fieldConfig.title} />
            <Select
              name={props.name}
              options={formData.fieldConfig.options}
              placeholder="Не выбрано"
              value={formData.fieldConfig.options.find(
                (opt) => opt.value === formData.fieldValues.optionValue
              )}
              onChange={(newValue) => {
                handleChangeFormOptionValue(newValue as ISelectOption<string | number>);
              }}
            />
          </Grid>

          {/* CHEMICAL COMPONENTS LIST */}
          {hasComponents ? (
            <GridItem
              p="4px"
              borderRadius="4px"
              border="1px solid"
              borderColor={isFieldInvalid ? 'error' : 'border'}
            >
              {/* CHEMICAL COMPONENTS LIST HEADER */}
              <Grid
                gap="4px"
                p="0 0 4px 0"
                alignItems="center"
                borderBottom="1px solid"
                borderColor="border"
                templateColumns="176px 1fr 24px"
              >
                <GridItem>Компонент</GridItem>
                <GridItem>Количество</GridItem>
                <GridItem>
                  <IconButton
                    size="smSquare"
                    aria-label=""
                    variant="ghostTr"
                    icon={<Svg size="s12" name="Cross" />}
                    isDisabled={props.schema.readOnly}
                    onClick={() => handleClearValues()}
                  />
                </GridItem>
              </Grid>

              {/* CHEMICAL COMPONENTS LIST ITEMS */}
              <Box p="2px 0">
                {[...pureListSelected, ...lumpListSelected].map((item) => (
                  <Grid key={item.uuid} gap="4px" p="2px 0" templateColumns="176px 1fr 24px">
                    <Flex align="center">{item.name}</Flex>
                    <InputNumber
                      isFloat
                      min={0}
                      size="xs"
                      variant="outline"
                      sx={{width: '100%'}}
                      isOnChangeOnlyOnBlur
                      disabled={props.schema.readOnly}
                      rightElement={selectedOption?.unit}
                      value={formData.fieldValues.value[item.uuid] || undefined}
                      onChange={(value) => handleChangeValue(item.uuid, value)}
                    />
                    <IconButton
                      size="smSquare"
                      aria-label=""
                      variant="ghost"
                      isDisabled={props.schema.readOnly}
                      icon={<Svg size="s12" name="Cross" />}
                      onClick={() => handleDeleteComponent(item.uuid)}
                    />
                  </Grid>
                ))}
              </Box>

              {/* CHEMICAL COMPONENTS SUMMARY */}
              <Grid
                gap="4px"
                p="4px 0 0 0"
                borderTop="1px solid"
                borderColor="border"
                templateColumns="176px 1fr auto"
              >
                <Flex align="center">Итого</Flex>
                <Input
                  size="xs"
                  isDisabled
                  variant="outline"
                  value={sumOfComponents}
                  isInvalid={sumOfComponents !== 1}
                />
                {formData.fieldConfig.normalizable && (
                  <Button
                    size="xs"
                    variant="ghost"
                    isDisabled={props.schema.readOnly}
                    onClick={handleNormalize}
                  >
                    Нормализовать
                  </Button>
                )}
              </Grid>
            </GridItem>
          ) : (
            <Center
              h="32px"
              color="bodyText"
              border="1px solid"
              borderColor={isFieldInvalid ? 'error' : 'border'}
              borderRadius="4px"
            >
              <Box opacity="0.6">Нет компонентов</Box>
            </Center>
          )}

          {/* MENU FOR ADDING CHEMICAL COMPONENTS */}
          {!props.schema.readOnly && (
            <GridItem>
              <Menu matchWidth offset={[0, 2]} variant="outline">
                <MenuButton
                  size="xs"
                  as={Button}
                  width="100%"
                  variant="ghost"
                  onClick={() => setIsDictionaryShown(false)}
                >
                  Добавить
                </MenuButton>

                {/* DEFAULT CHEMICAL COMPONENTS */}
                <MenuList motionProps={{animate: false}}>
                  {props.favoritePureList.map((c) => (
                    <MenuItem
                      key={c.uuid}
                      isDisabled={keys.includes(c.uuid)}
                      onClick={() => handleAddComponent(c.uuid)}
                    >
                      {c.name}
                    </MenuItem>
                  ))}

                  {props.favoriteLumpList.map((c) => (
                    <MenuItem
                      key={c.uuid}
                      isDisabled={keys.includes(c.uuid)}
                      onClick={() => handleAddComponent(c.uuid)}
                    >
                      {c.name}
                    </MenuItem>
                  ))}

                  <MenuDivider />
                  <MenuItem onClick={() => setIsDictionaryShown(true)}>Все компоненты</MenuItem>
                </MenuList>
              </Menu>
            </GridItem>
          )}
        </Flex>
      )}

      {/* CHEMICAL COMPONENTS DICTIONARY */}
      {isDictionaryShown && (
        <GridItem pt="8px">
          <ComponentsLists
            isClose
            isFavorite
            tableSx={{height: 300}}
            title={'Добавить компонент'}
            defaultComponentType={props.componentBaseType}
            allPureComponents={props.pureList}
            allLumpComponents={props.lumpList}
            selectedPureComponents={pureListSelected}
            selectedLumpComponents={lumpListSelected}
            favoriteLumpComponents={props.favoriteLumpList}
            favoritePureComponents={props.favoritePureList}
            onAddPureComponent={handleAddComponent}
            onAddLumpComponent={handleAddComponent}
            onDeletePureComponent={handleDeleteComponent}
            onDeleteLumpComponent={handleDeleteComponent}
            onClose={() => setIsDictionaryShown(false)}
          />
        </GridItem>
      )}
    </Box>
  );
};

export const FlowCompositionJsField = memo(FlowCompositionJsFieldFC);
