import {FC} from 'react';
import {Box} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';
import {EStructureItem} from '@progress-fe/core';

import {useStore} from 'core/hooks';

import {TaskForm, ElementForms, SettingsForms, ModelForms, ResultForms} from './components';

const ProjectFormsFC: FC = () => {
  const {techProcessStore} = useStore();
  const {selectedElement, selectedModel, selectedResult, projectTask, uiState} = techProcessStore;
  const {entityType} = uiState;

  return (
    <Box p="12px 8px">
      {entityType === EStructureItem.Settings && <SettingsForms />}

      {entityType === EStructureItem.Element && !!selectedElement && (
        <ElementForms
          key={selectedElement.uuid}
          elementDetails={selectedElement}
          onResetCalculatedFields={() => {}}
        />
      )}

      {entityType === EStructureItem.Model && !!selectedModel && (
        <ModelForms key={selectedModel.uuid} modelDetails={selectedModel} />
      )}

      {entityType === EStructureItem.Task && <TaskForm taskInstance={projectTask} />}

      {entityType === EStructureItem.Result && !!selectedResult && (
        <ResultForms key={selectedResult.id} resultDetails={selectedResult} />
      )}
    </Box>
  );
};

export const ProjectForms = observer(ProjectFormsFC);
