/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProjectOut } from './ProjectOut';
import {
    ProjectOutFromJSON,
    ProjectOutFromJSONTyped,
    ProjectOutToJSON,
} from './ProjectOut';

/**
 * 
 * @export
 * @interface PageProjectOut
 */
export interface PageProjectOut {
    /**
     * 
     * @type {Array<ProjectOut>}
     * @memberof PageProjectOut
     */
    items: Array<ProjectOut>;
    /**
     * 
     * @type {number}
     * @memberof PageProjectOut
     */
    total: number | null;
    /**
     * 
     * @type {number}
     * @memberof PageProjectOut
     */
    page: number | null;
    /**
     * 
     * @type {number}
     * @memberof PageProjectOut
     */
    size: number | null;
    /**
     * 
     * @type {number}
     * @memberof PageProjectOut
     */
    pages?: number | null;
}

/**
 * Check if a given object implements the PageProjectOut interface.
 */
export function instanceOfPageProjectOut(value: object): value is PageProjectOut {
    if (!('items' in value) || value['items'] === undefined) return false;
    if (!('total' in value) || value['total'] === undefined) return false;
    if (!('page' in value) || value['page'] === undefined) return false;
    if (!('size' in value) || value['size'] === undefined) return false;
    return true;
}

export function PageProjectOutFromJSON(json: any): PageProjectOut {
    return PageProjectOutFromJSONTyped(json, false);
}

export function PageProjectOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageProjectOut {
    if (json == null) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(ProjectOutFromJSON)),
        'total': json['total'],
        'page': json['page'],
        'size': json['size'],
        'pages': json['pages'] == null ? undefined : json['pages'],
    };
}

export function PageProjectOutToJSON(value?: PageProjectOut | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': ((value['items'] as Array<any>).map(ProjectOutToJSON)),
        'total': value['total'],
        'page': value['page'],
        'size': value['size'],
        'pages': value['pages'],
    };
}

