/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
/**
 *
 * @export
 * @interface RJSFSchemas
 */
export interface RJSFSchemas {
  /**
   *
   * @type {string}
   * @memberof RJSFSchemas
   */
  tabName: string;
  /**
   *
   * @type {object}
   * @memberof RJSFSchemas
   */
  jsonSchema: object;
  /**
   *
   * @type {object}
   * @memberof RJSFSchemas
   */
  ui: object;
  /**
   *
   * @type {object}
   * @memberof RJSFSchemas
   */
  data: object;
}

/**
 * Check if a given object implements the RJSFSchemas interface.
 */
export function instanceOfRJSFSchemas(value: object): value is RJSFSchemas {
  if (!('tabName' in value) || value['tabName'] === undefined) return false;
  if (!('jsonSchema' in value) || value['jsonSchema'] === undefined) return false;
  if (!('ui' in value) || value['ui'] === undefined) return false;
  if (!('data' in value) || value['data'] === undefined) return false;
  return true;
}

export function RJSFSchemasFromJSON(json: any): RJSFSchemas {
  return RJSFSchemasFromJSONTyped(json, false);
}

export function RJSFSchemasFromJSONTyped(json: any, ignoreDiscriminator: boolean): RJSFSchemas {
  if (json == null) {
    return json;
  }
  return {
    tabName: json['tab_name'],
    jsonSchema: json['json_schema'],
    ui: json['ui'],
    data: json['data']
  };
}

export function RJSFSchemasToJSON(value?: RJSFSchemas | null): any {
  if (value == null) {
    return value;
  }
  return {
    tab_name: value['tabName'],
    json_schema: value['jsonSchema'],
    ui: value['ui'],
    data: value['data']
  };
}
