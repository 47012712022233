import {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, IconButton, useToken} from '@chakra-ui/react';
import {Tooltip} from 'react-tooltip';
import {Svg} from '@progress-fe/ui-kit';

interface IProps {
  isDisabled: boolean;
  onRun: () => void;
}

const TOOLTIP_ID = 'PLAY_TOOLTIP_ID';

const PlayIconButtonFC: FC<IProps> = ({isDisabled, onRun}) => {
  const [borderHex] = useToken('colors', ['border']);

  return (
    <>
      <IconButton
        size="sm"
        aria-label=""
        variant="ghost"
        icon={<Svg name="Play" />}
        isDisabled={isDisabled}
        data-tooltip-id={TOOLTIP_ID}
        onClick={onRun}
      />

      <Tooltip
        id={TOOLTIP_ID}
        delayShow={250}
        border={`1px solid ${borderHex}`}
        closeEvents={{click: true, mouseleave: true}}
        style={{padding: '0', backgroundColor: 'white'}}
        opacity="1"
      >
        <Box color="bodyText" p="6px 10px" fontSize="12px">
          Запустить расчёт
        </Box>
      </Tooltip>
    </>
  );
};

export const PlayIconButton = observer(PlayIconButtonFC);
