import {FC} from 'react';
import {Box} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';

import {TProjectEntityJsonSchemasModel} from 'core/models';

import {BlendForms, ProbeForms} from './components';

interface IProps {
  probeInstance: TProjectEntityJsonSchemasModel | null;
  blendInstance: TProjectEntityJsonSchemasModel | null;
  onResetCalculatedFields?: () => void;
}

const SamplesFormsFC: FC<IProps> = ({probeInstance, blendInstance, onResetCalculatedFields}) => {
  return (
    <Box p="12px 8px">
      {!!probeInstance && (
        <ProbeForms
          probeInstance={probeInstance}
          onResetCalculatedFields={onResetCalculatedFields}
        />
      )}

      {!!blendInstance && (
        <BlendForms
          blendInstance={blendInstance}
          onResetCalculatedFields={onResetCalculatedFields}
        />
      )}
    </Box>
  );
};

export const SamplesForms = observer(SamplesFormsFC);
