import {ProjectOut, CalculationsResultEnum, ProjectTypeEnum} from 'api';

export const PROJECT_LIST: ProjectOut[] = [
  {
    uuid: '47CB2F0C-0000-0000-0000-000000000001',
    name: 'Схема с сепараторами',
    type: ProjectTypeEnum.TechProcess,
    updatedAt: new Date(),
    isFavorite: false,
    deleted: false,
    checkpoints: [
      {
        uuid: 'c9f0c5e6-4ec5-4edf-8ed1-9b66341b1eb3',
        calcStatus: CalculationsResultEnum.NoCalcResult,
        lastSavingDt: new Date()
      }
    ]
  },
  {
    uuid: '47CB2F0C-0000-0000-0000-000000000002',
    name: 'Схема с УЗК',
    type: ProjectTypeEnum.TechProcess,
    updatedAt: new Date(),
    isFavorite: false,
    deleted: false,
    checkpoints: [
      {
        uuid: 'c9f0c5e6-4ec5-4edf-8ed1-9b66341b1eb3',
        calcStatus: CalculationsResultEnum.NoCalcResult,
        lastSavingDt: new Date()
      }
    ]
  }
];
