import {FC, memo} from 'react';
import {Center} from '@chakra-ui/react';

interface IProps {
  withDots?: boolean;
}

const FlexDividerFC: FC<IProps> = ({withDots}) => {
  return (
    <Center
      h="8px"
      fontSize="8px"
      lineHeight="8px"
      letterSpacing="2px"
      fontWeight={700}
      borderTop="1px"
      borderBottom="1px"
      borderColor="border"
    >
      {withDots && <>•••</>}
    </Center>
  );
};

export const FlexDivider = memo(FlexDividerFC);
