/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ElementGroup,
  HTTPValidationError,
  Model,
  PureComponent,
  PureComponentClassEnum,
} from '../models/index';
import {
    ElementGroupFromJSON,
    ElementGroupToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    ModelFromJSON,
    ModelToJSON,
    PureComponentFromJSON,
    PureComponentToJSON,
    PureComponentClassEnumFromJSON,
    PureComponentClassEnumToJSON,
} from '../models/index';

export interface CatalogsGetPureComponentsCatalogRequest {
    name?: string | null;
    _class?: PureComponentClassEnum | null;
}

/**
 * 
 */
export class CatalogsApi extends runtime.BaseAPI {

    /**
     * Get Elements Catalog
     */
    async catalogsGetElementsCatalogRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ElementGroup>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OpenIdConnect", []);
        }

        const response = await this.request({
            path: `/api/public/v1/catalog/elements`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ElementGroupFromJSON));
    }

    /**
     * Get Elements Catalog
     */
    async catalogsGetElementsCatalog(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ElementGroup>> {
        const response = await this.catalogsGetElementsCatalogRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get Models Catalog
     */
    async catalogsGetModelsCatalogRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Model>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OpenIdConnect", []);
        }

        const response = await this.request({
            path: `/api/public/v1/catalog/models`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ModelFromJSON));
    }

    /**
     * Get Models Catalog
     */
    async catalogsGetModelsCatalog(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Model>> {
        const response = await this.catalogsGetModelsCatalogRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get Pure Components Catalog
     */
    async catalogsGetPureComponentsCatalogRaw(requestParameters: CatalogsGetPureComponentsCatalogRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PureComponent>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['_class'] != null) {
            queryParameters['class_'] = requestParameters['_class'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OpenIdConnect", []);
        }

        const response = await this.request({
            path: `/api/public/v1/catalog/pure-components`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PureComponentFromJSON));
    }

    /**
     * Get Pure Components Catalog
     */
    async catalogsGetPureComponentsCatalog(requestParameters: CatalogsGetPureComponentsCatalogRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PureComponent>> {
        const response = await this.catalogsGetPureComponentsCatalogRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
