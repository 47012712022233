/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ElementClassName } from './ElementClassName';
import {
    ElementClassNameFromJSON,
    ElementClassNameFromJSONTyped,
    ElementClassNameToJSON,
} from './ElementClassName';

/**
 * 
 * @export
 * @interface NewElement
 */
export interface NewElement {
    /**
     * 
     * @type {ElementClassName}
     * @memberof NewElement
     */
    type: ElementClassName;
}

/**
 * Check if a given object implements the NewElement interface.
 */
export function instanceOfNewElement(value: object): value is NewElement {
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function NewElementFromJSON(json: any): NewElement {
    return NewElementFromJSONTyped(json, false);
}

export function NewElementFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewElement {
    if (json == null) {
        return json;
    }
    return {
        
        'type': ElementClassNameFromJSON(json['type']),
    };
}

export function NewElementToJSON(value?: NewElement | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': ElementClassNameToJSON(value['type']),
    };
}

