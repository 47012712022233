import {FC, memo, ReactElement, useEffect, useState} from 'react';
import {
  Input as ChakraInput,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightAddon
} from '@chakra-ui/react';

interface IProps extends Omit<InputProps, 'onChange' | 'onBlur'> {
  isOnChangeOnlyOnBlur?: boolean;
  disabled?: boolean;
  leftElement?: ReactElement | string;
  rightElement?: ReactElement | string;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
}

const InputFC: FC<IProps> = ({
  isOnChangeOnlyOnBlur,
  value,
  size,
  disabled,
  leftElement,
  rightElement,
  onChange,
  onBlur,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setInputValue(value?.toString() || '');
  }, [value]);

  return (
    <InputGroup size={size}>
      {!!leftElement && <InputLeftElement>{leftElement}</InputLeftElement>}

      <ChakraInput
        {...rest}
        value={inputValue}
        disabled={disabled}
        onChange={(event) => {
          setInputValue(event.target.value);
          if (!isOnChangeOnlyOnBlur) {
            onChange?.(event.target.value);
          }
        }}
        onBlur={(event) => {
          onChange?.(event.target.value);
          onBlur?.(event.target.value);
        }}
      />

      {!!rightElement && (
        <InputRightAddon
          color="lightGray"
          justifyContent="flex-end"
          bg={disabled ? 'disabledGray' : 'white'}
        >
          {rightElement}
        </InputRightAddon>
      )}
    </InputGroup>
  );
};

export const Input = memo(InputFC);
