import {Instance, SnapshotIn, types} from 'mobx-state-tree';

const User = types
  .model('User', {
    id: types.string,
    email: types.string,
    name: types.string
  })
  .actions(() => ({}))
  .views(() => ({}));

export type TUserModel = Instance<typeof User>;

export type TUserSnapshotIn = SnapshotIn<typeof User>;

export {User};
