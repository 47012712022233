import {RJSFSchema, UiSchema} from '@rjsf/utils';

export const UI_SCHEMA_FLOW7_COMPOSITION: UiSchema = {
  composition: {
    flow7_composition_basis: {
      'ui:field': '/schemas/jsf-select'
    },
    flow7_composition_molar: {
      'ui:field': '/schemas/jsf-flow-composition'
    }
  }
};

export const SCHEMA_FLOW7_COMPOSITION: RJSFSchema = {
  type: 'object',
  properties: {
    composition: {
      title: 'Состав потока',
      type: 'object',
      required: ['units'],
      properties: {
        flow7_composition_basis: {
          title: 'Единицы измерения',
          type: 'string',
          oneOf: [
            {const: 'molar', title: 'Мольные доли', readOnly: true},
            {const: 'mass', title: 'Массовые доли', readOnly: true},
            {const: 'volume', title: 'Объемные доли', readOnly: true},
            {const: 'COMP_UNIT_4', title: 'Мольный расход', readOnly: true},
            {const: 'COMP_UNIT_6', title: 'Массовый расход', readOnly: true},
            {const: 'COMP_UNIT_7', title: 'Объемный расход', readOnly: true}
          ],
          readOnly: true
        },
        flow7_composition_molar: {
          title: 'Массовые доли компонентов',
          type: 'object',
          readOnly: true
        }
      }
    }
  }
};

export const FORM_DATA_FLOW7_COMPOSITION = {
  composition: {
    flow7_composition_basis: 'molar',
    flow7_composition_molar: {
      fieldConfig: {
        title: 'Единицы измерения',
        options: [
          {value: 'molar', label: 'Мольные доли'},
          {value: 'mass', label: 'Массовые доли'},
          {value: 'volume', label: 'Объемные доли', readonly: true}
        ]
      },
      fieldValues: {
        optionValue: 'molar',
        value: {
          h2o: '0.6666666667',
          methane: '0.3333333333'
        }
      }
    }
  }
};

export const REVERT_FORM_DATA = {};
