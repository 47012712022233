import {RJSFSchema, UiSchema} from '@rjsf/utils';

export const UI_SCHEMA_COKER_CHARACTERISTICS: UiSchema = {
  coking_chamber: {
    drume_volume: {
      'ui:field': '/schemas/jsf-input'
    },
    time_filling: {
      'ui:field': '/schemas/jsf-input'
    }
  }
};

export const SCHEMA_COKER_CHARACTERISTICS: RJSFSchema = {
  type: 'object',
  properties: {
    coking_chamber: {
      title: 'Камера коксования',
      type: 'object',
      required: ['drume_volume', 'time_filling'],
      properties: {
        drume_volume: {
          title: 'Объём к заполнению',
          type: ['number', 'null'],
          exclusiveMinimum: 0,
          $comment: 'м³'
        },
        time_filling: {
          title: 'Расчётное время заполнения',
          type: ['number', 'null'],
          exclusiveMinimum: 0,
          $comment: 'ч'
        }
      }
    }
  }
};

export const FORM_DATA_COKER_CHARACTERISTICS = {
  coking_chamber: {
    drume_volume: null,
    time_filling: null
  }
};

export const REVERT_FORM_DATA = {};
