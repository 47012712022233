import {RJSFSchema, UiSchema} from '@rjsf/utils';

export const UI_SCHEMA_SAMPLE_1A_MODEL: UiSchema = {
  model: {
    sample1_model_probe_ranges: {
      'ui:field': '/schemas/jsf-probe-model-table'
    }
  }
};

export const SCHEMA_SAMPLE_1A_MODEL: RJSFSchema = {
  type: 'object',
  properties: {
    model: {
      title: 'Модель расчета',
      type: 'object',
      required: [],
      properties: {
        sample1_model_probe_ranges: {
          type: 'object',
          $comment: '°C'
        }
      }
    }
  }
};

export const FORM_DATA_SAMPLE_1A_MODEL = {
  model: {
    sample1_model_probe_ranges: {
      fieldConfig: {
        title: 'Модель',
        options: [{value: 'default', label: 'По умолчанию'}],
        min: {
          title: 'Темп. низа',
          name: 'min',
          value: -273
        },
        max: {
          title: 'Темп. верха',
          name: 'max',
          value: 1200
        },
        fields: [
          {
            title: 'Молекулярная масса',
            name: 'weight',
            options: [
              {value: '1', label: 'Формула 1'},
              {value: '2', label: 'Формула 2'}
            ]
          },
          {
            title: 'Плотность',
            name: 'density',
            options: [
              {value: '1', label: 'Формула 1'},
              {value: '2', label: 'Формула 2'}
            ]
          },
          {
            title: 'Крит. температура',
            name: 'сritical_temperature',
            options: [
              {value: '1', label: 'Формула 1'},
              {value: '2', label: 'Формула 2'}
            ]
          },
          {
            title: 'Крит. давление',
            name: 'сritical_pressure',
            options: [
              {value: '1', label: 'Формула 1'},
              {value: '2', label: 'Формула 2'}
            ]
          },
          {
            title: 'Ацентрический фактор',
            name: 'accent_factor',
            options: [
              {value: '1', label: 'Формула 1'},
              {value: '2', label: 'Формула 2'}
            ]
          },
          {
            title: 'Вязкость',
            name: 'viscosity',
            options: [
              {value: '1', label: 'Формула 1'},
              {value: '2', label: 'Формула 2'}
            ]
          }
        ]
      },
      fieldValues: {
        optionValue: 'default',
        value: [
          {
            min: '-273',
            max: '500',
            weight: '1',
            density: '2',
            сritical_temperature: '1',
            сritical_pressure: '2',
            accent_factor: '1',
            viscosity: '2'
          },
          {
            min: '500',
            max: '1200',
            weight: '2',
            density: '1',
            сritical_temperature: '2',
            сritical_pressure: '1',
            accent_factor: '2',
            viscosity: '1'
          }
        ]
      }
    },
    sample1_model_oil_blend_ranges: {
      fieldConfig: {
        title: 'Модель',
        options: [{value: 'default', label: 'По умолчанию'}],
        min: {
          title: 'Темп. низа',
          name: 'min',
          value: -273
        },
        max: {
          title: 'Темп. верха',
          name: 'max',
          value: 1200
        },
        fields: [
          {
            title: 'Молекулярная масса',
            name: 'weight'
          }
        ]
      },
      fieldValues: {
        optionValue: 'default',
        value: [
          {
            min: '-273',
            max: '500',
            weight: '1'
          },
          {
            min: '500',
            max: '1200',
            weight: '2'
          }
        ]
      }
    }
  }
};
