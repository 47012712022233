import {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {Grid, Text, Box, Flex, Center} from '@chakra-ui/react';
import {useNavigate} from 'react-router';
import {getTimeAgo} from '@progress-fe/core';

import {ProjectOut} from 'api';
import {getProjectUrl} from 'core/utils';

import {ProjectCategryBadge} from '../ProjectCategoryTag';

interface IProps {
  projects: ProjectOut[];
}

const ProjectListFC: FC<IProps> = ({projects}) => {
  const navigate = useNavigate();

  return (
    <Box w="100%">
      <Grid gridTemplateColumns={'1fr 200px 200px 200px'} p="0 24px">
        {['Название', 'Категория', 'Изменён', 'Активность'].map((column) => (
          <Flex key={column} align="center" h="48px">
            <Text fontWeight="600" fontSize="14px">
              {column}
            </Text>
          </Flex>
        ))}
      </Grid>

      <Box overflowY="auto" maxHeight="calc(var(--height-layout) - 250px)">
        {projects.map(({uuid, name, updatedAt, type}) => (
          <Grid
            key={name}
            p="0 24px"
            bg="white"
            borderBottom="1px solid"
            borderColor="border"
            gridTemplateColumns={'minmax(200px, 1fr) 200px 200px 200px'}
            onClick={() => navigate(getProjectUrl(type, uuid))}
            _last={{borderBottom: 'none'}}
            _hover={{opacity: '0.6', cursor: 'pointer'}}
          >
            <Flex align="center" h="64px">
              <Text fontWeight="600" fontSize="14px">
                {name}
              </Text>
            </Flex>
            <Flex align="center" h="64px">
              <ProjectCategryBadge type={type} />
            </Flex>
            <Flex align="center" h="64px">
              <Text fontSize="14px">{getTimeAgo(updatedAt.toISOString())}</Text>
            </Flex>
            <Flex align="center" h="64px">
              <Text fontSize="14px">Автор ???</Text>
            </Flex>
          </Grid>
        ))}
      </Box>

      {projects.length === 0 && (
        <Center textAlign="center" h="64px" bg="white" fontSize="14px">
          Проекты не найдены
        </Center>
      )}
    </Box>
  );
};

export const ProjectList = observer(ProjectListFC);
