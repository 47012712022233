import {RJSFSchema, UiSchema} from '@rjsf/utils';

export const UI_SCHEMA_PR_FLOW_MODEL_BINARY_COEFFICIENTS: UiSchema = {
  binary_coeffs: {
    flow2_binary_coeffs: {
      'ui:field': '/schemas/jsf-binary-coeffs'
    }
  }
};

export const SCHEMA_PR_FLOW_MODEL_BINARY_COEFFICIENTS: RJSFSchema = {
  type: 'object',
  properties: {
    binary_coeffs: {
      title: 'Бинарные коэффициенты',
      type: 'object',
      required: [],
      properties: {
        flow2_binary_coeffs: {
          title: 'Коэффициенты',
          type: 'object'
        }
      }
    }
  }
};

export const FORM_DATA_PR_FLOW_MODEL_BINARY_COEFFICIENTS = {
  binary_coeffs: {
    flow2_binary_coeffs: {
      methane: {
        methane: '1',
        ethane: '0.1',
        propane: '0.1',
        'i-butane': '0.1',
        h2o: '0.1'
      },
      ethane: {
        methane: '0.2',
        ethane: '1',
        propane: '0.2',
        'i-butane': '0.2',
        h2o: '0.2'
      },
      propane: {
        methane: '0.3',
        ethane: '0.3',
        propane: '1',
        'i-butane': '0.3',
        h2o: '0.3'
      },
      'i-butane': {
        methane: '0.4',
        ethane: '0.4',
        propane: '0.4',
        'i-butane': '1',
        h2o: '0.4'
      },
      h2o: {
        methane: '0.5',
        ethane: '0.5',
        propane: '0.5',
        'i-butane': '0.5',
        h2o: '1'
      }
    }
  }
};
