import {RJSFSchema, UiSchema} from '@rjsf/utils';

import {EResultRepresentationType} from 'core/enums';
import {EElementResultType} from 'core/enums';

export const UI_SCHEMA: UiSchema = {
  basic: {
    name: {
      'ui:field': '/schemas/jsf-input'
    },
    resultRepresentationType: {
      'ui:field': '/schemas/jsf-select'
    },
    resultType: {
      'ui:field': '/schemas/jsf-select'
    },
    elementName: {
      'ui:field': '/schemas/jsf-select-arrow'
    }
  }
};

export const SCHEMA: RJSFSchema = {
  type: 'object',
  properties: {
    basic: {
      title: 'Свойства',
      type: 'object',
      required: ['name', 'resultRepresentationType', 'resultType', 'elementName'],
      properties: {
        name: {
          title: 'Имя',
          type: 'string',
          minLength: 1
        },
        resultRepresentationType: {
          title: 'Формат результата',
          type: 'string',
          oneOf: [
            {
              const: EResultRepresentationType.CONNECTED_FLOWS_DATA,
              title: 'Состояние связанных потоков'
            }
          ],
          readOnly: true
        },
        resultType: {
          title: 'Тип результата',
          type: 'string',
          oneOf: [
            {const: EElementResultType.Separator_2ph_Threads, title: 'Сепаратор 2-фазный: потоки'}
          ],
          readOnly: true
        },
        elementName: {
          title: 'Имя элемента',
          type: 'string',
          oneOf: [{const: '196C89E0-0000-0000-0000-000000000001', title: 'V-100'}],
          readOnly: true
        }
      }
    }
  }
};

export const FORM_DATA = {
  basic: {
    name: 'V-100 [Потоки]',
    resultRepresentationType: EResultRepresentationType.CONNECTED_FLOWS_DATA,
    resultType: EElementResultType.Separator_2ph_Threads,
    elementName: '196C89E0-0000-0000-0000-000000000001'
  }
};
