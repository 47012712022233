import {RJSFSchema, UiSchema} from '@rjsf/utils';

export const UI_SCHEMA_FLOW6_CHARACTERISTICS: UiSchema = {
  characteristics: {
    flow6_vapor_phase: {
      'ui:field': '/schemas/jsf-input'
    },
    flow6_temperature: {
      'ui:field': '/schemas/jsf-input'
    },
    flow6_pressure: {
      'ui:field': '/schemas/jsf-input'
    },
    flow6_molar_flow: {
      'ui:field': '/schemas/jsf-input'
    },
    flow6_mass_flow_rate: {
      'ui:field': '/schemas/jsf-input'
    },
    flow6_volume_flow: {
      'ui:field': '/schemas/jsf-input'
    },
    flow6_molar_enthalpy: {
      'ui:field': '/schemas/jsf-input'
    }
  }
};

export const SCHEMA_FLOW6_CHARACTERISTICS: RJSFSchema = {
  type: 'object',
  properties: {
    characteristics: {
      title: 'Характеристики',
      type: 'object',
      required: ['flow6_temperature', 'flow6_pressure', 'flow6_mass_flow_rate'],
      properties: {
        flow6_vapor_phase: {
          title: 'Паровая фаза',
          type: ['number', 'null'],
          minimum: 0,
          maximum: 1,
          readOnly: true
        },
        flow6_temperature: {
          title: 'Температура',
          type: ['number', 'null'],
          minimum: -273.15,
          maximum: 1000,
          readOnly: true,
          $comment: '°C'
        },
        flow6_pressure: {
          title: 'Давление',
          type: ['number', 'null'],
          exclusiveMinimum: 0,
          maximum: 100000,
          readOnly: true,
          $comment: 'кПа (абс.)'
        },
        flow6_molar_flow: {
          title: 'Мольный расход',
          type: ['number', 'null'],
          exclusiveMinimum: 0,
          readOnly: true,
          $comment: 'кмоль/ч'
        },
        flow6_mass_flow_rate: {
          title: 'Массовый расход',
          type: ['number', 'null'],
          exclusiveMinimum: 0,
          readOnly: true,
          $comment: 'кг/ч'
        },
        flow6_volume_flow: {
          title: 'Объемный расход идеально газа',
          type: ['number', 'null'],
          exclusiveMinimum: 0,
          readOnly: true,
          $comment: 'стд.м³/ч'
        },
        flow6_molar_enthalpy: {
          title: 'Мольная энтальпия',
          type: ['number', 'null'],
          readOnly: true,
          $comment: 'кДж/моль'
        }
      }
    }
  }
};

export const FORM_DATA_FLOW6_CHARACTERISTICS = {
  characteristics: {
    flow6_vapor_phase: null,
    flow6_temperature: null,
    flow6_pressure: null,
    flow6_molar_flow: null,
    flow6_mass_flow_rate: null,
    flow6_volume_flow: null,
    flow6_molar_enthalpy: null
  }
};

export const REVERT_FORM_DATA = {
  characteristics: {
    flow6_vapor_phase: null,
    flow6_temperature: null,
    flow6_pressure: null,
    flow6_molar_flow: null,
    flow6_mass_flow_rate: null,
    flow6_volume_flow: null,
    flow6_molar_enthalpy: null
  }
};
