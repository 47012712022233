import {FC, memo} from 'react';
import {Box, Flex, Text} from '@chakra-ui/react';

import {SvgMenu} from '../../menu';
import {ISvgMenuItem} from '../../../interfaces';

interface IProps {
  title: string;
  menuItems?: ISvgMenuItem<string>[];
  onMenuSelect?: (value: string) => void;
}

const ItemListHeaderFC: FC<IProps> = ({title, menuItems, onMenuSelect}) => {
  return (
    <Flex justify="space-between" align="center" p="0 8px 0 0">
      <Text fontSize="md" fontWeight={700} p="8px" color="black">
        {title}
      </Text>
      {!!menuItems && (
        <Box>
          <SvgMenu
            menuSize={'normal'}
            menuIcon={'Plus'}
            items={menuItems}
            onSelect={(value) => onMenuSelect?.(value as string)}
          />
        </Box>
      )}
    </Flex>
  );
};

export const ItemListHeader = memo(ItemListHeaderFC);
