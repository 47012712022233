import {RJSFSchema, UiSchema} from '@rjsf/utils';

export const UI_SCHEMA_COKER_MODEL_SETTINGS: UiSchema = {
  saturates_predexps: {
    r1: {
      'ui:field': '/schemas/jsf-input'
    },
    r2: {
      'ui:field': '/schemas/jsf-input'
    },
    r3: {
      'ui:field': '/schemas/jsf-input'
    },
    r4: {
      'ui:field': '/schemas/jsf-input'
    },
    r5: {
      'ui:field': '/schemas/jsf-input'
    },
    r6: {
      'ui:field': '/schemas/jsf-input'
    }
  },
  aromatics_predexps: {
    r7: {
      'ui:field': '/schemas/jsf-input'
    },
    r8: {
      'ui:field': '/schemas/jsf-input'
    },
    r9: {
      'ui:field': '/schemas/jsf-input'
    },
    r10: {
      'ui:field': '/schemas/jsf-input'
    },
    r11: {
      'ui:field': '/schemas/jsf-input'
    },
    r12: {
      'ui:field': '/schemas/jsf-input'
    }
  },
  resins_predexps: {
    r13: {
      'ui:field': '/schemas/jsf-input'
    },
    r14: {
      'ui:field': '/schemas/jsf-input'
    },
    r15: {
      'ui:field': '/schemas/jsf-input'
    },
    r16: {
      'ui:field': '/schemas/jsf-input'
    },
    r17: {
      'ui:field': '/schemas/jsf-input'
    },
    r18: {
      'ui:field': '/schemas/jsf-input'
    }
  },
  asphaltenes_predexps: {
    r19: {
      'ui:field': '/schemas/jsf-input'
    },
    r20: {
      'ui:field': '/schemas/jsf-input'
    },
    r21: {
      'ui:field': '/schemas/jsf-input'
    },
    r22: {
      'ui:field': '/schemas/jsf-input'
    },
    r23: {
      'ui:field': '/schemas/jsf-input'
    },
    r24: {
      'ui:field': '/schemas/jsf-input'
    }
  },
  saturates_activation_energies: {
    r1: {
      'ui:field': '/schemas/jsf-input'
    },
    r2: {
      'ui:field': '/schemas/jsf-input'
    },
    r3: {
      'ui:field': '/schemas/jsf-input'
    },
    r4: {
      'ui:field': '/schemas/jsf-input'
    },
    r5: {
      'ui:field': '/schemas/jsf-input'
    },
    r6: {
      'ui:field': '/schemas/jsf-input'
    }
  },
  aromatics_activation_energies: {
    r7: {
      'ui:field': '/schemas/jsf-input'
    },
    r8: {
      'ui:field': '/schemas/jsf-input'
    },
    r9: {
      'ui:field': '/schemas/jsf-input'
    },
    r10: {
      'ui:field': '/schemas/jsf-input'
    },
    r11: {
      'ui:field': '/schemas/jsf-input'
    },
    r12: {
      'ui:field': '/schemas/jsf-input'
    }
  },
  resins_activation_energies: {
    r13: {
      'ui:field': '/schemas/jsf-input'
    },
    r14: {
      'ui:field': '/schemas/jsf-input'
    },
    r15: {
      'ui:field': '/schemas/jsf-input'
    },
    r16: {
      'ui:field': '/schemas/jsf-input'
    },
    r17: {
      'ui:field': '/schemas/jsf-input'
    },
    r18: {
      'ui:field': '/schemas/jsf-input'
    }
  },
  asphaltenes_activation_energies: {
    r19: {
      'ui:field': '/schemas/jsf-input'
    },
    r20: {
      'ui:field': '/schemas/jsf-input'
    },
    r21: {
      'ui:field': '/schemas/jsf-input'
    },
    r22: {
      'ui:field': '/schemas/jsf-input'
    },
    r23: {
      'ui:field': '/schemas/jsf-input'
    },
    r24: {
      'ui:field': '/schemas/jsf-input'
    }
  }
};

export const SCHEMA_COKER_MODEL_SETTINGS: RJSFSchema = {
  type: 'object',
  properties: {
    saturates_predexps: {
      title: 'Предэкспоненциальные множители для Парафинов (Saturates)',
      type: 'object',
      required: ['r1', 'r2', 'r3', 'r4', 'r5', 'r6'],
      properties: {
        r1: {
          title: 'Saturates to FG',
          type: 'number',
          exclusiveMinimum: 0
        },
        r2: {
          title: 'Saturates to LPG',
          type: 'number',
          exclusiveMinimum: 0
        },
        r3: {
          title: 'Saturates to Naphtha',
          type: 'number',
          exclusiveMinimum: 0
        },
        r4: {
          title: 'Saturates to Light Gasoil',
          type: 'number',
          exclusiveMinimum: 0
        },
        r5: {
          title: 'Saturates to Heavy Gasoil',
          type: 'number',
          exclusiveMinimum: 0
        },
        r6: {
          title: 'Saturates to Coke',
          type: 'number',
          exclusiveMinimum: 0
        }
      }
    },
    aromatics_predexps: {
      title: 'Предэкспоненциальные множители для Ароматики (Aromatics)',
      type: 'object',
      required: ['r7', 'r8', 'r9', 'r10', 'r11', 'r12'],
      properties: {
        r7: {
          title: 'Aromatics to FG',
          type: 'number',
          exclusiveMinimum: 0
        },
        r8: {
          title: 'Aromatics to LPG',
          type: 'number',
          exclusiveMinimum: 0
        },
        r9: {
          title: 'Aromatics to Naphtha',
          type: 'number',
          exclusiveMinimum: 0
        },
        r10: {
          title: 'Aromatics to Light Gasoil',
          type: 'number',
          exclusiveMinimum: 0
        },
        r11: {
          title: 'Aromatics to Heavy Gasoil',
          type: 'number',
          exclusiveMinimum: 0
        },
        r12: {
          title: 'Aromatics to Coke',
          type: 'number',
          exclusiveMinimum: 0
        }
      }
    },
    resins_predexps: {
      title: 'Предэкспоненциальные множители для Смол (Resins)',
      type: 'object',
      required: ['r13', 'r14', 'r15', 'r16', 'r17', 'r18'],
      properties: {
        r13: {
          title: 'Resins to FG',
          type: 'number',
          exclusiveMinimum: 0
        },
        r14: {
          title: 'Resins to LPG',
          type: 'number',
          exclusiveMinimum: 0
        },
        r15: {
          title: 'Resins to Naphtha',
          type: 'number',
          exclusiveMinimum: 0
        },
        r16: {
          title: 'Resins to Light Gasoil',
          type: 'number',
          exclusiveMinimum: 0
        },
        r17: {
          title: 'Resins to Heavy Gasoil',
          type: 'number',
          exclusiveMinimum: 0
        },
        r18: {
          title: 'Resins to Coke',
          type: 'number',
          exclusiveMinimum: 0
        }
      }
    },
    asphaltenes_predexps: {
      title: 'Предэкспоненциальные множители для Асфальтенов (Asphaltenes)',
      type: 'object',
      required: ['r19', 'r20', 'r21', 'r22', 'r23', 'r24'],
      properties: {
        r19: {
          title: 'Asphaltenes to FG',
          type: 'number',
          exclusiveMinimum: 0
        },
        r20: {
          title: 'Asphaltenes to LPG',
          type: 'number',
          exclusiveMinimum: 0
        },
        r21: {
          title: 'Asphaltenes to Naphtha',
          type: 'number',
          exclusiveMinimum: 0
        },
        r22: {
          title: 'Asphaltenes to Light Gasoil',
          type: 'number',
          exclusiveMinimum: 0
        },
        r23: {
          title: 'Asphaltenes to Heavy Gasoil',
          type: 'number',
          exclusiveMinimum: 0
        },
        r24: {
          title: 'Asphaltenes to Coke',
          type: 'number',
          exclusiveMinimum: 0
        }
      }
    },
    saturates_activation_energies: {
      title: 'Энергия активации для Парафинов (Saturates)',
      type: 'object',
      required: ['r1', 'r2', 'r3', 'r4', 'r5', 'r6'],
      properties: {
        r1: {
          title: 'Saturates to FG',
          type: 'number',
          exclusiveMinimum: 0
        },
        r2: {
          title: 'Saturates to LPG',
          type: 'number',
          exclusiveMinimum: 0
        },
        r3: {
          title: 'Saturates to Naphtha',
          type: 'number',
          exclusiveMinimum: 0
        },
        r4: {
          title: 'Saturates to Light Gasoil',
          type: 'number',
          exclusiveMinimum: 0
        },
        r5: {
          title: 'Saturates to Heavy Gasoil',
          type: 'number',
          exclusiveMinimum: 0
        },
        r6: {
          title: 'Saturates to Coke',
          type: 'number',
          exclusiveMinimum: 0
        }
      }
    },
    aromatics_activation_energies: {
      title: 'Энергия активации для Ароматики (Aromatics)',
      type: 'object',
      required: ['r7', 'r8', 'r9', 'r10', 'r11', 'r12'],
      properties: {
        r7: {
          title: 'Aromatics to FG',
          type: 'number',
          exclusiveMinimum: 0
        },
        r8: {
          title: 'Aromatics to LPG',
          type: 'number',
          exclusiveMinimum: 0
        },
        r9: {
          title: 'Aromatics to Naphtha',
          type: 'number',
          exclusiveMinimum: 0
        },
        r10: {
          title: 'Aromatics to Light Gasoil',
          type: 'number',
          exclusiveMinimum: 0
        },
        r11: {
          title: 'Aromatics to Heavy Gasoil',
          type: 'number',
          exclusiveMinimum: 0
        },
        r12: {
          title: 'Aromatics to Coke',
          type: 'number',
          exclusiveMinimum: 0
        }
      }
    },
    resins_activation_energies: {
      title: 'Энергия активации для Смол (Resins)',
      type: 'object',
      required: ['r13', 'r14', 'r15', 'r16', 'r17', 'r18'],
      properties: {
        r13: {
          title: 'Resins to FG',
          type: 'number',
          exclusiveMinimum: 0
        },
        r14: {
          title: 'Resins to LPG',
          type: 'number',
          exclusiveMinimum: 0
        },
        r15: {
          title: 'Resins to Naphtha',
          type: 'number',
          exclusiveMinimum: 0
        },
        r16: {
          title: 'Resins to Light Gasoil',
          type: 'number',
          exclusiveMinimum: 0
        },
        r17: {
          title: 'Resins to Heavy Gasoil',
          type: 'number',
          exclusiveMinimum: 0
        },
        r18: {
          title: 'Resins to Coke',
          type: 'number',
          exclusiveMinimum: 0
        }
      }
    },
    asphaltenes_activation_energies: {
      title: 'Энергия активации для Асфальтенов (Asphaltenes)',
      type: 'object',
      required: ['r19', 'r20', 'r21', 'r22', 'r23', 'r24'],
      properties: {
        r19: {
          title: 'Asphaltenes to FG',
          type: 'number',
          exclusiveMinimum: 0
        },
        r20: {
          title: 'Asphaltenes to LPG',
          type: 'number',
          exclusiveMinimum: 0
        },
        r21: {
          title: 'Asphaltenes to Naphtha',
          type: 'number',
          exclusiveMinimum: 0
        },
        r22: {
          title: 'Asphaltenes to Light Gasoil',
          type: 'number',
          exclusiveMinimum: 0
        },
        r23: {
          title: 'Asphaltenes to Heavy Gasoil',
          type: 'number',
          exclusiveMinimum: 0
        },
        r24: {
          title: 'Asphaltenes to Coke',
          type: 'number',
          exclusiveMinimum: 0
        }
      }
    }
  }
};

export const FORM_DATA_COKER_MODEL_SETTINGS = {
  saturates_predexps: {
    r1: 1332074.8,
    r2: 1058468.9,
    r3: 5801590.9,
    r4: 3190820.9,
    r5: 39411829.0,
    r6: 1906571.8
  },
  aromatics_predexps: {
    r7: 130434140.0,
    r8: 908725570.0,
    r9: 76908827000.0,
    r10: 30821842000.0,
    r11: 3143760700.0,
    r12: 10814511.0
  },
  resins_predexps: {
    r13: 4756402.6,
    r14: 1543769.0,
    r15: 9982870.2,
    r16: 3716024.6,
    r17: 31855183.0,
    r18: 262355340.0
  },
  asphaltenes_predexps: {
    r19: 1346847.3,
    r20: 91141.741,
    r21: 554555.67,
    r22: 2835890.2,
    r23: 459566.07,
    r24: 6.7296857
  },
  saturates_activation_energies: {
    r1: 122750.0,
    r2: 118050.0,
    r3: 122300.0,
    r4: 119000.0,
    r5: 125050.0,
    r6: 180300.0
  },
  aromatics_activation_energies: {
    r7: 170580.0,
    r8: 173780.0,
    r9: 182550.0,
    r10: 179350.0,
    r11: 191200.0,
    r12: 155520.0
  },
  resins_activation_energies: {
    r13: 139110.0,
    r14: 139600.0,
    r15: 147100.0,
    r16: 134800.0,
    r17: 142030.0,
    r18: 148540.0
  },
  asphaltenes_activation_energies: {
    r19: 87960.0,
    r20: 75210.0,
    r21: 77560.0,
    r22: 79260.0,
    r23: 84300.0,
    r24: 77860.0
  }
};
