import {observer} from 'mobx-react-lite';
import {IJsFormBaseProps, JsForm} from '@progress-fe/ui-kit';

import {useStore} from 'core/hooks';

const JsFormWrapperFC = <T,>(props: IJsFormBaseProps<T>) => {
  const {jsFormConfig} = useStore();

  return <JsForm {...props} config={jsFormConfig} />;
};

export const JsFormWrapper = observer(JsFormWrapperFC);
