import {RJSFSchema, UiSchema} from '@rjsf/utils';

export const UI_SCHEMA_AB72_BASIC: UiSchema = {
  basic: {
    name: {
      'ui:field': '/schemas/jsf-input'
    }
  }
};

export const SCHEMA_AB72_BASIC: RJSFSchema = {
  type: 'object',
  properties: {
    basic: {
      title: 'Базовые свойства',
      type: 'object',
      required: ['name'],
      properties: {
        name: {
          title: 'Имя',
          type: 'string',
          minLength: 1
        }
      }
    }
  }
};

export const FORM_DATA_AB72_BASIC = {
  basic: {
    name: 'AB72'
  }
};
