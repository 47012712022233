import {RJSFSchema, UiSchema} from '@rjsf/utils';

export const UI_SCHEMA_PR_FLOW_MODEL_BINARY_COEFFICIENTS: UiSchema = {
  binary_coeffs: {}
};

export const SCHEMA_PR_FLOW_MODEL_BINARY_COEFFICIENTS: RJSFSchema = {
  type: 'object',
  readOnly: true,
  properties: {
    binary_coeffs: {
      title: 'Бинарные коэффициенты',
      type: 'object',
      required: [],
      properties: {}
    }
  }
};

export const FORM_DATA_PR_FLOW_MODEL_BINARY_COEFFICIENTS = {
  binary_coeffs: {}
};
