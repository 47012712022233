import cn from 'clsx';
import {FC, memo} from 'react';
import {ReactSVG} from 'react-svg';

import * as Icons from './Svg.Icons';
import s from './Svg.module.css';

export type TSvgName = keyof typeof Icons;
export type TSvgSize = 's8' | 's9' | 's12' | 's16' | 's32' | 's48' | 's64';

interface IProps {
  name: TSvgName;
  size?: TSvgSize;
  width?: number;
  height?: number;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
}

const SvgFC: FC<IProps> = ({name, size = 's16', disabled, width, height, onClick, className}) => {
  const Icon = Icons[name];
  return !!onClick ? (
    <button className={cn(s.button, className)} onClick={onClick} disabled={disabled}>
      <ReactSVG
        title=""
        src={Icon}
        className={cn(s[size], className)}
        {...(width && height ? {style: {width: `${width}px`, height: `${height}px`}} : {})}
      />
    </button>
  ) : (
    <ReactSVG
      title=""
      src={Icon}
      className={cn(s[size], className)}
      {...(width && height ? {style: {width: `${width}px`, height: `${height}px`}} : {})}
    />
  );
};

export const Svg = memo(SvgFC);
