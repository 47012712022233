import {cast, flow, Instance, types} from 'mobx-state-tree';
import {IPureComponent} from '@progress-fe/core';

import {CatalogsApi, PureComponent} from 'api';
import {RequestModel, ResetModel} from 'core/models';

const PureComponentsCatalog = types.compose(
  ResetModel,
  types
    .model('PureComponentsCatalog', {
      components: types.optional(types.array(types.frozen<IPureComponent>()), []),
      fetchRequest: types.optional(RequestModel, {})
    })
    .actions((self) => ({
      load: flow(function* () {
        const response: Array<PureComponent> = yield self.fetchRequest.send(
          CatalogsApi.catalogsGetPureComponentsCatalog.bind(CatalogsApi),
          {}
        );

        const components: IPureComponent[] = response.map((c) => ({
          uuid: c.uuid,
          name: c.name,
          formula: c.formula,
          structure: c.uNIFACStructure,
          classType: c._class
        }));

        self.components = cast(components);
      })
    }))
);

export type TPureComponentsCatalogModel = Instance<typeof PureComponentsCatalog>;

export {PureComponentsCatalog};
