/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ProjectTypeEnum = {
    TechProcess: 'TECH_PROCESS',
    SamplesAndOilBlend: 'SAMPLES_AND_OIL_BLEND',
    UnknownDefaultOpenApi: '11184809'
} as const;
export type ProjectTypeEnum = typeof ProjectTypeEnum[keyof typeof ProjectTypeEnum];


export function instanceOfProjectTypeEnum(value: any): boolean {
    for (const key in ProjectTypeEnum) {
        if (Object.prototype.hasOwnProperty.call(ProjectTypeEnum, key)) {
            if ((ProjectTypeEnum as Record<string, ProjectTypeEnum>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ProjectTypeEnumFromJSON(json: any): ProjectTypeEnum {
    return ProjectTypeEnumFromJSONTyped(json, false);
}

export function ProjectTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectTypeEnum {
    return json as ProjectTypeEnum;
}

export function ProjectTypeEnumToJSON(value?: ProjectTypeEnum | null): any {
    return value as any;
}

