import {FC, memo} from 'react';
import {VStack, IconButton, Box} from '@chakra-ui/react';
import cn from 'clsx';
import {useT} from '@progress-fe/core';

import {TProbeModelFormData, TRangeValue} from '../../../../ProbeModelTableFormData.types';
import {Svg} from '../../../../../../helpers';
import {InputNumber, Select} from '../../../../../../inputs';
import {ISelectOption} from '../../../../../../../interfaces';

import s from './ProbeRange.module.css';

interface IProps {
  rangeValue: TRangeValue;
  formData: TProbeModelFormData;
  isFirst: boolean;
  isLast: boolean;
  isReadOnly: boolean;
  onChange: (newValue: TRangeValue) => void;
  onDelete: () => void;
  suffix?: string;
  className?: string;
}

const ProbeRangeFC: FC<IProps> = ({
  rangeValue,
  formData,
  isFirst,
  isLast,
  suffix,
  className,
  isReadOnly,
  onChange,
  onDelete
}) => {
  const {t} = useT();

  const fieldConfig = formData.fieldConfig;

  const handleChanges = (name: string, value?: string | number) => {
    if (value === undefined) {
      return;
    }

    const editedRange = {...rangeValue, [name]: value};
    onChange(editedRange);
  };

  return (
    <VStack
      className={cn(s.rangeColumn, isReadOnly && s.readonly, className)}
      align="stretch"
      justify="center"
      gap="4px"
      flexShrink="0"
    >
      <InputNumber
        size="xs"
        variant="outline"
        sx={{width: '100%'}}
        value={rangeValue[fieldConfig.min.name] || undefined}
        rightElement={suffix ? <>{suffix}</> : undefined}
        max={rangeValue[fieldConfig.max.name]}
        disabled={isFirst || isReadOnly}
        isOnChangeOnlyOnBlur
        onChange={(value) => {
          handleChanges(fieldConfig.min.name, value?.toString());
        }}
      />
      <InputNumber
        size="xs"
        variant="outline"
        sx={{width: '100%'}}
        value={rangeValue[fieldConfig.max.name] || undefined}
        min={rangeValue[fieldConfig.min.name]}
        rightElement={suffix ? <>{suffix}</> : undefined}
        disabled={isLast || isReadOnly}
        isOnChangeOnlyOnBlur
        onChange={(value) => {
          handleChanges(fieldConfig.max.name, value?.toString());
        }}
      />
      {fieldConfig.fields.map((field) => (
        <Select
          key={field.name}
          name={field.name}
          inPortal
          placeholder={t('jsfields.ProbeModelTable.formulesPlaceholder')}
          options={field.options}
          isDisabled={isReadOnly}
          value={field.options.find((opt) => opt.value === rangeValue[field.name])}
          onChange={(selected) => {
            handleChanges(field.name, (selected as ISelectOption<string | number>).value);
          }}
        />
      ))}
      {!isFirst ? (
        <IconButton
          className={s.deleteRangeBtn}
          size="smSquare"
          w="100%"
          aria-label=""
          variant="ghost"
          isDisabled={isReadOnly}
          icon={<Svg size="s12" name="Cross" />}
          onClick={onDelete}
        />
      ) : (
        <Box h="24px" />
      )}
    </VStack>
  );
};

export const ProbeRange = memo(ProbeRangeFC);
