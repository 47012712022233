import {Checkbox as ChakraCheckbox} from '@chakra-ui/react';
import {FC, memo} from 'react';

interface IProps {
  name?: string;
  checked?: boolean;
  isDisabled?: boolean;
  onChange: (value: boolean) => void;
}

const CheckboxFC: FC<IProps> = ({name, checked, isDisabled, onChange}) => {
  return (
    <ChakraCheckbox
      key={`${checked}`}
      size="md"
      name={name}
      checked={checked}
      defaultChecked={checked}
      isDisabled={isDisabled}
      onChange={(event) => onChange(event.target.checked)}
    />
  );
};

export const Checkbox = memo(CheckboxFC);
