import {memo, useState} from 'react';
import {Flex} from '@chakra-ui/react';

import {Input} from '../../../../inputs';
import {VirtualTable} from '../../../../tables';
import {ISimpleTableColumn, ISimpleTableRow} from '../../../../../interfaces';

interface IProps {
  sampleList: Array<{sampleId: string; sampleName: string}>;
  selectedSamplesIdx: string[];
  onAddSample: (sampleId: string) => void;
  onDeleteSample: (sampleId: string) => void;
}

const SampleListDialogFC = ({
  sampleList,
  selectedSamplesIdx,
  onAddSample,
  onDeleteSample
}: IProps) => {
  const [query, setQuery] = useState('');

  const filteredSampleList = sampleList.filter((item) =>
    item.sampleName.toLowerCase().includes(query.toLowerCase())
  );

  const columns: ISimpleTableColumn[] = [{name: 'Название'}];
  const rows: ISimpleTableRow<string>[] = filteredSampleList.map(({sampleId, sampleName}) => ({
    key: sampleId,
    isDisabled: false,
    values: [sampleName],
    isSelected: selectedSamplesIdx.includes(sampleId)
  }));

  return (
    <Flex flexDirection="column" w="220px" gap="12px">
      <Input size="xs" variant="outline" value={query} placeholder="Поиск" onChange={setQuery} />
      <VirtualTable
        isCheckboxShown
        columns={columns}
        rows={rows}
        tableSx={{height: 300}}
        noItemsTitle={'Нет проб'}
        onSelect={(value: string, isSelected) => {
          if (isSelected) {
            onAddSample(value);
          } else {
            onDeleteSample(value);
          }
        }}
      />
    </Flex>
  );
};

export const SampleListDialog = memo(SampleListDialogFC);
