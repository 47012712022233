import {RJSFSchema, UiSchema} from '@rjsf/utils';
import {EElement} from '@progress-fe/core';

export const UI_SCHEMA_FLOW_MODEL_BASIC: UiSchema = {
  basic: {
    name: {
      'ui:field': '/schemas/jsf-input'
    },
    elementType: {
      'ui:field': '/schemas/jsf-select'
    },
    model_type: {
      'ui:field': '/schemas/jsf-select'
    },
    isDefaultModel: {
      'ui:field': '/schemas/jsf-checkbox'
    }
  },
  connected_elements: {}
};

export const SCHEMA_FLOW_MODEL_BASIC: RJSFSchema = {
  type: 'object',
  properties: {
    basic: {
      title: 'Настройка',
      type: 'object',
      required: ['name', 'elementType', 'model_type', 'isDefaultModel'],
      properties: {
        name: {
          title: 'Имя модели',
          type: 'string',
          minLength: 1
        },
        elementType: {
          title: 'Тип элемента',
          type: 'string',
          oneOf: [{const: EElement.MaterialFlowElement, title: 'Материальный поток'}],
          readOnly: true
        },
        model_type: {
          title: 'Тип модели',
          type: 'string',
          oneOf: [{const: 'EModelType.Robinson', title: 'Пенг-Робинсон'}],
          readOnly: true
        },
        isDefaultModel: {
          title: 'Модель по умолчанию',
          type: 'boolean'
        }
      }
    },
    connected_elements: {
      title: 'Связанные элементы',
      type: 'object',
      properties: {}
    }
  }
};

export const FORM_DATA_FLOW_MODEL_BASIC = {
  basic: {
    name: 'Пенг-Робинсон-2',
    elementType: EElement.MaterialFlowElement,
    model_type: 'EModelType.Robinson',
    isDefaultModel: false
  },
  connected_elements: {}
};
