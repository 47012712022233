import {FC, useState} from 'react';
import {Grid} from '@chakra-ui/react';
import {SystemStyleObject} from '@chakra-ui/styled-system';
import {ILumpComponent, IPureComponent} from '@progress-fe/core';

import {Input} from '../../../../inputs';
import {VirtualTable} from '../../../../tables';
import {ISimpleTableColumn, ISimpleTableRow} from '../../../../../interfaces';

interface IProps {
  favoritePureComponents: IPureComponent[];
  favoriteLumpComponents: ILumpComponent[];
  selectedPureComponents: IPureComponent[];
  selectedLumpComponents: ILumpComponent[];
  onAddPureComponent: (value: string) => void;
  onAddLumpComponent: (value: string) => void;
  onDeletePureComponent: (value: string) => void;
  onDeleteLumpComponent: (value: string) => void;
  onAddAllComponents?: () => void;
  onDeleteAllComponents?: () => void;
  tableSx?: SystemStyleObject;
}

const FavoriteComponentsFC: FC<IProps> = ({
  favoritePureComponents,
  favoriteLumpComponents,
  selectedPureComponents,
  selectedLumpComponents,
  onAddPureComponent,
  onAddLumpComponent,
  onDeletePureComponent,
  onDeleteLumpComponent,
  onAddAllComponents,
  onDeleteAllComponents,
  tableSx
}) => {
  const [query, setQuery] = useState('');

  const columns: ISimpleTableColumn[] = [
    {name: 'Название'},
    {name: 'Формула'},
    {name: 'Структура'}
  ];

  const pureRows: ISimpleTableRow<string>[] = favoritePureComponents
    .filter((c) => c.name.toLowerCase().includes(query.toLowerCase()))
    .map((opt) => ({
      key: opt.uuid,
      isDisabled: false,
      values: [opt.name, opt.formula || '—', opt.structure || '—'],
      isSelected:
        selectedPureComponents.map((c) => c.uuid).includes(opt.uuid) ||
        selectedLumpComponents.map((c) => c.uuid).includes(opt.uuid)
    }));

  const lumpRows: ISimpleTableRow<string>[] = favoriteLumpComponents
    .filter((c) => c.name.toLowerCase().includes(query.toLowerCase()))
    .map((opt) => ({
      key: opt.uuid,
      isDisabled: false,
      values: [opt.name, '—', '—'],
      isSelected:
        selectedPureComponents.map((c) => c.uuid).includes(opt.uuid) ||
        selectedLumpComponents.map((c) => c.uuid).includes(opt.uuid)
    }));

  return (
    <Grid templateRows="1fr" gap="8px">
      <Grid gridTemplateColumns="1fr" gap="8px">
        <Input size="xs" variant="outline" value={query} placeholder="Поиск" onChange={setQuery} />
      </Grid>
      <VirtualTable
        isCheckboxShown
        rows={[...pureRows, ...lumpRows]}
        columns={columns}
        noItemsTitle="Нет избранных компонентов"
        tableSx={tableSx}
        onSelectAll={onAddAllComponents}
        onUnselectAll={onDeleteAllComponents}
        onSelect={(value, isSelected) => {
          if (isSelected) {
            if (favoritePureComponents.map((c) => c.uuid).includes(value)) {
              onAddPureComponent(value);
            } else {
              onAddLumpComponent(value);
            }
          } else {
            if (favoritePureComponents.map((c) => c.uuid).includes(value)) {
              onDeletePureComponent(value);
            } else {
              onDeleteLumpComponent(value);
            }
          }
        }}
      />
    </Grid>
  );
};

export const FavoriteComponents = FavoriteComponentsFC;
