import {Instance, SnapshotIn, types} from 'mobx-state-tree';

import {ECalculationResultType} from 'core/enums';

const CalculationResultItem = types
  .model('CalculationResultItem', {
    name: types.string,
    element_id: types.string,
    data: types.frozen<unknown>(),
    result_type: types.optional(
      types.enumeration(Object.values(ECalculationResultType)),
      ECalculationResultType.TABLE
    )
  })
  .actions(() => ({}))
  .views(() => ({}));

export type TCalculationResultItemModel = Instance<typeof CalculationResultItem>;

export type TCalculationResultItemSnapshotIn = SnapshotIn<typeof CalculationResultItem>;

export {CalculationResultItem};
