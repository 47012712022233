import {cast, Instance, SnapshotIn, types} from 'mobx-state-tree';

import {ResetModel} from 'core/models';

/** @deprecated **/
const ChemicalComponents = types
  .compose(
    ResetModel,
    types.model('ChemicalComponents', {
      pureComponentsKeys: types.optional(types.array(types.string), []),
      lumpComponentsKeys: types.optional(types.array(types.string), [])
    })
  )
  .actions((self) => ({
    init(): void {},
    addPureComponent(): void {},
    addAllPureComponents(): void {},
    deletePureComponent(key: string): void {
      self.pureComponentsKeys = cast(self.pureComponentsKeys.filter((c) => c !== key));
    },
    deleteAllPureComponents(): void {
      self.pureComponentsKeys = cast([]);
    },
    addLumpComponent(key: string): void {
      self.lumpComponentsKeys = cast([...self.lumpComponentsKeys, key]);
    },
    addAllLumpComponents(): void {},
    deleteLumpComponent(key: string): void {
      self.lumpComponentsKeys = cast(self.lumpComponentsKeys.filter((c) => c !== key));
    },
    deleteAllLumpComponents(): void {
      self.lumpComponentsKeys = cast([]);
    }
  }));

export type TChemicalComponentsModel = Instance<typeof ChemicalComponents>;

export type TChemicalComponentsSnapshotIn = SnapshotIn<typeof ChemicalComponents>;

export {ChemicalComponents};
