import _ from 'lodash-es';
import {memo, useState} from 'react';
import {FieldProps} from '@rjsf/utils';
import {Box} from '@chakra-ui/react';
import {IPureComponent, ILumpComponent} from '@progress-fe/core';

import {Dialog} from '../../helpers';

import {BinaryCoeffsTable} from './components';

type TFormData = Record<string, Record<string, string | null>>;

interface IProps extends FieldProps {
  pureList: IPureComponent[];
  lumpList: ILumpComponent[];
  onCalcBinaryCoeffs: () => Promise<TFormData>;
}

/**
 * Component renders "/schemas/jsf-binary-coeffs" field of JsonSchema.
 * @param props : field props come from JsonSchema.
 * Value of props.formData is TFormData.
 */
const BinaryCoeffsJsFieldFC = (props: IProps) => {
  const [isDialog, setIsDialog] = useState(false);

  const formData = props.formData as TFormData;

  const handleChangeValue = (rootKey: string, key: string, value: string | null) => {
    const formDataCopy = _.cloneDeep(formData);
    formDataCopy[rootKey][key] = value;
    formDataCopy[key][rootKey] = value;
    props.onChange(formDataCopy);
  };

  const handleCalcBinaryCoeffs = async () => {
    const formDataResult = await props.onCalcBinaryCoeffs();
    props.onChange({...formDataResult});
  };

  const handleToggleDialog = () => {
    setIsDialog(!isDialog);
  };

  return (
    <Box w={isDialog ? '100%' : '423px'}>
      <BinaryCoeffsTable
        isDialog={isDialog}
        formData={formData}
        isReadOnly={props.schema.readOnly}
        lumpList={props.lumpList}
        pureList={props.pureList}
        onChangeValue={handleChangeValue}
        onCalcCoeffs={handleCalcBinaryCoeffs}
        onToggleDialog={handleToggleDialog}
      />

      {isDialog && (
        <Dialog title="Бинарные коэффициенты" onClose={handleToggleDialog}>
          <BinaryCoeffsTable
            isDialog={isDialog}
            formData={formData}
            isReadOnly={props.schema.readOnly}
            lumpList={props.lumpList}
            pureList={props.pureList}
            onChangeValue={handleChangeValue}
            onCalcCoeffs={handleCalcBinaryCoeffs}
            onToggleDialog={handleToggleDialog}
          />
        </Dialog>
      )}
    </Box>
  );
};

export const BinaryCoeffsJsField = memo(BinaryCoeffsJsFieldFC);
