import {RJSFSchema, UiSchema} from '@rjsf/utils';

export const UI_SCHEMA_FLOW1_PROPS: UiSchema = {
  characteristics: {
    feedstock_cocking_ability: {
      'ui:field': '/schemas/jsf-input'
    },
    feedstock_density: {
      'ui:field': '/schemas/jsf-input'
    },
    feedstock_sulfur: {
      'ui:field': '/schemas/jsf-input'
    }
  }
};

export const SCHEMA_FLOW1_PROPS: RJSFSchema = {
  type: 'object',
  properties: {
    characteristics: {
      title: 'Свойства',
      type: 'object',
      required: ['feedstock_cocking_ability', 'feedstock_density'],
      properties: {
        feedstock_cocking_ability: {
          title: 'Коксуемость по Кондратсону',
          type: ['number', 'null'],
          minimum: 0,
          maximum: 100,
          readOnly: true,
          $comment: '% масс.'
        },
        feedstock_density: {
          title: 'Плотность',
          type: ['number', 'null'],
          minimum: 700,
          maximum: 1200,
          $comment: 'кг/м³'
        },
        feedstock_sulfur: {
          title: 'Содержание серы',
          type: ['number', 'null'],
          minimum: 0,
          exclusiveMaximum: 15,
          readOnly: true,
          $comment: '% масс.'
        }
      }
    }
  }
};

export const FORM_DATA_FLOW1_PROPS = {
  characteristics: {
    feedstock_cocking_ability: 23.6,
    feedstock_density: null,
    feedstock_sulfur: 3.1
  }
};

export const REVERT_FORM_DATA = {};
