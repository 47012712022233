import {FC} from 'react';
import {Tooltip} from 'react-tooltip';
import {observer} from 'mobx-react-lite';
import {Box, Flex, IconButton, Text, useToken} from '@chakra-ui/react';
import {Svg} from '@progress-fe/ui-kit';

import {TUserModel} from 'core/models';

interface IProps {
  currentUser: TUserModel;
  onSignOut: () => void;
}

const TOOLTIP_ID = 'USER_TOOLTIP_ID';
const HOVER_TOOLTIP_ID = 'HOVER_USER_TOOLTIP_ID';

const UserIconButtonFC: FC<IProps> = ({currentUser, onSignOut}) => {
  const [borderHex] = useToken('colors', ['border']);

  return (
    <Box data-tooltip-id={HOVER_TOOLTIP_ID}>
      <IconButton
        aria-label=""
        size="sm"
        variant="ghost"
        data-tooltip-id={TOOLTIP_ID}
        icon={<Svg name="User" />}
      />

      <Tooltip
        id={HOVER_TOOLTIP_ID}
        delayShow={250}
        border={`1px solid ${borderHex}`}
        closeEvents={{click: true, mouseleave: true}}
        style={{padding: '0', backgroundColor: 'white'}}
        opacity="1"
      >
        <Box color="bodyText" p="6px 10px" fontSize="12px">
          Профиль
        </Box>
      </Tooltip>

      <Tooltip
        id={TOOLTIP_ID}
        openOnClick
        opacity="1"
        border={`1px solid ${borderHex}`}
        offset={4}
        style={{
          padding: '0',
          backgroundColor: 'white',
          boxShadow: 'shadow_6'
        }}
      >
        <Flex p="6px 0" flexDirection="column" pointerEvents="all" minW="140px">
          <Text p="4px 10px" fontSize="12px" fontWeight={700} color="black">
            {currentUser.name}
          </Text>

          <Flex
            align="center"
            p="6px 10px"
            gap="8px"
            borderRadius="4px"
            _hover={{cursor: 'pointer', opacity: '0.7'}}
          >
            <Svg name="User" />
            <Text fontSize="12px" color="black">
              Профиль
            </Text>
          </Flex>

          <Flex
            align="center"
            p="6px 10px"
            gap="8px"
            borderRadius="4px"
            _hover={{cursor: 'pointer', opacity: '0.7'}}
            onClick={onSignOut}
          >
            <Svg name="SignOut" />
            <Text fontSize="12px" color="black">
              Выйти
            </Text>
          </Flex>
        </Flex>
      </Tooltip>
    </Box>
  );
};

export const UserIconButton = observer(UserIconButtonFC);
