import {RJSFSchema, UiSchema} from '@rjsf/utils';
import {EElement} from '@progress-fe/core';

export const UI_SCHEMA_FLOW7_BASIC: UiSchema = {
  basic: {
    name: {
      'ui:field': '/schemas/jsf-input'
    },
    elementType: {
      'ui:field': '/schemas/jsf-select'
    },
    modelId: {
      'ui:field': '/schemas/jsf-select-arrow'
    }
  },
  connections: {
    source: {
      'ui:field': '/schemas/jsf-select-pick-arrow'
    },
    consumer: {
      'ui:field': '/schemas/jsf-select-pick-arrow'
    }
  }
};

export const SCHEMA_FLOW7_BASIC: RJSFSchema = {
  type: 'object',
  properties: {
    basic: {
      title: 'Базовые свойства',
      type: 'object',
      required: ['name', 'elementType', 'modelId'],
      properties: {
        name: {
          title: 'Имя',
          type: 'string',
          minLength: 1
        },
        elementType: {
          title: 'Тип элемента',
          type: 'string',
          oneOf: [{const: EElement.MaterialFlowElement, title: 'Материальный поток'}],
          readOnly: true
        },
        modelId: {
          title: 'Модель',
          type: 'string',
          oneOf: [
            {const: '031CE246-0000-0000-0000-000000000001', title: 'Пенг-Робинсон-1'},
            {
              const: '031CE246-0000-0000-0000-000000000002',
              title: 'Пенг-Робинсон-2',
              readOnly: true
            }
          ]
        }
      }
    },
    connections: {
      title: 'Связи',
      type: 'object',
      properties: {
        source: {
          title: 'Источник',
          type: 'string',
          oneOf: [
            {const: '196C89E0-0000-0000-0000-000000000001', title: 'V-100', readOnly: true},
            {const: '196C89E0-0000-0000-0000-000000000002', title: 'K-100', readOnly: true},
            {const: '196C89E0-0000-0000-0000-000000000003', title: 'E-100', readOnly: true},
            {const: '196C89E0-0000-0000-0000-000000000004', title: 'VLV-100', readOnly: true},
            {const: '196C89E0-0000-0000-0000-000000000005', title: 'V-101'}
          ]
        },
        consumer: {
          title: 'Потребитель',
          type: ['string', 'null'],
          readOnly: true,
          oneOf: [
            {const: null},
            {const: '196C89E0-0000-0000-0000-000000000001', title: 'V-100', readOnly: true},
            {const: '196C89E0-0000-0000-0000-000000000002', title: 'K-100', readOnly: true},
            {const: '196C89E0-0000-0000-0000-000000000003', title: 'E-100', readOnly: true},
            {const: '196C89E0-0000-0000-0000-000000000004', title: 'VLV-100', readOnly: true},
            {const: '196C89E0-0000-0000-0000-000000000005', title: 'V-101', readOnly: true}
          ]
        }
      }
    }
  }
};

export const FORM_DATA_FLOW7_BASIC = {
  basic: {
    name: '7',
    elementType: EElement.MaterialFlowElement,
    modelId: '031CE246-0000-0000-0000-000000000001'
  },
  connections: {
    source: '196C89E0-0000-0000-0000-000000000005',
    consumer: null
  }
};

export const REVERT_FORM_DATA = {};
