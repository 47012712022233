/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {ModelOut} from './ModelOut';
import {ModelOutFromJSON, ModelOutFromJSONTyped, ModelOutToJSON} from './ModelOut';

/**
 *
 * @export
 * @interface ModelsOut
 */
export interface ModelsOut {
  /**
   *
   * @type {Array<ModelOut>}
   * @memberof ModelsOut
   */
  models: Array<ModelOut>;
}

/**
 * Check if a given object implements the ModelsOut interface.
 */
export function instanceOfModelsOut(value: object): value is ModelsOut {
  if (!('models' in value) || value['models'] === undefined) return false;
  return true;
}

export function ModelsOutFromJSON(json: any): ModelsOut {
  return ModelsOutFromJSONTyped(json, false);
}

export function ModelsOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelsOut {
  if (json == null) {
    return json;
  }
  return {
    models: (json['models'] as Array<any>).map(ModelOutFromJSON)
  };
}

export function ModelsOutToJSON(value?: ModelsOut | null): any {
  if (value == null) {
    return value;
  }
  return {
    models: (value['models'] as Array<any>).map(ModelOutToJSON)
  };
}
